export default {
  addBidders (state, { mediation, appId, bidders }) {
    state.bidders[mediation][appId] = bidders.filter((bidder) => bidder.mediation === mediation)
  },
  updateBidder (state, { mediation, appId, bidder }) {
    const existingBidderIdx = state.bidders[mediation][appId].findIndex(b => b.id === bidder.id)

    if (existingBidderIdx >= 0) {
      state.bidders.splice(existingBidderIdx, 1, bidder)
    }
  }
}
