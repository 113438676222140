import axios from 'axios'

import { notify } from '@/store/utils'

export default {
  async pushLineItems ({ state, commit, dispatch, rootGetters }, payload) {
    try {
      const mediation = rootGetters['monetization/currentMediation']
      const currentApp = rootGetters['monetization/currentApp']

      await dispatch('monetization/apps/setOwner',
        { appKey: payload.appKey, version: currentApp.version },
        { root: true }
      )

      const updatedApp = rootGetters['monetization/currentApp']

      const { data } = await axios.post(`waterfall/apps/${payload.appKey}/lineitems`,
        { lineItems: payload.lineItems, version: updatedApp.version, mediation: mediation })

      commit('monetization/setAppVersion', data.version, { root: true })
      notify(commit, 'LineItems pushed', false)
    } catch (e) {
      notify(commit, `Could not create lineItem: ${e}`, true)
    }
  },

  async validateLineItems ({ state, commit, rootGetters }, payload) {
    try {
      const mediation = rootGetters['monetization/currentMediation']
      const currentApp = rootGetters['monetization/currentApp']

      await axios.post(`waterfall/apps/${currentApp.id}/lineItems/validate`,
        { lineItems: payload.lineItems, version: currentApp.version, mediation: mediation })
      commit('setWaterfallValidity', true)
      commit('removeInvalidLineItems', payload.lineItems)
    } catch (e) {
      commit('setWaterfallValidity', false)
      commit('addInvalidLineItems', payload.lineItems)
    }
  },

  async deleteLineItems ({ state, commit, rootGetters }, payload) {
    try {
      const mediation = rootGetters['monetization/currentMediation']
      const currentApp = rootGetters['monetization/currentApp']
      const { data } = await axios.delete(`waterfall/apps/${payload.appKey}/lineitems`, {
        data: {
          appKey: payload.appKey,
          version: currentApp.version,
          lineItemIds: payload.lineItemIds,
          mediation: mediation
        }
      })
      commit('monetization/setAppVersion', data.version, { root: true })
      notify(commit, 'LineItems deletion request success', false)
    } catch (e) {
      notify(commit, `Could not delete lineItems: ${e}`, true)
    }
  },

  async exportLineItems ({ rootGetters, state, commit }, payload) {
    try {
      const mediation = rootGetters['monetization/currentMediation']
      const { data: { lineItems } } = await axios.get(`waterfall/apps/${payload.appKey}/lineitems/export`, {
        params: { mediation: mediation }
      })

      commit('setExportedLineItems', lineItems)
    } catch (e) {
      notify(commit, `Could not export lineItems: ${e}`, true)
    }
  },

  /**
   * @param {Object} payload
   * @param {string} payload.lineItemsCsv
   */
  async importLineItems ({ state, commit, rootGetters }, payload) {
    try {
      const mediation = rootGetters['monetization/currentMediation']
      const currentApp = rootGetters['monetization/currentApp']

      const { data: { data } } = await axios.post(`waterfall/apps/${currentApp.id}/lineitems/import`,
        { lineItemsCsv: payload.lineItemsCsv, version: currentApp.version, mediation: mediation })

      commit('monetization/setAppVersion', data.version, { root: true })
      notify(commit, 'LineItems imported successfully')
    } catch (e) {
      notify(commit, `Could not export lineItems: ${e}`, true)
    }
  }
}
