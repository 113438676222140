import axios from 'axios'
import { cloneDeep } from 'lodash'

import { notify } from '@/store/utils'
import platforms from '@/utils/platforms'

const basic = platforms.reduce((acc, platform) => ({ ...acc, [platform.value]: null }), {})

/** @type {import('vuex').Module} */
export default {
  namespaced: true,

  state: {
    loaded: { ...basic },
    creating: { ...basic },
    has: { ...basic },
    app: { ...basic }
  },

  mutations: {
    reset (state) {
      platforms
        .map(({ value }) => value)
        .forEach((platform) => {
          state.has[platform] = false
          state.app[platform] = null
          state.has[platform] = false
          state.loaded[platform] = false
        })
    },
    setCreating (state, { bundleId, value }) {
      state.creating = {
        ...state.creating,
        [bundleId]: value
      }
    },
    setLoaded (state, { platform, value }) {
      state.loaded = {
        ...state.loaded,
        [platform]: value
      }
    },
    setHas (state, { platform, value }) {
      state.has[platform] = value
    },
    setApp (state, { platform, app }) {
      state.app = {
        ...state.app,
        [platform]: app
      }
    }
  },

  actions: {
    async createApp ({ commit, dispatch }, { unifiedAppId, appId, opts }) {
      commit('setCreating', { bundleId: opts.bundleId, value: true })

      try {
        await axios.post('/adjust/app', { appKey: appId, opts })

        notify(commit, `Successfully created ${opts.name} on Adjust!`)

        dispatch('getApp', { unifiedAppId, appId, ...opts })
      } catch (e) {
        notify(commit, `Could not create ${opts.name} on Adjust: ${e.response.data.error}`, true)
      }
      commit('setCreating', { bundleId: opts.bundleId, value: false })
    },
    async getApp ({ commit, rootState, dispatch }, { appId, storeId, platform, releaseStatus }) {
      commit('setLoaded', { platform, value: false })
      try {
        const os = platform.split('_')[0]
        const { data } = await axios.get('/adjust/app', {
          params: {
            platform: os,
            storeId,
            releaseStatus
          }
        })

        const success = !!(data && data.adjust_app_token)
        commit('setHas', { platform, value: success })
        if (!success) {
          return
        }
        commit('setApp', { platform, app: data })

        // Adding to VSauce configuration
        const key = 'AdjustAppToken'

        // Saving the app token
        const currentVSConfig = cloneDeep(rootState.vsauce.configs[appId])
        const toSend = {
          Adjust: {
            [key]: data.adjust_app_token
          }
        }

        if (os === 'android') {
          toSend.Adjust.Sha1 = data.android_signature_hash
        }
        // We want to save only if never done before
        // Or if sha 1 is different on Android
        if (
          !currentVSConfig.Adjust ||
          !currentVSConfig.Adjust[key] ||
          currentVSConfig.Adjust[key] !== data.adjust_app_token ||
          (os === 'android' && currentVSConfig.Adjust.Sha1 !== data.android_signature_hash)
        ) {
          commit('vsauce/setCurrentApp', { id: appId }, { root: true })
          await dispatch('vsauce/saveConfig', { id: appId, config: toSend }, { root: true })
        }

        // Now updating it in the current config
        currentVSConfig.Adjust = { ...currentVSConfig.Adjust, ...toSend.Adjust }
        commit('vsauce/addConfig', { key: appId, config: currentVSConfig }, { root: true })
      } catch (e) {}
      commit('setLoaded', { platform, value: true })
    },
    async getSignatureLink ({ commit, rootState, dispatch }, { app, platform, type = 'forked' }) {
      try {
        const { bundleId, id: appId } = app
        const os = platform.split('_')[0]
        const { data } = await axios.get(`/adjust/signature/${os}/${bundleId}`, {
          params: { type }
        })
        const signatureLink = data[os]

        if (!signatureLink) throw new Error('Not generated.')

        const currentConfig = cloneDeep(rootState.vsauce.configs[appId])
        const isV2 = type === 'plugin'
        const key = isV2 ? 'AdjustSignatureLinkV2' : 'AdjustSignatureLink'

        currentConfig.Adjust[key] = { value: signatureLink.url, checksum: signatureLink.checksum }
        commit('vsauce/addConfig', { key: appId, config: currentConfig }, { root: true })

        commit('vsauce/setCurrentApp', app, { root: true })
        await dispatch('vsauce/saveConfig', { id: appId, config: currentConfig }, { root: true })

        return signatureLink
      } catch (e) {
        notify(commit, 'Could not retrieve Signature file, it might not be created yet.', true)
      }
    },
    async updateChannels ({ dispatch }, config) {
      // TODO: Implement this.
    }
  },

  getters: {
    app (state) {
      return (platform) => state.app[platform] || {}
    },
    has (state) {
      return (platform) => state.has[platform]
    },
    isLoading (state) {
      return (platform) => state.loading[platform]
    },
    isLoaded (state) {
      return (platform) => state.loaded[platform]
    },
    isCreating (state) {
      return (bundleId) => state.creating[bundleId]
    }
  }
}
