export default {
  init (state) {
    state.isInit[state.mediation] = true
  },

  setIsInit (state, isInit) {
    state.isInit[state.mediation] = isInit
  },

  setLoading (state, bool) {
    state.isLoading = bool
  },

  setPlatform (state, platform) {
    state.platform = platform
  },

  setMediation (state, mediation) {
    state.mediation = mediation
  },

  setCurrentApp (state, app) {
    state.currentApp[state.mediation][state.platform] = app
  },

  setHasWaterfall (state, bool) {
    state.hasWaterfall[state.mediation][state.platform] = bool
  },

  setMediationAccounts (state, accounts) {
    state.mediationAccounts[state.mediation] = accounts
  },

  setAppVersion (state, version) {
    state.currentApp[state.mediation][state.platform].version = version
  },

  setAppAvailability (state, isAppAvailable) {
    state.isAppAvailable = isAppAvailable
  },

  setHistory (state, history) {
    state.history = history
  },

  setNetworkAppIds (state, networkAppIds) {
    state.networkAppIds[state.mediation][state.platform] = networkAppIds
  },

  setNetworkAppIdsLoading (state, isLoading) {
    state.foundNetworkAppIds.isLoading = isLoading
  },

  setFoundNetworkAppIds (state, networkAppIds) {
    state.foundNetworkAppIds[state.mediation][state.platform] = networkAppIds
  },

  setTemplateList (state, templateList) {
    state.templateList = templateList
  },

  setActionSummary (state, actionSummary) {
    state.actionSummary = actionSummary
  }
}
