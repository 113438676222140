<template lang="pug">
  v-stepper-content(step='2')
    v-card
      v-card-title Select networks to procede with.

      v-divider

      v-card-text
        v-autocomplete(
          label='Choose Networks',
          :items='currentNetworks',
          v-model='selected',
          @change='emitChange'
          chips,
          deletable-chips,
          filled,
          multiple,
          rounded,
          solo-inverted,
          hide-details,
          ref='autocomplete'
        )
          template(v-slot:selection='data')
            v-chip(
              v-bind='data.attrs',
              :input-value='data.selected',
              close,
              @click='data.select',
              @click:close='remove(data.item)',
            )
              template(v-if='data.item.icon')
                v-icon(left, :color='data.item.color') {{ data.item.icon }}
              template(v-else-if='data.item.image')
                v-avatar(left)
                  v-img(:src='data.item.image')
              div {{ data.item.text }}

      v-divider

      v-card-actions.px-4
        v-spacer
        v-btn(@click='step -= 1', color='secondary') Previous
        v-btn(
          @click='next',
          :disabled='!canNext',
          color='primary'
        ) Next
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import step from '@/mixins/acquisition/step.js'

export default {
  name: 'Networks',

  mixins: [step],

  data: () => ({
    selected: []
  }),

  mounted () {
    this.selected = Array.from(this.networks.selected.map(({ value }) => value))
  },

  computed: {
    ...mapState('acquisition', [
      'networks',
      'campaigns'
    ]),

    canNext () {
      return !!this.selected.length
    },

    currentNetworks () {
      return this.networks.all
    }
  },

  methods: {
    ...mapMutations('acquisition', [
      'setSelectedNetworks'
    ]),

    emitChange () {
      this.$refs.autocomplete.internalSearch = ''
    },

    remove (item) {
      const network = item.value
      const index = this.selected.indexOf(network)

      if (index >= 0) this.selected.splice(index, 1)
    },
    next () {
      this.setSelectedNetworks(this.selected)
      this.step += 1
    }
  },

  watch: {
    step () {
      if (this.step === 2) this.selected = [...this.networks.selected]
    }
  }
}
</script>
