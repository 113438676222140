import notifications from './notifications'

export default {
  mixins: [notifications],

  methods: {
    async copyText (text) {
      await navigator.clipboard.writeText(text)

      this.notify('Copied to clipboard.')
    }
  }
}
