<template lang="pug">
  v-btn-toggle(
    v-model='mediation',
    @change='onChange',
    mandatory
  )
    template(
      v-for='media in mediations'
    )
      v-btn(
        icon,
        :value='media',
        :disabled="(isLoading && mediation !== media)"
      )
        v-img(:src='getMediationLogo(media)', contain, max-width='35px', max-height='40px')
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import appDecorators from '@/mixins/appDecorators.js'
import { mediations } from '@/constants/constants.js'

export default {
  name: 'MediationSelection',

  mixins: [appDecorators],

  data: function () {
    return { mediations }
  },

  computed: {
    ...mapGetters('apps', ['app']),
    ...mapState('monetization', ['isLoading']),

    mediation: {
      get () { return this.$store.state.monetization.mediation },
      set (val) {
        this.$router.replace({ query: { ...this.$route.query, mediation: val } })
        this.$store.commit('monetization/setMediation', val)
      }
    }
  },

  methods: {
    onChange () {
      this.$emit('update')
    }
  }
}
</script>
