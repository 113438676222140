<template lang="pug">
  v-container(fluid)
    v-card
      v-card-title
        div Please create the following ad units on your platform and fill in the required informations below.

      v-card-text
        v-row
          v-col(cols='12')
            h2 App Name : {{ appInfo.name }} {{ appInfo.isChina ? '- China' : '' }}
          v-col(cols='12')
            h3 Store URL : {{ appInfo.url }}
          v-col(cols='12')
            h3 App Bungle Id : {{ appInfo.bundleId }}
          v-col(cols='12')
            h3 App Platform : {{ appInfo.platform }}
          v-col(cols='12')
            h3 App Mediation : {{ mediation }}
          v-col(cols='12' v-if='network === "chartboost"')
            h3 COPPA : this game is not directed to children under 13 years old

        v-row(v-if='network === "ogury"')
          v-col(cols='3')
            v-text-field(
              label='App Id on Ogury (required)',
              v-model='appInfo.monetAppKey',
            )
        v-row(v-else-if='appInfo.monetAppKey')
          v-col(cols='12')
            h3 App Id on {{ network }}: {{ appInfo.monetAppKey }}

</template>

<script>
export default {
  name: 'FormHeader',

  props: {
    network: {
      type: String,
      required: true
    },
    mediation: {
      type: String,
      required: true
    },
    appInfo: {
      type: Object,
      required: true
    }
  }
}
</script>
