<template lang="pug">
  v-dialog(v-model='show', width='70%')
    template(v-slot:activator='{ on, attrs }')
      v-btn.mr-1(
        v-bind='attrs'
        v-on='on',
        color='primary',
        outlined
      ) Import from template

    v-card(outlined)
      v-card-title Campaign creation templates

      v-divider

      v-card-text
        v-container(fluid)
          v-row(justify='center', align='center')
            v-col(cols='12', md='4')
              v-select(
                outlined,
                label='Available templates',
                :loading='loading',
                :items='templates',
                v-model='template',
                item-text='name',
                item-value='id'
              )

          v-row(justify='center', align='center', v-if='error')
            v-col(cols='12', md='4')
              v-row(justify='space-between', align='center')
                v-alert(
                  dense,
                  outlined,
                  border='left',
                  type='error'
                )
                  v-col.grow
                    h5 Kitchen could not retrieve templates.
                    h5 {{ error }}
                    h5 Please retry. If the problem persists, please contact the administrators.

                  v-col.shrink
                    v-btn(
                      @click='retrieveTemplates',
                      outlined
                    ) Retry

      v-card-actions
        v-spacer
        v-btn(
          @click='show = false',
          outlined
        ) cancel
        v-btn(
          @click='importTemplate',
          color='primary',
          :disabled='loading'
        ) Import
</template>

<script>
import { mapMutations } from 'vuex'
/**
 * @typedef {Object} TemplateEntry
 * @property {String} network
 * @property {String} bid
 * @property {String} budget
 * @property {String} targeting
 */

export default {
  name: 'AcquisitionImportDialog',

  data: () => ({
    show: false,
    loading: false,

    formattedTemplate: [],
    templates: [],
    template: null,
    error: null
  }),

  methods: {
    ...mapMutations('acquisition', [
      'setBudgetTemplate'
    ]),

    async retrieveTemplates () {
      this.loading = true
      this.error = null

      try {
        const { data } = await this.$axios.get('/acquisition/campaigns/templates/list')

        this.templates = data
      } catch (e) {
        this.error = e.message
      }

      this.loading = false
    },

    async retrieveTemplate () {
      if (!this.template) return

      this.loading = true
      this.error = null

      try {
        /**
         * @type {{ data: TemplateEntry[] }}
         */
        const { data } = await this.$axios.get('/acquisition/campaigns/templates', {
          params: {
            fileId: this.template,
            mimeType: this.templates.find(({ id }) => id === this.template).mimeType
          }
        })

        this.formattedTemplate = data
      } catch (e) {
        this.error = e.message
      }

      this.loading = false
    },

    /**
     * @param {TemplateEntry[]} template
     */
    importTemplate () {
      this.setBudgetTemplate(this.formattedTemplate)
      this.show = false
      this.template = null
    }
  },

  watch: {
    show (bool) {
      if (bool) this.retrieveTemplates()
    },
    template () {
      this.retrieveTemplate()
    }
  }
}
</script>
