import axios from 'axios'

import { notify } from '@/store/utils'

/**
 * @typedef {"ADMIN" | "UA_GUY" | "PM_GUY" | "MONET_GUY"} GroupName
 */

export default {
  /**
   * Allows to add or delete a user to / from a group.
   *
   * @param {import('vuex').ActionContext} ctx
   * @param {Object} args
   * @param {"add" | "delete"} args.action
   * @param {GroupName} args.groupName
   * @param {String} args.userId
   *
   * @returns {Promise<void>}
   */
  async actOnUserInGroup ({ commit }, { action, groupName, userId }) {
    try {
      const params = {
        group: groupName,
        userId
      }

      const call = {
        add: async () => axios.put('/users/groups', params),
        delete: async () => axios.delete('/users/groups', { params })
      }[action]

      if (!call) return

      await call()

      notify(commit, `Successfully ${action === 'add' ? 'added' : 'removed'} user to group ${groupName}`)
    } catch (e) {
      const msg = e.response ? e.response.data : e.message
      notify(commit, `Could not act on member in ${groupName}: ${msg}`, true)
    }
  },

  /**
   * Retrieves user from a given group
   *
   * @param {import('vuex').ActionContext} ctx
   * @param {GroupName} groupName
   *
   * @returns {Promise<void>}
   */
  async listGroup ({ commit }, groupName) {
    try {
      const { data } = await axios.get('/users/groups', {
        params: {
          group: groupName
        }
      })

      commit('setGroupMembers', { groupName, data })
    } catch (e) {
      notify(commit, 'Could not retrieve members of group ' + groupName, true)
    }
  },

  /**
   * Retrieves groups for the current user
   *
   * @param {import('vuex').ActionContext} ctx
   *
   * @returns {Promise<void>}
   */
  async getUserGroups ({ commit }) {
    try {
      const { data } = await axios.get('/me/groups')

      commit('setUserGroups', data.groups)
    } catch (e) {
      notify(commit, 'Could not retrieve groups for the current user', true)
    }
  }
}
