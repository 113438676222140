<template lang="pug">

  v-select(
    label='Template'
    :items='templateList'
    @change='changed'
    outlined
  )

</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TemplateSelector',

  beforeMount () {
    this.fetchTemplateList()
  },

  computed: {
    ...mapState('monetization', ['templateList'])
  },

  methods: {
    ...mapActions('monetization', ['fetchTemplateList']),

    changed (templateName) {
      this.$emit('template-selected', templateName)
    }
  }

}
</script>
