<template lang="pug">
  v-stepper-content(step='3')
    v-card
      v-card-title Configure each network

      v-divider

      v-card-text
        v-tabs(
          v-model='tab',
          grow,
          icons-and-text
        )
          v-tabs-slider

          v-tab(
            v-for='network in networks.selected',
            :key='network.value',
            :href='`#${network.value}`'
          )
            span {{ network.text }}
            template(v-if='network.icon')
              v-icon(large, :color='network.color') {{ network.icon }}
            template(v-else-if='network.image')
              v-avatar(tile, size='30')
                v-img(:src='network.image')

        v-tabs-items(v-model='tab')
          v-tab-item(
            eager,
            v-for='network in networks.selected',
            :key='network.value',
            :value='network.value'
          )
            v-card(flat)
              component(
                :is='configuration[network.value].component',
                v-bind='configuration[network.value].props',
                :network='network',
                @valid='setValid',
                ref='networks'
              )

      v-divider

      v-card-actions.px-4
        v-spacer
        v-btn(@click='step -= 1', color='secondary') Previous
        v-btn(@click='saveAll', :disabled='!canSaveAll', :loading='loading', color='warn') Save All
        v-btn(@click='step += 1', :disabled='!canNext', color='primary') Next
</template>

<script>
import { mdiAccountAlertOutline } from '@mdi/js'
import { mapState } from 'vuex'

import Adwords from './networks/adwords.vue'
import Apple from './networks/apple.vue'
import Applovin from './networks/applovin.vue'
import Crosspromo from './networks/crosspromo.vue'
import Facebook from './networks/facebook.vue'
import Mintegral from './networks/mintegral.vue'
import Snapchat from './networks/snapchat.vue'
import Tiktok from './networks/tiktok.vue'
import Voodooads from './networks/voodooads.vue'
import Email from './networks/email.vue'

import step from '@/mixins/acquisition/step.js'

export default {
  name: 'Configuration',

  mixins: [step],

  data: () => ({
    mdiAccountAlertOutline,
    tab: null,

    valid: {},
    loading: false,

    configuration: {
      // Customs
      adwords: { component: Adwords },
      applovin: { component: Applovin },
      apple: { component: Apple },
      facebook: { component: Facebook },
      mintegral: { component: Mintegral },
      snapchat: { component: Snapchat },
      tiktok: { component: Tiktok },
      voodooads: { component: Voodooads },
      crosspromo: { component: Crosspromo },

      // Emails
      crossinstall: { component: Email, props: { needsId: false } },
      ironsource: { component: Email, props: { needsId: false } },
      tapjoy: { component: Email, props: { needsId: true } },
      unityads: { component: Email, props: { needsId: true } },
      vungle: { component: Email, props: { needsId: true } }
    }
  }),

  computed: {
    ...mapState('acquisition', [
      'networks'
    ]),

    configurations () {
      return this.$refs.networks.reduce((acc, { network, configuration }) => {
        if (network.value === 'crosspromo') return acc

        return {
          ...acc,
          [network.value]: { configuration }
        }
      }, {})
    },

    canSaveAll () {
      const values = Object.values(this.valid)

      return values.length
        ? values.some((valid) => valid)
        : false
    },

    canNext () {
      const values = Object.values(this.valid)

      return values.length
        ? values.every((valid) => valid)
        : false
    }
  },

  methods: {
    setValid ({ value, network }) {
      this.$set(this.valid, network, value)
    },

    async saveAll () {
      this.loading = true

      await Promise.all(
        this.$refs.networks.map(async ({ save }) => save(false))
      )

      // Handy trick for design purposes
      setTimeout(() => {
        this.loading = false
      }, 1500)
    }
  }
}
</script>
