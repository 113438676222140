<template lang="pug">
  v-simple-table
    template(v-slot:default)
      thead
        tr
          th.text-center Batch name
          th.text-center Creation date
          th
      tbody
        tr(
          v-for='batch in batches',
          :key='batch.id'
        )
          td.text-center {{ batch.name }}
          td.text-center {{ new Date(batch.createdAt).toGMTString() }}
          td.text-center
            v-tooltip(top)
              template(v-slot:activator='{ on }')
                v-btn(icon, v-on='on', @click='openBatch(batch)', :loading='loading[batch.id]')
                  v-icon {{ icons.mdiEye }}
              span Check campaign batch
</template>

<script>
import { mdiEye } from '@mdi/js'
import { mapActions, mapGetters, mapState } from 'vuex'

import Batch from '@/mixins/acquisition/batch.js'

export default {
  name: 'Batches',

  mixins: [Batch],

  props: {
    batches: {
      type: Array,
      required: true
    }
  },

  data: () => ({
    icons: { mdiEye },

    loading: {}
  }),

  computed: {
    ...mapState('acquisition', ['campaigns', 'networks', 'platform']),
    ...mapGetters('apps', ['app']),
    ...mapGetters('auth', ['user'])
  },

  methods: {
    ...mapActions('acquisition', ['getBatch']),

    async openBatch (batch) {
      this.$set(this.loading, batch.id, true)

      await this.getBatch(batch.id)

      const { current: { batches } } = this.campaigns
      const batchInfo = batches[batch.id]

      const data = {
        batchId: batch.id,
        campaigns: this.formatBatch(batchInfo),
        user: this.user,
        appId: (this.app.apps.find(({ platform }) => platform === this.platform)).id
      }

      this.$emit('review', data)

      this.$set(this.loading, batch.id, false)
    }
  }
}
</script>
