<template lang="pug">
  div
    //- Configuration
    v-container(fluid)
      v-simple-table(v-if='campaign.configuration', dense)
        template(v-slot:default)
          thead
            tr
              //- Configuration Headers
              th.text-center(
                v-for='keyName in Object.keys(campaign.configuration)',
                :key='keyName'
              ) {{ displayNames[keyName] || keyName }}

              //- Options Headers
              th.text-center(
                v-for='option in getOptionalHeaders',
                :key='option.name'
              ) Exclude {{ displayNames[option.name] || option.name }}

          tbody
            tr
              //- Configuration values
              td.text-center(
                v-for='keyName in Object.keys(campaign.configuration)',
                :key='keyName'
              )
                //- Check mark if boolean value
                template(v-if="typeof campaign.configuration[keyName] === 'boolean'")
                  v-icon(
                    :color="campaign.configuration[keyName] ? 'green' : 'red'"
                  ) {{ campaign.configuration[keyName] ? icons.mdiCheck : icons.mdiClose }}

                //- String otherwise
                template(v-else)
                  span {{ campaign.configuration[keyName] }}

              //- Options values
              td.text-center(
                v-for='option in Object.keys(getOptionalHeaders)',
                :key='option.name'
              )
                //- Only check marks
                v-icon(
                  :color="option.value ? 'green' : 'red'"
                ) {{ option.value ? icons.mdiCheck : icons.mdiClose }}

    //- Budgets
    v-container(fluid)
      v-simple-table
        template(v-slot:default)
          thead
            tr
              th.text-center(
                v-if='multiAdgroups'
              ) Campaign
              th.text-center Countries
              th.text-center(
                v-if='displayTypes'
              ) Type
              template(
                v-if='!campaign.network.hide || !campaign.network.hide.bid'
              )
                template(v-if="campaign.network.value === 'applovin' && ['roas_ad_based', 'roas_iap'].includes(campaign.type)")
                  th.text-center Percent goal
                template(v-if="campaign.network.value === 'tiktok' && ['splitTest', 'controlTest'].includes(campaign.type)")
                  th.text-center Budget per Creative
                template(v-else)
                  th.text-center Bid
              th.text-center(
                v-if='!(campaign.network.hide && campaign.network.hide.budget)'
              ) Budget
              th.text-center iOS 14+ Only
              th.text-center Status
              th.text-center Error

          tbody
            tr(
              v-for='budget in budgets',
              :key='getBudgetKey(budget)'
            )
              th.text-center(
                v-if='multiAdgroups'
              )  {{ budget.campaignIndex + 1 }}
              th.text-center {{ budget.countries.join(', ') }}
              th.text-center(
                v-if='displayTypes'
              ) {{ campaign.network.value === 'tiktok' && budget.type === 'controlTest' ? 'splitTest' : budget.type }}
              th.text-center(
                v-if='!(campaign.network.hide && campaign.network.hide.bid)'
              ) {{ budget.bid }}
              th.text-center(
                v-if='!(campaign.network.hide && campaign.network.hide.budget)'
              ) {{ budget.budget }}
              th.text-center {{ budget.ios14Min }}
              th.text-center
                template(v-if="budget.status === 'request_pending' || budget.status === 'request_sent'")
                  v-progress-circular(indeterminate)
                template(v-else-if="budget.status === 'success'")
                  v-icon(color='green') {{ icons.mdiCheck }}
                template(v-else-if="budget.status === 'failed'")
                  v-icon(color='red') {{ icons.mdiClose }}
              th.text-center
                template(v-if='budget.error')
                  v-alert.my-1(
                    :type="budget.status === 'success' ? 'warning' : 'error'",
                    dense,
                    outlined
                  )
                    template(v-for='error in budget.error')
                      h5 {{ error.message }}
                template(v-else) N/A
</template>

<script>
import { mdiClose, mdiCheck, mdiBellAlert } from '@mdi/js'

export default {
  name: 'CampaignDisplay',

  props: {
    campaign: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    icons: { mdiClose, mdiCheck, mdiBellAlert },

    displayNames: {
      accountId: 'Account ID',
      linkId: 'Link ID',
      isSetup: 'All clean',
      appId: 'App ID',
      decryptionKey: 'Decryption Key',
      appSecret: 'App Secret',
      pageId: 'Page ID',
      secondaryPageId: 'Secondary Page ID',
      isAdaccountInAppDeveloper: 'Account added to developper',
      ios14: 'iOS 14+',

      // Batch info
      workerMessageId: 'Worker ID',
      trackingUrl: 'Tracking URL',
      status: 'Status',
      error: 'Error'
    }
  }),

  computed: {
    getOptionalHeaders () {
      // avoid next attributes to be show
      const blacklist = ['ios14']

      const options = []
      Object.keys(this.campaign.options.exclude).forEach((optionName) => {
        if (!blacklist.includes(optionName)) {
          options.push({
            name: optionName,
            value: this.campaign.options.exclude[optionName]
          })
        }
      })

      return options
    },
    displayTypes () {
      return this.campaign.network.campaignsType
    },

    multiAdgroups () {
      return this.campaign.network.multiAdgroups
    },

    budgets () {
      if (this.multiAdgroups) {
        return this.campaign.budgets.map((adgroupBudgets, index) => {
          return adgroupBudgets.map(adgroupBudget => {
            return {
              ...adgroupBudget,
              campaignIndex: index
            }
          })
        }).flat()
      }

      return this.campaign.budgets.flat()
    }
  },

  methods: {
    getBudgetKey (budget) {
      return budget.countries.join(', ') + (budget.type || '')
    }
  }
}
</script>
