import { mdiGooglePlay } from '@mdi/js'
import ChinaFlag from 'svg-country-flags/svg/cn.svg'

import LogoAppStore from '@/assets/icons/logo-appstore.png'
import LogoXiaomi from '@/assets/icons/logo-xiaomi.png'
import LogoHuawei from '@/assets/icons/logo-huawei.png'
import LogoTencent from '@/assets/icons/logo-tencent.png'
import LogoOppo from '@/assets/icons/logo-oppo.jpg'
import LogoLine from '@/assets/icons/logo-line.png'

import LogoMax from '@/assets/icons/logo-maxads.png'

export default {
  data: () => ({
    appDecorators: {
      icons: {
        android: mdiGooglePlay
      },

      logos: {
        ios_china: ChinaFlag,
        ios: LogoAppStore,
        android_xiaomi: LogoXiaomi,
        android_huawei: LogoHuawei,
        android_tencent: LogoTencent,
        android_oppo: LogoOppo,
        ios_line: LogoLine,
        android_line: LogoLine
      },

      mediationLogos: {
        max: LogoMax
      },

      colors: {
        ios: 'cyan',
        android: 'green'
      },

      names: {
        android: 'Android',
        ios: 'iOS',
        ios_china: 'China iOS',
        android_xiaomi: 'Xiaomi',
        android_huawei: 'Huawei',
        android_tencent: 'Tencent',
        android_oppo: 'Oppo',
        ios_line: 'Line iOS',
        android_line: 'Line Android'
      }
    }
  }),

  methods: {
    getIcon (platform) {
      return this.appDecorators.icons[platform] || null
    },
    getLogo (platform) {
      return this.appDecorators.logos[platform]
    },
    getMediationLogo (mediation) {
      return this.appDecorators.mediationLogos[mediation]
    },
    getColor (platform) {
      return this.appDecorators.colors[platform] || 'default'
    },
    getName (platform) {
      return this.appDecorators.names[platform] || `${platform.charAt(0).toUpperCase()}${platform.slice(1)}`
    }
  }
}
