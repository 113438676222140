<template lang="pug">
  v-container(v-if='!adgroup.deleted')
      v-card-title
        span.title Adgroup {{ adgroup.name }}
          v-btn(v-if='adgroup.deletable', icon, @click='removeAdgroup')
            v-icon {{ icons.mdiCloseCircle }}

      v-container(fluid)
        v-row
          v-col(cols='12')
            countries(
              ref='countries',
              :network='network',
              @update='updateSelectedCountries'
            )

        v-col(cols='12')
          budgets(
            @isValid='isValid',
            :countries='countries.selected',
            :multiCountry='true',
            :campaignsType='specialCampaigns',
            :network='network',
            :ios14Min='ios14Min',
            :needsCreative='needsCreative',
            :campaignType='campaignType',
            ref='adgroupBudget'
          )
</template>

<script>
import { mapState } from 'vuex'
import { mdiCloseCircle } from '@mdi/js'

import Countries from './countries.vue'
import Budgets from './budgets.vue'

export default {
  name: 'Adgroup',

  components: {
    Budgets,
    Countries
  },

  props: {
    network: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
    adgroup: {
      required: true,
      type: Object
    },
    needsCreative: {
      required: true,
      type: Boolean
    },
    campaignType: {
      required: true,
      type: String
    },
    ios14Min: {
      required: true,
      type: Boolean
    }
  },

  data: () => ({
    icons: { mdiCloseCircle },
    specialCampaigns: [],
    countries: {
      selected: []
    },
    isDeleted: false
  }),

  mounted () {
    if (this.adgroup.baseCountry) {
      this.$refs.countries.setBase([this.adgroup.baseCountry])
    }
  },

  computed: {
    ...mapState('acquisition', ['platform'])
  },

  methods: {
    isValid (params) { this.$emit('isValid', params) },

    updateSelectedCountries (countries) {
      this.countries.selected = countries
    },
    removeAdgroup () {
      this.isDeleted = true
      this.updateSelectedCountries([])
    },
    budgets () {
      return this.isDeleted ? [] : this.$refs.adgroupBudget.budgets
    }
  },

  watch: {
    countries: {
      deep: true,
      handler () {
        this.$emit('update', { countries: this.countries.selected, adgroupIndex: this.index })
      }
    }
  }
}
</script>
