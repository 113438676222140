<template lang="pug">
  v-container(fluid)
    template(v-if='canAccess')
      template(
        v-for='(group, index) in groups'
      )
        user-listing(
          :group='group',
          :key='group.name',
          :class="{ 'mt-2': index > 0 }"
        )

    no-access(v-else)
</template>

<script>
// Comps
import UserListing from '@/components/auth/listing.vue'

export default {
  name: 'AdminPanel',

  components: {
    UserListing
  },

  data: () => ({
    restrict: {
      groups: ['ADMIN']
    },

    groups: [
      {
        name: 'ADMIN',
        title: 'Administrators',
        description: 'Those users can do about everything on the platform'
      },
      {
        name: 'UA_GUY',
        title: 'User Acquisition Managers',
        description: 'Those users can only act on Acquisition part of the platform'
      },
      {
        name: 'MONET_GUY',
        title: 'App Monetization Managers',
        description: 'Those users can only act on Monetization part of the platform'
      },
      {
        name: 'PM_GUY',
        title: 'Publishing Managers',
        description: 'Those users can only act on Publishing related part of the platform'
      }
    ]
  })
}

</script>
