var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.lineItems,"expanded":_vm.expanded,"items-per-page":-1,"item-key":"id","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('line-item-edition',{attrs:{"lineItem":item},on:{"temp-line-item":_vm.onLineItemChanges,"deletion-request":_vm.onLineItemDeletion}})],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"disabled":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.syncStatus.status",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyError(item.syncStatus)}}},[(!_vm.invalidLineItems[item.id])?_c('v-icon',{attrs:{"color":_vm.statusColors[item.syncStatus.status]}},[_vm._v(_vm._s(_vm.icons.circle))]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.alert))])],1)],1)]}}],null,true)},[_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(item.syncStatus.status))]),_c('h5',{staticClass:"text-center"},[_vm._v(_vm._s(item.syncStatus.message)+" "+_vm._s(item.syncStatus.messageId))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }