<template lang="pug">
  v-card.mb-4(outlined, v-if='step > 1')
    v-card-title
      span Notes
      v-spacer
      v-btn(
        icon,
        @click='hideNotes = !hideNotes'
      )
        v-icon {{ hideNotes ? icons.mdiWindowMaximize : icons.mdiWindowMinimize }}

    v-card-subtitle Reminder of what you selected so far.

    v-divider

    v-card-text
      v-expand-transition
        v-container(v-show='!hideNotes')
          v-row(justify='space-around', align-center)
            template(v-if='step > 1')
              v-col(cols='12', sm='6', md='4', lg='3')
                v-card(outlined)
                  v-card-title Platform
                  v-divider
                  v-card-text
                    v-row(justify='center', align='center')
                      v-btn(outlined, large, :color='getColor(platform)')
                        template(slot='default')
                          span.mr-2 {{ getName(platform) }}

                          template(v-if='getIcon(platform)')
                            v-icon(
                              large,
                              :color='getColor(platform)'
                            ) {{ getIcon(platform) }}
                          template(v-else)
                            v-img(:src='getLogo(platform)', contain, max-width='35px', max-height='30px')

            template(v-if='step > 3')
              v-col(cols='12', sm='6', md='4', lg='3')
                v-card.pa-2(outlined)
                  v-card-title Networks
                  v-divider
                  v-card-text
                    v-row(justify='center', align='center')
                      template(v-for='network in networks.selected')
                        .ma-2
                          v-chip
                            template(v-if='network.icon')
                              v-icon(left, :color='network.color') {{ network.icon }}
                            template(v-else-if='network.image')
                              v-avatar(left)
                                v-img(:src='network.image')
                            div {{ network.text }}
</template>

<script>
import { mdiWindowMinimize, mdiWindowMaximize } from '@mdi/js'

import step from '@/mixins/acquisition/step.js'
import appDecorators from '@/mixins/appDecorators.js'
import { mapState } from 'vuex'

export default {
  name: 'Notes',

  mixins: [step, appDecorators],

  data: () => ({
    icons: { mdiWindowMinimize, mdiWindowMaximize },
    hideNotes: false
  }),

  computed: {
    ...mapState('acquisition', ['platform', 'networks'])
  }
}
</script>
