<template lang="pug">
  v-container(fluid)
    v-card(outlined)
      v-card-title
        span Networks' Google Drive Folder ID
        v-spacer

        v-scroll-x-reverse-transition(group, leave-absolute)
          template(v-if='newNetwork.show')
            v-row(align='center', justify='end', key='add', no-gutters)
              v-col.py-0(cols='8')
                v-text-field(
                  @keyup.enter='addNetwork',
                  solo,
                  v-model='newNetwork.name',
                  label='Network Name',
                  hide-details
                )

              v-btn(icon, @click='addNetwork')
                v-icon(color='accent') {{ icons.mdiKeyboardReturn }}

              v-btn(icon, @click='newNetwork.show = false')
                v-icon(color='warning') {{ icons.mdiClose }}

          template(v-else)
            v-btn(icon, @click='newNetwork.show = true', key='no-add')
              v-icon {{ icons.mdiPlusCircle }}

      v-divider

      v-card-text
        v-row(align='center', justify='center')
          template(v-if='!drives.length')
            .title No Google Drive Folder was setup for #[i {{ app.name }}] as of yet.

          template(v-else, v-for='drive in drives')
            v-col(cols='10', :key='drive.networkName')
              v-text-field(
                outlined,
                clearable,
                :label='drive.networkName',
                :ref='drive.networkName',
                :hint='getUpdateDateString(drive.updatedAt)',
                persistent-hint,
                v-model='values[drive.networkName]',
                @input='save'
              )
                template(v-slot:append)
                  v-icon(@click='copyText(formatDriveURL(drive.googleDriveFolderId))') {{ icons.mdiContentCopy }}

                template(v-slot:prepend)
                  v-icon(color='error', @click='deleteDrive(drive)') {{ icons.mdiDeleteOutline }}
</template>

<script>
import { mapGetters } from 'vuex'

import { debounce } from 'lodash'

import checkIfApp from '@/mixins/checkIfApp.js'
import copyText from '@/mixins/copyText.js'
import notifications from '@/mixins/notifications.js'

import { mdiContentCopy, mdiDeleteOutline, mdiPlusCircle, mdiKeyboardReturn, mdiClose } from '@mdi/js'

export default {
  name: 'GoogleDrive',

  mixins: [checkIfApp, copyText, notifications],

  data: () => ({
    drives: [],

    icons: { mdiContentCopy, mdiDeleteOutline, mdiPlusCircle, mdiKeyboardReturn, mdiClose },

    newNetwork: {
      show: false,
      name: null
    }
  }),

  mounted () {
    this.populateDrives()
  },

  computed: {
    ...mapGetters('apps', ['app']),

    values () {
      return this.drives.reduce((acc, drive) => {
        acc[drive.networkName] = drive.googleDriveFolderId

        return acc
      }, {})
    }
  },

  methods: {
    populateDrives () {
      this.drives = Array.from(this.app ? this.app.driveNetworkFolders : [])
    },
    getUpdateDateString (rawDate) {
      const date = new Date(rawDate)

      return rawDate
        ? `Last update on ${date.toLocaleDateString()} at ${date.toLocaleTimeString()}.`
        : 'Has never been set.'
    },
    formatDriveURL (id) {
      return id
    },
    addNetwork () {
      const { name } = this.newNetwork

      this.drives.push({
        googleDriveFolderId: null,
        networkName: name,
        unifiedAppId: this.app.id
      })

      this.newNetwork.show = false
      this.newNetwork.name = null

      this.$nextTick(() => {
        this.$refs[name][0].focus()
      })
    },
    deleteDrive (drive) {
      const name = drive.networkName
      const index = this.drives.findIndex(({ networkName }) => networkName === name)

      this.drives.splice(index, 1)
    },
    save: debounce(function () {
      // TODO: Handle Save

      this.notify('Drives are not yet enabled. If you wish to do so, please go on the vsauce manager.', true)
    }, 300)
  },

  watch: {
    app: {
      deep: true,
      handler () {
        this.$nextTick(() => {
          this.populateDrives()
        })
      }
    }
  }
}
</script>
