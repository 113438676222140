<template lang="pug">
  app-wrapper
    v-container(fluid)
      general-information(@save='save')

      settings(@save='save')

      platforms
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import { debounce } from 'lodash'

import AppWrapper from '@/components/layout/appWrapper.vue'

import GeneralInformation from '@/components/general/generalInformation.vue'
import Settings from '@/components/general/settings.vue'
import Platforms from '@/components/general/platforms.vue'

export default {
  name: 'AppInformation',

  components: { GeneralInformation, Settings, Platforms, AppWrapper },

  computed: {
    ...mapGetters('apps', ['app'])
  },

  methods: {
    ...mapActions('apps', [
      'saveGeneralInfo'
    ]),

    save: debounce(async function (data) {
      await this.saveGeneralInfo({
        ...data,
        appId: this.app.id
      })
    }, 300)
  }
}
</script>
