<template lang="pug">
  v-card(shaped, outlined)
    v-card-title.text-center
      v-spacer
      img(:src='icons.adjustIcon', width='36', height='36')
      v-spacer

      template(v-if='getIcon(platform)')
        v-icon(
          large,
          :color='getColor(platform)'
        ) {{ getIcon(platform) }}
      template(v-else)
        v-img(:src='getLogo(platform)', contain, max-width='100px', max-height='40px')

      v-spacer

      template(v-if="os === 'ios'")
        v-icon(color='cyan', large) {{ icons.mdiAppleIos }}

      template(v-else)
        v-icon(color='green', large) {{ icons.mdiAndroidHead }}

      v-spacer

    v-divider

    template(v-if='!adjustApp')
      template(v-if='!isAppLoaded || isAppCreating')
        v-card-text.text-center
          v-progress-circular(indeterminate)

      template(v-else)
        v-card-text
          adjust-create-card(:platform='platform')

    template(v-else)
      v-card-text
        v-text-field(
          label='Adjust App Name',
          outlined,
          readonly,
          v-model='adjustApp.name'
        )
          template(v-slot:append)
            v-icon(@click='copyText(adjustApp.name)') {{ icons.mdiContentCopy }}

        v-text-field(
          label='Adjust App Token',
          readonly,
          outlined,
          v-model='adjustApp.adjust_app_token'
        )
          template(v-slot:append)
            v-icon(@click='copyText(adjustApp.adjust_app_token)') {{ icons.mdiContentCopy }}

        v-text-field(
          v-if="os === 'android'",
          label='Android Signature Hash',
          clearable,
          outlined,
          readonly,
          v-model='adjustApp.android_signature_hash'
        )
          template(v-slot:append)
            v-icon(@click='copyText(adjustApp.android_signature_hash)') {{ icons.mdiContentCopy }}

        v-text-field(
          label='Signature File V2',
          readonly,
          outlined,
          :loading='signatureFileLoading.plugin',
          v-model='vsauceConfig.AdjustSignatureLinkV2'
        )
          template(v-slot:append)
            v-tooltip(top)
              template(v-slot:activator='{ on }')
                v-icon.mr-2(@click="retrieveSignatureLink(platform, 'plugin')", v-on='on') {{ icons.mdiDatabaseRefresh }}
              div
                span Refresh Signature File
            template(v-if="getSignatureLink('plugin')")
              v-icon(@click="copyText(getSignatureLink('plugin'))") {{ icons.mdiContentCopy }}
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import { mdiAndroidHead, mdiAppleIos, mdiContentCopy, mdiDatabaseRefresh } from '@mdi/js'

import AdjustCreateCard from '@/components/attribution/adjust/adjustCreateCard.vue'

import AppDecorators from '@/mixins/appDecorators'
import copyText from '@/mixins/copyText.js'

import adjustIcon from '@/assets/icons/logo-adjust.png'

export default {
  name: 'AdjustPlatformCard',

  mixins: [copyText, AppDecorators],

  components: { AdjustCreateCard },

  props: {
    platform: String
  },

  async mounted () {
    this.refreshVSauceConfig()
    await this.refreshApp()
  },

  data: () => ({
    icons: { mdiAndroidHead, mdiAppleIos, mdiContentCopy, mdiDatabaseRefresh, adjustIcon },

    changes: {
      android: {
        name: null,
        sha1: null
      },
      ios: {
        name: null
      }
    },
    signatureFileLoading: {
      plugin: false,
      forked: false
    }
  }),

  computed: {
    ...mapState('adjust', {
      adjustApps: 'app'
    }),
    ...mapGetters('adjust', [
      'isLoaded', 'isCreating'
    ]),
    ...mapGetters('apps', ['app']),
    ...mapGetters('vsauce', ['getConfig']),

    currentApp () {
      return this.app.apps.find(({ platform }) => platform === this.platform)
    },

    isAppCreating () {
      return this.isCreating(this.currentApp.bundleId)
    },

    isAppLoaded () {
      return this.isLoaded(this.platform)
    },

    adjustApp () {
      return this.adjustApps && this.adjustApps[this.platform]
    },

    vsauceConfig () {
      const config = this.getConfig(this.currentApp.id)
      return (config && config.Adjust) || {}
    },

    os () {
      return this.platform.split('_')[0]
    }
  },

  methods: {
    ...mapActions('adjust', {
      askForSignatureLink: 'getSignatureLink',
      getApp: 'getApp'
    }),
    ...mapActions('vsauce', [
      'saveConfig'
    ]),
    async refreshApp () {
      if (!this.isAppLoaded) {
        await this.getApp({
          appId: this.currentApp.id,
          platform: this.platform,
          storeId: this.currentApp.storeId,
          releaseStatus: this.currentApp.releaseStatus
        })
      } else {
        this.$store.commit('adjust/setApp', { platform: this.platform, app: this.adjustApps[this.platform] }, { root: true })
      }
    },
    refreshVSauceConfig () {
      this.$store.commit('vsauce/addConfig', { key: this.currentApp.id, config: this.vsauceConfig }, { root: true })
    },
    getSignatureLink (type = 'forked') {
      if (!this.vsauceConfig) return 'N/A'

      const key = `AdjustSignatureLink${type === 'plugin' ? 'V2' : ''}`

      return this.vsauceConfig[key]
    },
    async retrieveSignatureLink (platform, type = 'forked') {
      this.signatureFileLoading[type] = true

      try {
        await this.askForSignatureLink({
          type,
          platform,
          app: this.currentApp
        })

        this.refreshVSauceConfig()
      } catch (e) {}

      this.signatureFileLoading[type] = false
    }
  }
}
</script>
