export default {
  creating: {
    ios: false,
    android: false
  },
  createdId: null,
  loading: false,
  current: null,
  all: [],
  recent: []
}
