import axios from 'axios'
import { v4 } from 'uuid'

/**
 * GTM-300
 * Interceptor to add a correlation id to track end to end requests
 * naming: trace id following opentelemetry tracing api convention
 */
axios.interceptors.request.use(config => {
  config.headers['x-correlation-id'] = v4()

  return config
})

export default {
  async getConfiguration ({ commit }, { appId, network }) {
    try {
      const { data } = await axios.get('/acquisition/configuration', {
        params: { network, appId }
      })

      commit('setNetworkConfiguration', { network, configuration: data })
    } catch (e) {
      console.error(e)
    }
  },
  async saveConfiguration ({ commit }, { appId, network, configuration }) {
    try {
      await axios.put('/acquisition/configuration', { appId, network, configuration })

      commit('setNetworkConfiguration', { network, configuration })
    } catch (e) {
      commit('setNetworkConfiguration', { network, configuration: { error: 'Network save failed' } })
    }
  },

  async createCampaigns ({ commit }, data) {
    commit('setLoading', true)

    try {
      const { data: res } = await axios.post('/acquisition/campaigns', data)

      commit('setCreatedCampaigns', res)
      commit('setCreatedError', null)
      commit('setCreatedStatus', true)
    } catch (e) {
      commit('setCreatedError', e.response ? e.response.data : e.message)
      commit('setCreatedStatus', true)
    }

    commit('setLoading', false)
  },
  async getCampaigns ({ commit, rootGetters }) {
    commit('setLoading', true)

    try {
      const { data } = await axios.get('/acquisition/campaigns', {
        params: {
          apps: rootGetters['apps/app'].apps.map(({ id }) => id).join(';')
        }
      })

      commit('setCurrentBatches', data)
      commit('setCurrentCampaignError', null)
    } catch (e) {
      commit('setCurrentCampaignError', e.message)
    }

    commit('setLoading', false)
  },

  /**
   *
   * @param {import('vuex').ActionContext} param
   * @param {Object} params
   * @param {string} params.app
   * @param {string} params.network
   * @param {string} params.country
   */
  async getCreatives ({ commit }, { app, network, countries }) {
    try {
      const { data: { data } } = await axios.get('/acquisition/creatives', {
        params: {
          network,
          countries,
          unifiedAppId: app.id
        }
      })

      commit('setAvailableCreatives', data)
      commit('setCreativesError', null)
    } catch (e) {
      commit('setCreativesError', { network, countries, message: e.response ? e.response.data : e.message })
    }
  },

  async getBatch ({ commit }, id) {
    try {
      const { data } = await axios.get(`/acquisition/campaigns/fromBatch/${id}`)

      commit('setCurrentBatch', { id, data })
    } catch (e) {
      commit('setCurrentBatch', { id, data: { error: e } })
    }
  }
}
