export default [
  // IOS Distributions
  { value: 'ios', text: 'iOS Worldwide' },
  { value: 'ios_china', text: 'iOS China' },
  { value: 'ios_line', text: 'iOS Line' },

  // Android Distributions
  { value: 'android', text: 'Android Worldwide' },
  { value: 'android_xiaomi', text: 'Android Xiaomi' },
  { value: 'android_huawei', text: 'Android Huawei' },
  { value: 'android_tencent', text: 'Android Tencent' },
  { value: 'android_oppo', text: 'Android Oppo' },
  { value: 'android_line', text: 'Android Line' }
]
