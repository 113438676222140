<template lang="pug">
  v-container(fluid)
    v-card(outlined)
      v-card-title Tenjin

      v-card-subtitle Nothing to see here, Tenjin should disapear from Voodoo's ecosystem shortly.

      v-divider

      v-card-text
        v-row(justify='center', align='center')
          v-col(cols='8')
            v-img(src='https://media.giphy.com/media/kaBU6pgv0OsPHz2yxy/giphy.gif')
</template>

<script>
export default {
  name: 'Tenjin'
}
</script>
