<template lang="pug">
  v-dialog(
    v-model='show',
    max-width='90%'
  )
    v-card
      v-overlay(
        absolute,
        :value='loading'
      )
        v-progress-circular(indeterminate)

      v-card-title
        template(v-if='canCreate')
          span Campaign creation review
        template(v-else)
          span Campaign batch review
          v-spacer
          v-icon(@click='copyBatchUrl') {{ icons.mdiShare }}

      v-card-subtitle(v-if='canCreate') Please check one last time that what you are about to create is what you want to create.

      v-divider

      v-card-text
        template(v-if='data')
          template(v-if='!data.campaigns.length')
            v-alert.mt-4(type='warning', outlined) Seems like this batch is empty

          template(v-else)
            v-container(fluid)
              template(v-for='campaign in data.campaigns')
                v-card.mb-2
                  v-card-title.text-capitalize
                    template(v-if='campaign.network.icon')
                      v-icon(large, :color='campaign.network.color') {{ campaign.network.icon }}
                    template(v-else-if='campaign.network.image')
                      v-avatar(tile, size='30')
                        v-img(:src='campaign.network.image')

                    span.ml-2 {{ campaign.network.text }}

                  v-divider

                  v-card-text
                    display(
                      :campaign='campaign',
                      :data='data',
                      :ref='campaign.network.value'
                    )

        template(v-else)
          v-alert(.mt-4, type='error', outlined) Nothing to see here

      v-divider

      v-card-actions
        v-spacer
        v-btn(
          outlined,
          @click='hide'
        ) {{ canCreate ? 'Cancel' : 'Close' }}
        v-btn(
          color='primary',
          v-if='canCreate',
          @click='create'
        ) Yes, create!
</template>

<script>
import { mapState } from 'vuex'
import { mdiShare } from '@mdi/js'

import copyText from '@/mixins/copyText'
import Batch from '@/mixins/acquisition/batch.js'

import Display from './display.vue'

export default {
  name: 'CampaignReviewDialog',

  components: { Display },

  mixins: [Batch, copyText],

  props: {
    canCreate: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data: () => ({
    icons: { mdiShare },

    show: false,

    data: null
  }),

  computed: {
    ...mapState('acquisition', ['loading'])
  },

  methods: {
    reveal () {
      this.show = true
    },
    hide () {
      this.show = false
    },
    setData (data) {
      this.data = data
    },

    create () {
      this.$emit('create', this.data)
    },

    async copyBatchUrl () {
      await this.copyText(`${window.origin}/app/acquisition/campaigns/history?appId=${this.$route.query.appId}&batchId=${this.data.batchId}`)
    }
  },

  watch: {
    data: {
      deep: true,
      handler () {}
    }
  }
}
</script>
