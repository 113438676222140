<template lang="pug">
  v-autocomplete(
    label='Add user',
    outlined,
    v-model='user',
    :search-input.sync='term',
    :loading='loading',
    :items='items'
  )
</template>

<script>
import { debounce } from 'lodash'
import { mapActions } from 'vuex'

export default {
  name: 'UserSearch',

  props: {
    groupName: {
      type: String,
      required: true
    }
  },

  data: () => ({
    loading: false,
    term: null,
    user: null,

    users: []
  }),

  computed: {
    items () {
      return this.users
        .map(({ profile, id }) => ({
          text: profile.displayName || `${profile.firstName} ${profile.lastName}`,
          value: id
        }))
    }
  },

  methods: {
    ...mapActions('auth', ['actOnUserInGroup']),

    search: debounce(async function (term) {
      if (!term) return

      this.loading = true

      const { data } = await this.$axios.get('/users/search', {
        params: { term: term }
      })

      this.users = data

      this.loading = false
    }, 300),

    async add (userId) {
      await this.actOnUserInGroup({
        action: 'add',
        groupName: this.groupName,
        userId
      })

      this.$emit('added')
    }
  },

  watch: {
    async term (term) {
      return this.search(term)
    },

    async user (userId) {
      return this.add(userId)
    }
  }
}
</script>
