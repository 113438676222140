var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allLineItems,"expanded":_vm.expanded,"item-key":"localId","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.adUnit",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{staticClass:"pt-5",attrs:{"name":"AdUnitSelection","outlined":"","label":"Select adUnit","items":_vm.adUnits,"item-text":"name","return-object":""},on:{"change":function (adUnit) { return _vm.updateLineItem(item, { adUnit: adUnit }); }},model:{value:(item.adUnit),callback:function ($$v) {_vm.$set(item, "adUnit", $$v)},expression:"item.adUnit"}})]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{staticClass:"pt-5",attrs:{"name":"NetworkSelection","outlined":"","label":"Select network","items":_vm.orders,"item-text":"network","return-object":""},on:{"change":function (order) { return _vm.updateLineItem(item, { order: order }); }},model:{value:(item.order),callback:function ($$v) {_vm.$set(item, "order", $$v)},expression:"item.order"}})]}},{key:"item.bid",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.bid,"large":"","persistent":""},on:{"update:returnValue":function($event){return _vm.$set(item, "bid", $event)},"update:return-value":function($event){return _vm.$set(item, "bid", $event)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":"Edit","min":0,"single-line":"","autofocus":""},on:{"change":function (bid) { return _vm.updateLineItem(item, { bid: bid }); }},model:{value:(item.bid),callback:function ($$v) {_vm.$set(item, "bid", $$v)},expression:"item.bid"}})]},proxy:true}],null,true)},[_c('v-row',{staticClass:"px-2"},[_c('div',{staticClass:"subtitle-1"},[_vm._v(_vm._s(item.bid))]),_c('v-icon',{staticClass:"pl-2"},[_vm._v(_vm._s(_vm.icons.edit))])],1)],1)]}},{key:"item.countries",fn:function(ref){
var item = ref.item;
return [_c('countries-selector',{attrs:{"countries":item.countries},on:{"change":function (countries) { return _vm.updateLineItem(item, { countries: countries }); }}})]}},{key:"item.includeGeoTargeting",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mb-5",attrs:{"name":"IncludeExclude","color":_vm.setGeoTargetingColor(item.includeGeoTargeting)},on:{"click":function($event){return _vm.setGeoTargeting(item)}}},[_vm._v(_vm._s(item.includeGeoTargeting))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function (active) { return _vm.updateLineItem(item, { active: active }); }},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.actions",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.duplicateItem(item)}}},[_vm._v(_vm._s(_vm.icons.copy))]),_c('v-icon',{staticClass:"ml-5",attrs:{"small":""},on:{"click":function($event){return _vm.removeLineItem(item)}}},[_vm._v(_vm._s(_vm.icons.minus))])]}},{key:"body.append",fn:function(){return [_c('td',{attrs:{"colspan":4}}),_c('td',{attrs:{"colspan":1}},[_c('v-icon',{attrs:{"small":""},on:{"click":_vm.removeLineItem}},[_vm._v(_vm._s(_vm.icons.minus))]),_c('v-icon',{staticClass:"ml-5",attrs:{"small":""},on:{"click":_vm.createNewLineItem}},[_vm._v(_vm._s(_vm.icons.plus))])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(item.order.network)?_c('td',{attrs:{"colspan":headers.length}},[_c('advanced-line-item-form',{attrs:{"isUpdate":false,"lineItemOverrideFields":item.overrideFields,"network":item.order.network,"networkSync":_vm.newLineItem.networkSync,"mediationLineItemId":_vm.newLineItem.mediationLineItemId,"mediationSync":_vm.newLineItem.mediationSync},on:{"set-advanced-options":function (values) { return _vm.updateLineItem(item, values); }}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }