<template lang="pug">
v-container(fluid, v-if='newLineItem')
    v-row(justify='space-around', align='center').card-size.pt-2
      v-col(cols='3')
        countries-selector(
          :countries='newLineItem.countries'
          @change='(countries) => lineItemChange({ countries })'
        )

      v-col(cols='1')
        v-tooltip(top)
          template(v-slot:activator='{ on }')
            div(v-on='on')
              v-btn(
                name='IncludeExclude',
                @click='setGeoTargeting',
                :color='setGeoTargetingColor(newLineItem.includeGeoTargeting)'
              ) {{ newLineItem.includeGeoTargeting }}
          span {{ newLineItem.includeGeoTargeting }} Geo Targeting

      v-col(cols='1')
        v-text-field(
          flat,
          name='CPM',
          type='number',
          v-model='newLineItem.bid',
          label='Define CPM',
          outlined,
          @change='lineItemChange'
        )

      v-col(cols='1')
        v-switch(
          name='Active',
          hint='Status',
          persistent-hint,
          :label=`newLineItem.active ? 'Active' : 'Disabled'`,
          v-model='newLineItem.active'
          @change='lineItemChange'
        )

      v-col(cols='1')
        v-tooltip(right)
          template(v-slot:activator='{ on }')
            div(v-on='on')
              v-btn(
                icon,
                @click='showAdvanced = !showAdvanced'
              )
                v-icon(small) {{ icons.settings}}
          span Advanced options

    template(v-if='showAdvanced')
      advanced-line-item-form(
        :isUpdate='true'
        :lineItemOverrideFields='newLineItem.overrideFields'
        :network='newLineItem.network'
        :networkSync='newLineItem.networkSync'
        :mediationLineItemId='newLineItem.mediationLineItemId'
        :mediationSync='newLineItem.mediationSync'
        @set-advanced-options='lineItemChange'
        @deletion-request='lineItemDelete'
      )

</template>

<script>
import { cloneDeep } from 'lodash'
import { mdiCheck, mdiClose, mdiSettings } from '@mdi/js'

import CountriesSelector from '@/components/monetization/waterfall/common/countriesSelector'
import AdvancedLineItemForm from '@/components/monetization/waterfall/advancedLineItemForm'

import { countries } from '@/constants/constants.js'
import { mapGetters } from 'vuex'

export default {
  name: 'LineItemEdition',

  components: {
    CountriesSelector,
    AdvancedLineItemForm
  },

  props: {
    lineItem: { type: Object, required: true }
  },

  beforeMount () {
    this.init()
  },

  data: () => ({
    icons: {
      cross: mdiClose,
      check: mdiCheck,
      settings: mdiSettings
    },
    constants: {
      countries,
      colors: {
        exclude: 'red',
        include: 'green',
        all: 'grey'
      }
    },

    refresh: 1,
    newLineItem: {},

    showAdvanced: false,
    geoTargeting: false
  }),

  computed: {
    ...mapGetters('monetization', ['currentMediation'])
  },

  methods: {
    setGeoTargetingColor (status) {
      return this.constants.colors[status]
    },

    setGeoTargeting () {
      const status = this.newLineItem.includeGeoTargeting
      let newStatus

      switch (status) {
        case 'include':
          newStatus = 'exclude'
          break
        case 'exclude':
          newStatus = 'all'
          break
        case 'all':
          newStatus = 'include'
          break
      }
      this.newLineItem.includeGeoTargeting = newStatus
      this.lineItemChange()
    },

    lineItemChange (values) {
      this.newLineItem = {
        ...this.newLineItem,
        ...values,
        mediation: this.currentMediation,
        networkSync: values ? values.networkSync === true : false,
        mediationSync: values ? values.mediationSync === true : false
      }
      this.$emit('temp-line-item', this.lineItem, this.newLineItem)
    },

    lineItemDelete () {
      this.$emit('deletion-request', this.lineItem)
    },

    init () {
      this.newLineItem = cloneDeep(this.lineItem)
    }
  }
}
</script>

<style scoped lang="scss">
.card-size {
  height: 110px;
}
</style>
