export default {
  methods: {
    _getStoreUrl (platform, id) {
      const platformParts = platform.split('_')
      if (platformParts[0] === 'ios') {
        if (platformParts.length > 1 && platformParts[1] === 'china') {
          return `https://apps.apple.com/cn/app/id${id}`
        }
        return `https://apps.apple.com/us/app/id${id}`
      }

      if (platform === 'android') {
        return `https://play.google.com/store/apps/details?id=${id}`
      }

      return ''
    },

    _open (url) {
      window.open(url, '_blank')
    }
  }
}
