<template lang="pug">
  v-container(fluid)
    v-card-text
      template(v-if='loading')
        v-container(fluid)
          v-row(justify='center', align='center')
            v-progress-circular(indeterminate, size='64')

      template(v-else-if='isError')
        error(
          @retry='retrieveCreatives'
        )

      template(v-else)
        ad-group(
          :budget='budget',
          :network='network',
          :campaignType='campaignType',
          @update='update'
        )
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import AdGroup from '@/components/acquisition/creatives/adGroup.vue'
import Error from '@/components/acquisition/creatives/error.vue'

export default {
  name: 'CreativesContainer',

  components: {
    Error,
    AdGroup
  },

  props: {
    countries: {
      type: Array,
      required: true
    },
    budget: {
      type: Object,
      required: true
    },
    network: {
      type: Object,
      required: true
    },
    campaignType: {
      type: String,
      required: true
    }
  },

  async mounted () {
    return this.retrieveCreatives()
  },

  data: () => ({
    loading: true
  }),

  computed: {
    ...mapState('acquisition', ['creatives', 'platform']),
    ...mapGetters('apps', ['app']),

    isError () {
      return !!this.creatives.error
    }
  },

  methods: {
    ...mapActions('acquisition', [
      'getCreatives'
    ]),

    async retrieveCreatives () {
      this.loading = true

      try {
        await this.getCreatives({
          network: this.network.value,
          countries: this.countries.join(','),
          app: this.app.apps.find(({ platform }) => this.platform === platform)
        })
      } catch (e) {}

      this.loading = false
    },

    update (event) {
      this.$emit('update', event)
    }
  }
}
</script>
