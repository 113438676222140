import Vue from 'vue'

import axios from 'axios'

import vuetify from './plugins/vuetify'
import './plugins/auth'

import VueGtm from '@gtm-support/vue2-gtm'

import App from './App.vue'
import router from './router'
import store from './store/'

import 'roboto-fontface/css/roboto/roboto-fontface.css'

Vue.config.productionTip = false

axios.defaults.baseURL = store.state.config.api.url
axios.defaults.withCredentials = true
axios.interceptors.request.use(async (config) => {
  const accessToken = Vue.prototype.$auth.getAccessToken()
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
})

Vue.prototype.$axios = axios

Vue.use(VueGtm, {
  id: 'GTM-N4C9639',
  enabled: process.env.NODE_ENV === 'production',
  vueRouter: router
})

new Vue({
  router,
  render (h) {
    return h(App)
  },
  vuetify,
  store
})
  .$mount('#app')
