<template lang="pug">
  v-container(fluid)
    v-alert(
      v-if='alert',
      color='orange darken-1',
      dismissible,
      dark
      dense
    ).pa-0.pr-6
      v-row(
        align='center'
        justify='space-between'
      )
        v-col(cols='6').ml-12
          div {{ alertMessage }}
            a(target='_blank' :href='facebookUrl').ml-4 click to access
        v-col(cols='3').pa-0
          v-file-input(
            @change='sendFile',
            placeholder='Import csv file',
            truncate-length='10',
            dense,
            prepend-icon,
          ).pr-10

    .title.mb-3 Waterfall Preview

    v-container(fluid)
      v-row(justify='end' align='center')

        v-col(cols='2')
          export-waterfall

        //- TODO: Remove that once it's certain it can be removed
        //- v-tooltip(left)
        //-   template(v-slot:activator='{ on }')
        //-     div(v-on='on').pr-6
        //-       v-btn(
        //-         :disabled='hideUpdate'
        //-         @click='lineItemUpdatesConfirmation'
        //-       )
        //-         v-icon {{ icons.mdiPlus }}
        //-         span Update
        //-   span Update modified LineItems

        //- v-tooltip(left)
        //-   template(v-slot:activator='{ on }')
        //-     div(v-on='on')
        //-       v-btn(
        //-         @click=`$emit('line-items-create')`
        //-       )
        //-         v-icon {{ icons.mdiPlus }}
        //-         span Create
        //-   span Create new LineItem

      v-spacer.mb-10

      v-tabs(
        centered,
        v-model='activeTab'
      )
        v-file-input(
          @change='sendFile',
          placeholder='Import Facebook csv',
          truncate-length='10',
          dense
        ).pt-2

        v-spacer

        v-tab(
          v-for='adUnit in adUnits',
          :key='adUnit.id'
        ) {{ adUnit.name }}

        div.spaced

        v-tab-item(
          v-for='adUnit in adUnits',
          :key='adUnit.id'
        )
          header-biddings(
            :adUnit='adUnit'
          )
          line-items(
            :lineItems='lineItemsByAdUnit[adUnit.id]',
            @store-selected-line-item='lineItemSelectedForModification'
            @store-temp-line-item='lineItemChanges'
            @line-item-deletion-request='lineItemSelectedForDeletion'
          )

    Dialog(
      ref='dialog',
      v-bind='dialog',
    )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mdiPlus } from '@mdi/js'
import { isEqual, orderBy } from 'lodash'

import { countries, syncStatus } from '@/constants/constants.js'

import Dialog from '@/components/monetization/dialog.vue'
import LineItems from '@/components/monetization/waterfall/lineItems.vue'
import HeaderBiddings from '@/components/monetization/waterfall/headerBiddings.vue'
import ExportWaterfall from '@/components/monetization/waterfall/exportWaterfall'

export default {
  name: 'WaterfallDisplay',

  components: {
    Dialog,
    LineItems,
    HeaderBiddings,
    ExportWaterfall
  },

  data: () => ({
    icons: { mdiPlus },
    constants: {
      countries,
      syncStatus
    },

    hideUpdate: true,

    activeTab: 0,

    selectedCountries: [],
    lineItemsToUpdate: {},
    lineItemsToDelete: {},
    newLineItems: {},
    countryFilter: true,

    dialog: {
      onConfirm: () => {}
    },

    alert: false,
    alertMessage:
      'Please import facebook csv file in order to set Facebook lineItems',
    facebookUrl:
      'https://business.facebook.com/pub/properties/create?business_id=838871949512065',
    file: []
  }),

  props: {
    gameId: { type: String, required: true }
  },

  beforeMount () {
    this.initSelectedCountries()
    this.initAlert()
  },

  computed: {
    ...mapGetters('monetization/lineItems', ['getLineItemsByAppId']),
    ...mapGetters('monetization/adUnits', ['getAdUnitsByAppId']),

    adUnits () {
      return this.getAdUnitsByAppId(this.gameId)
    },

    lineItems () {
      return this.getLineItemsByAppId(this.gameId)
    },

    lineItemsByCountries () {
      if (!this.countryFilter) {
        return this.lineItems
      }

      const countries = this.selectedCountries

      if (countries.length === 0) {
        return this.lineItems
      }
      return this.lineItems.filter(lineItem => {
        if (lineItem.includeGeoTargeting === 'include') {
          return lineItem.countries.find(_country =>
            countries.includes(_country)
          )
        } else if (lineItem.includeGeoTargeting === 'exclude') {
          return lineItem.countries.every(
            _country => !countries.includes(_country)
          )
        }
        return true
      })
    },

    lineItemsByAdUnit () {
      return this.adUnits.reduce((acc, { id }) => {
        acc[id] = this.lineItemsByCountries
          .filter(lineItem => lineItem.adUnitIds[0] === id)
          .sort((a, b) => this.byCPM(a, b))
        return acc
      }, {})
    }
  },

  methods: {
    ...mapActions('monetization', ['uploadFacebookFile']),
    ...mapActions('monetization/lineItems', ['validateLineItems']),

    initAlert () {
      this.alert = this.lineItems.some(
        lineItem =>
          lineItem.network === 'facebook' &&
          this.constants.syncStatus[lineItem.syncStatus.status] <
            this.constants.syncStatus.CREATED_ON_NETWORK
      )
    },

    countriesChange (countries) {
      this.selectedCountries = countries
    },

    async sendFile (file) {
      const text = await file.text()
      await this.uploadFacebookFile({
        file: text,
        appKey: this.gameId
      })
      this.alert = false
      this.file = []
    },

    initSelectedCountries () {
      this.selectedCountries = this.constants.countries.map(({ code }) => code)
    },

    lineItemUpdatesConfirmation () {
      let bodyMessage = '<ul>'

      const fields = [
        'bid',
        'active',
        'mediationSync',
        'networkSync',
        'mediationLineItemId',
        'includeGeoTargeting'
      ]

      const lines = Object.values(this.newLineItems)

      lines.forEach(newLine => {
        const oldLine = this.lineItemsToUpdate[newLine.id]

        bodyMessage += `<li><h3>You will update ${oldLine.network}_${
          oldLine.format
        }_${oldLine.bid}_${oldLine.countries.slice(0, 10) || 'ALL'}</h3></>`

        fields.forEach(field => {
          if (newLine[field] !== oldLine[field]) {
            if (oldLine[field] !== undefined) {
              bodyMessage += `${field}: ${oldLine[field]} -> ${newLine[field]}<br/>`
            } else {
              bodyMessage += `(+) ${field}: ${newLine[field]}<br/>`
            }
          }
        })

        if (newLine.overrideFields) {
          const keys = Object.keys(newLine.overrideFields)

          keys.forEach(key => {
            if (
              newLine.overrideFields &&
              oldLine.overrideFields &&
              newLine.overrideFields[key] !== oldLine.overrideFields[key]
            ) {
              bodyMessage += `${key}: ${oldLine.overrideFields[key]} -> ${newLine.overrideFields[key]}<br/>`
            } else if (
              newLine.overrideFields &&
              !oldLine.overrideFields &&
              newLine.overrideFields[key]
            ) {
              bodyMessage += `(+) ${key}: ${newLine.overrideFields[key]}<br/>`
            }
          })
          if (
            !isEqual(
              orderBy(newLine.countries, x => x.name),
              orderBy(oldLine.countries, x => x.name)
            )
          ) {
            bodyMessage += 'Countries changed<br/>'
          }
        }
      })
      Object.values(this.lineItemsToDelete).forEach(li => {
        bodyMessage += `<li><h3>You will delete ${li.network}_${li.format}_${li.bid}</h3></li>`
      })
      this.$refs.dialog.setBody(bodyMessage.concat('</ul>'))

      this.$refs.dialog.setTitle('Update lineItems')

      this.dialog.onConfirm = this.pushLineItemsAndApplyChanges
      this.$refs.dialog.open()
    },

    pushLineItemsAndApplyChanges () {
      const lines = Object.values(this.newLineItems)
      lines.forEach(line => {
        const keys = Object.keys(line)
        keys.forEach(key => {
          this.lineItemsToUpdate[line.id][key] = line[key]
        })
        delete line.syncStatus
      })
      this.$emit(
        'line-items-update',
        Object.values(this.newLineItems),
        Object.keys(this.lineItemsToDelete)
      )
      this.hideUpdate = true
    },

    byCPM (firstItem, secondItem) {
      return secondItem.bid - firstItem.bid
    },

    lineItemSelectedForModification (lineItem) {
      lineItem.syncStatus.status = 'MODIFIED'
      this.lineItemsToUpdate[lineItem.id] = lineItem
    },
    lineItemSelectedForDeletion (lineItem) {
      this.hideUpdate = false
      lineItem.syncStatus.status = 'WAITING_DELETION'
      this.lineItemsToDelete[lineItem.id] = lineItem
    },
    async lineItemChanges (lineItem) {
      this.hideUpdate = false
      this.newLineItems[lineItem.id] = lineItem
      await this.validateLineItems({ lineItems: [lineItem] })
    }
  }
}
</script>

<style lang="scss" scoped>
.no-space {
  margin-top: -30px;
}
.spaced {
  flex-grow: 3;
}
</style>
