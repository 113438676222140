import Vue from 'vue'
import Router from 'vue-router'

import Home from '@/views/Home.vue'

// Okta things
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue, { LoginCallback } from '@okta/okta-vue'

import oktaConfig from '@/plugins/okta'

// App views

import Admin from '@/views/auth/admin.vue'

// General
import Information from './views/general/information.vue'
import GoogleDrive from './views/general/googleDrive.vue'

// Attribution
import Adjust from './views/attribution/adjust.vue'
import Tenjin from './views/attribution/tenjin.vue'

// Acquisition
import CampaignsCreation from './views/acquisition/campaigns.vue'
import CampaignsHistory from './views/acquisition/history.vue'

// Monetization
import Waterfall from './views/monetization/waterfall.vue'
import AppHistory from './views/monetization/appHistory.vue'
import History from './views/monetization/history.vue'

// Public
import PublicAppForm from './views/public/publicAppForm.vue'

// Configuration
import Configuration from './views/configuration/configuration.vue'
import VSConfiguration from './views/configuration/vsauceConfiguration.vue'

// Developper
import Analytics from './views/developper/analytics.vue'
import CustomLinks from './views/developper/custom.vue'

const oktaAuth = new OktaAuth(oktaConfig.oidc)

Vue.use(Router)
Vue.use(OktaVue, { oktaAuth })

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: 'App Settings | Home',
        requiresAuth: true
      }
    },
    {
      path: '/implicit/callback',
      name: 'handle-callback',
      component: LoginCallback
    },

    // Admin
    {
      path: '/auth/admin',
      component: Admin,
      name: 'Admin',
      meta: {
        title: 'App Settings | User Administration',
        requiresAuth: true
      }
    },

    // VSauce
    {
      path: '/vsauce/configuration',
      component: Configuration,
      name: 'Configuration',
      meta: {
        title: 'App Settings | Voodoo Sauce Configuration',
        requiresAuth: true
      }
    },

    // General
    {
      path: '/app/general/information',
      component: Information,
      name: 'Information',
      meta: {
        title: 'App Settings | Information',
        requiresAuth: true
      }
    },
    {
      path: '/misc/googleDrive',
      component: GoogleDrive,
      name: 'GoogleDrive',
      meta: {
        title: 'App Settings | GoogleDrive',
        requiresAuth: true
      }
    },
    // Attribution
    {
      path: '/app/attribution/adjust',
      component: Adjust,
      name: 'Adjust',
      meta: {
        title: 'App Settings | Adjust',
        requiresAuth: true
      }
    },
    {
      path: '/app/attribution/tenjin',
      component: Tenjin,
      name: 'Tenjin',
      meta: {
        title: 'App Settings | Tenjin',
        requiresAuth: true
      }
    },
    // Acquisition
    {
      path: '/app/acquisition/campaigns/creation',
      component: CampaignsCreation,
      name: 'Campaigns',
      meta: {
        title: 'App Settings | Campaigns Creation',
        requiresAuth: true
      }
    },
    {
      path: '/app/acquisition/campaigns/history',
      component: CampaignsHistory,
      name: 'CampaignsHistory',
      meta: {
        title: 'App Settings | Campaigns Creation History',
        requiresAuth: true
      }
    },
    // Monetization
    {
      path: '/app/monetization/waterfall',
      component: Waterfall,
      name: 'Waterfall',
      meta: {
        title: 'App Settings | Waterfall',
        requiresAuth: true
      }
    },
    {
      path: '/app/monetization/history',
      component: AppHistory,
      name: 'AppHistory',
      meta: {
        title: 'App Settings | History',
        requiresAuth: true
      }
    },
    {
      path: '/monetization/history',
      component: History,
      name: 'History',
      meta: {
        title: 'History',
        requiresAuth: true
      }
    },
    // Public Form
    {
      path: '/public/form',
      component: PublicAppForm,
      name: 'PublicAppForm',
      meta: {
        title: 'Public App form',
        norequiresAuth: true
      }
    },
    // Configuration
    {
      path: '/app/configuration/vsauce',
      component: VSConfiguration,
      name: 'VSauce Configuration',
      meta: {
        title: 'App Settings | Voodoo Sauce Configuration',
        requiresAuth: true
      }
    },
    // Developper
    {
      path: '/app/developper/analytics',
      component: Analytics,
      name: 'Analytics',
      meta: {
        title: 'App Settings | Analytics',
        requiresAuth: true
      }
    },
    {
      path: '/app/developper/custom',
      component: CustomLinks,
      name: 'CustomLinks',
      meta: {
        title: 'App Settings | Custom Links',
        requiresAuth: true
      }
    },

    // Global redirect
    {
      path: '*',
      redirect: '/'
    }
  ]
})

function hasQueryParams (route) {
  return !!Object.keys(route.query).length
}

function isAppRoute (route) {
  return route.path.includes('/app/') || route.path.includes('/misc/')
}

router.beforeEach(async (to, from, next) => {
  if (isAppRoute(to) && !hasQueryParams(to) && hasQueryParams(from)) {
    return next({ name: to.name, query: from.query })
  }

  return next()
})

export default router
