<template>
  <v-card class="mb-4" outlined>
    <v-card-title><span>Creatives available</span>
      <v-spacer></v-spacer>
      <template v-if="limit.isReached">
        <v-alert class="mt-4" type="error" dense outlined>Limit reached, remove some creatives to add new ones.</v-alert>
      </template>
      <v-spacer v-if="limit.isReached"></v-spacer>
      <v-text-field label="Search" v-if="!hide" v-model="term" hide-details clearable outlined></v-text-field>
      <v-spacer></v-spacer>
      <v-btn icon @click="hide = !hide">
        <v-icon>{{ hide ? icons.mdiWindowMaximize : icons.mdiWindowMinimize }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-expand-transition>
        <v-container fluid v-show="!hide">
          <v-row class="creatives-container">
            <creative v-for="creative in filteredCreatives" :key="creative.name" :creative="creative" :selected="!!isSelected(creative)" :class="{ selected: isSelected(creative) }" :disabled="limit.isReached && !isSelected(creative)" @toggle="toggleCreative" />
          </v-row>
        </v-container>
      </v-expand-transition>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiWindowMinimize, mdiWindowMaximize } from '@mdi/js'

import Creative from '@/components/acquisition/creatives/creative.vue'

export default {
  name: 'CreativesGroup',

  components: {
    Creative
  },

  data () {
    return ({
      icons: { mdiWindowMinimize, mdiWindowMaximize },
      hide: false,
      term: null,
      selected: [],
      limit: {
        isReached: false,
        number: this.campaignType === 'splitTest' ? 5 : 50
      }
    })
  },

  props: {
    country: {
      type: String,
      required: true
    },
    creatives: {
      type: Array,
      required: true
    },
    network: {
      type: Object,
      required: true
    },
    campaignType: {
      type: String,
      required: true
    }
  },

  computed: {
    isValid () {
      return this.selected.length > 0
    },
    filteredCreatives () {
      if (!this.term) return this.creatives

      return this.creatives.filter(({ name }) => name.toLowerCase().includes(this.term.toLowerCase()))
    }
  },

  methods: {
    isSelected (creative) {
      return this.selected.find(({ name }) => name === creative.name)
    },

    toggleCreative (creative) {
      const isSelected = this.isSelected(creative)

      // Removing the selected item if already sleected
      if (isSelected) {
        this.selected = this.selected.filter((creative) => creative.name !== isSelected.name)

      // Adding the selected creative
      } else {
        !this.limit.isReached && this.selected.push(creative)
      }

      this.limit.isReached = this.selected.length === this.limit.number
      this.$emit('update', this.selected)
    }
  },

  watch: {
    isValid: {
      deep: true,
      handler (bool) {
        this.$emit('isValid', { country: this.country, value: bool })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.creatives-container {
  overflow-y: scroll;
  overflow-x: hidden;
  resize: vertical;
  min-height: 320px;
  height: 320px;
}

.selected {
  background-color: rgba(129, 199, 132, 0.4);
}
</style>
