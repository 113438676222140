import { networks } from '@/constants/constants.js'

export default {
  addOrders (state, { mediation, appId, orders }) {
    state.orders[mediation][appId] = orders.filter((order) => order.mediation === mediation)
    networks.forEach(network => {
      if (!orders.find(order => order.network.toLowerCase() === network.toLowerCase())) {
        state.orders[mediation][appId].push({
          name: network,
          id: '',
          mediation,
          network
        })
      }
    })
  },
  addOrder (state, { mediation, appId, order }) {
    state.orders[mediation][appId].map(_order => {
      if (_order.network.toLowerCase() === order.network.toLowerCase()) {
        _order.name = order.name
        _order.id = order.id
        _order.network = order.network
      }
    })
  }
}
