<template lang="pug">
  v-responsive(
      class='mr-0 mr-md-6 hidden-xs-only transition-swing'
      :max-width='isFocused ? 400 : 250'
    )
      v-autocomplete(
        dark,
        ref='search',
        v-if='hasApp',
        v-model='term',
        :items='apps',
        label='Search Apps',
        item-text='name',
        item-value='name',
        color="primary",
        dense,
        hide-details,
        rounded,
        solo-inverted,
        clearable,
        :loading='loading',
        @blur='onBlur',
        @focus='onFocus',
        @update:search-input='onInput',
        @keydown.esc='onEsc'
      )
        template(v-slot:prepend-inner)
          template
            v-icon(:color="isFocused ? 'black' : 'default'") {{ icons.mdiMagnify }}

        template(v-slot:item='data')
          template
            v-list-item-avatar(@click.stop='goTo(data.item)')
              img(:src='data.item.iconUrl')
            v-list-item-content(@click.stop='goTo(data.item)')
              v-list-item-title(v-html='data.item.marketingAttributes ? data.item.marketingAttributes.voodooName : data.item.name')
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { debounce } from 'lodash'
import { mdiMagnify, mdiOpenInNew } from '@mdi/js'

export default {
  name: 'SearchApps',

  data: () => ({
    icons: { mdiMagnify, mdiOpenInNew },

    isFocused: false,
    term: null,
    loading: false,

    timeout: null
  }),

  computed: {
    ...mapGetters('apps', [
      'hasApp',
      'apps'
    ])
  },

  methods: {
    ...mapActions('apps', [
      'retrieveApps'
    ]),

    async getApps (term = this.term) {
      this.loading = true

      await this.retrieveApps({ term })

      this.loading = false
    },

    getHRef (app) {
      return `${window.location.origin}${this.$route.path}?appId=${app.id}`
    },
    goTo (app) {
      window.open(this.getHRef(app), '_self')
    },

    onBlur () {
      this.resetSearch()
    },
    onEsc () {
      this.$refs.search.blur()
    },
    onFocus () {
      clearTimeout(this.timeout)
      this.isFocused = true
    },
    onInput: debounce(async function (term) {
      if (term) await this.getApps(term)
    }, 300),
    resetSearch (timeout = 0) {
      clearTimeout(this.timeout)
      this.$nextTick(() => {
        this.search = undefined
        this.isSearching = false
        this.timeout = setTimeout(() => (this.isFocused = false), timeout)
      })
    }
  }
}
</script>
