<template lang="pug">
  v-container(fluid)
    v-card(outlined)
      v-card-title
        v-row(justify='space-between', align='center')
          v-col(cols='12', sm='4', md='4')
            span All Apps

          v-col(cols='12', sm='3', md='3', offset-md='2')
            v-text-field(
              v-model='term',
              label='Search',
              color="primary",
              :loading='loading',
              dense,
              rounded,
              solo-inverted,
              clearable,
              persistent-hint,
              :hint='`Search ${apps.length} apps`'
            )

          v-col.text-center(cols='12', sm='4', md='2')
            create-apps

      v-divider

      v-card-text
        v-container(fluid)
          template(v-if='apps.length')
            v-row(no-gutters)
              v-col(cols='12')
                v-fade-transition(
                  leave-absolute,
                  group,
                  ref='apps',
                  class='trans layout row wrap justify-space-around',
                  tag='div'
                )
                  template(v-for='app in reducedApps')
                    v-col(:key='app.id', cols='6', sm='6', md='3', lg='2')
                      app-card(:app='app')

              v-col(cols='12')
                v-pagination(
                  v-model='page',
                  :length='pages'
                )
          template(v-else)
            .text-center.subtitle-1 No app found.
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { debounce } from 'lodash'

import AppCard from './app.vue'
import CreateApps from './createApps.vue'

export default {
  name: 'AllApps',

  components: { AppCard, CreateApps },

  data: () => ({
    term: null,
    loading: false,

    page: 1,
    entryPerPage: 24
  }),

  computed: {
    ...mapGetters('apps', {
      allApps: 'apps'
    }),

    apps () {
      const apps = this.allApps

      return apps
        .sort((a, b) => a.name < b.name ? -1 : 1)
    },
    pages () {
      return Math.ceil(this.apps.length / this.entryPerPage)
    },

    reducedApps () {
      const index = this.page - 1

      const range = {
        inf: index * this.entryPerPage,
        sup: (index + 1) * this.entryPerPage
      }

      return this.apps.slice(range.inf, range.sup)
    }
  },

  methods: {
    ...mapActions('apps', [
      'retrieveApps'
    ]),

    async getApps () {
      this.loading = true

      await this.retrieveApps({ term: this.term })

      this.loading = false
    }
  },

  watch: {
    term: debounce(async function (term) {
      return this.getApps()
    }, 300)
  }
}
</script>
