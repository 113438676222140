export default {
  data: () => ({
    appId: '',
    formId: ''
  }),
  async beforeMount () {
    const { appId, formId } = this.$route.query

    if (!appId || !formId) return this.$router.push({ path: '/' })

    this.appId = appId
    this.formId = formId

    await this.$store.commit('monetization/forms/setLoading', true)
    try {
      await this.$store.dispatch('monetization/forms/getForm', { appId, formId })
      const form = this.$store.getters['monetization/forms/form']
      this.$store.commit('monetization/setMediation', form.mediation)
      await this.$store.dispatch('monetization/overrideFields/fetchOverrideFields', false)
    } finally {
      await this.$store.commit('monetization/forms/setLoading', false)
    }
  }
}
