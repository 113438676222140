<template lang="pug">
  v-form(
    v-model='valid'
    ref='form'
    class='p-5'
    v-on:submit.prevent
    v-on:submit='submit'
  )
    v-container
      v-row
        v-col(
          cols='12'
          md='3'
          v-for='{ key, description } in allOverrideFields[mediation][network]'
          :key='key'
        )
          v-text-field(
            :key='key'
            :rules='rules'
            :label='description'
            v-model='values[key]'
            required
          )
      v-btn(:disabled='!valid' @click='submit') Submit
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OverrideFieldsForm',

  props: {
    lineItemOverrideFields: {
      type: Object
    },
    network: {
      type: String,
      required: true
    },
    mediation: {
      type: String,
      required: true
    }
  },

  beforeMount () {
    this.values = { ...this.lineItemOverrideFields }
  },

  data: () => ({
    values: {},
    valid: false,
    rules: [v => !!v || 'A value is required']
  }),

  computed: {
    ...mapGetters('monetization/overrideFields', ['allOverrideFields'])
  },

  methods: {
    submit () {
      this.$emit('validate', { values: this.values, valid: this.valid })
    }
  }
}
</script>
