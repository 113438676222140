<template lang="pug">
  v-app
    v-fade-transition
      template(v-if='hasApp')
        drawer

    toolbar
    notification

    v-content
      v-fade-transition(mode='out-in')
        router-view(:key='$route.path')

    v-overlay(:value='isLoading')
      v-progress-circular(indeterminate, size='64')
</template>

<script>
import { mapGetters } from 'vuex'

// Layout components
import Toolbar from '@/components/layout/toolbar/toolbar.vue'
import Drawer from '@/components/layout/drawer.vue'
import Notification from '@/components/layout/notifications.vue'

export default {
  name: 'App-Settings',

  components: { Toolbar, Drawer, Notification },

  computed: {
    ...mapGetters('auth', ['userGroups']),

    hasApp () { return this.$store.getters['apps/hasApp'] },
    isLoading () {
      return this.$store.state.apps.loading
    },

    isAuthenticated () {
      return this.authState.isAuthenticated
    },
    hasGroups () {
      return !!this.userGroups.length
    }
  },

  watch: {
    async $route () {
      if (this.isAuthenticated && !this.hasGroups) await this.$store.dispatch('auth/getUserGroups')
    },
    async isAuthenticated (newVal) {
      if (newVal) {
        const user = await this.$auth.getUser()
        this.$store.commit('auth/setUser', user)

        if (!this.hasGroups) await this.$store.dispatch('auth/getUserGroups')
      }
    }
  }
}
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
</style>
