<template lang="pug">
  v-card-title
    span {{ title }}

    v-spacer

    template(v-if='showPlatform')
      v-btn-toggle(
        v-model='platform',
        mandatory
      )
        template(
          v-for='plat in platforms'
        )
          v-btn(
            icon,
            :value='plat',
            :disabled='loading || !hasPlatform(plat)'
          )
            template(v-if='getIcon(plat)')
              v-icon(
                large,
                :color='getColor(plat)'
              ) {{ getIcon(plat) }}
            template(v-else)
              v-img(:src='getLogo(plat)', contain, max-width='35px', max-height='40px')
</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'

import hasPlatform from '@/mixins/hasPlatform.js'
import appDecorators from '@/mixins/appDecorators.js'

export default {
  name: 'Title',

  mixins: [appDecorators, hasPlatform],

  props: {
    title: {
      type: String,
      required: true
    },
    showPlatform: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters('apps', ['app', 'platforms']),
    ...mapState('acquisition', {
      storeLoading: 'loading',
      storePlatform: 'platform'
    }),

    platform: {
      get () { return this.storePlatform },
      set (value) { this.setPlatform(value) }
    },
    loading: {
      get () { return this.storeLoading },
      set (value) { this.setLoading(value) }
    }
  },

  methods: {
    ...mapMutations('acquisition', [
      'setLoading',
      'setPlatform'
    ])
  }
}
</script>
