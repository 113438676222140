import axios from 'axios'

import { notify } from '@/store/utils'

export default {
  async createOrder ({ commit, rootGetters }, payload) {
    try {
      const mediation = rootGetters['monetization/currentMediation']
      const currentApp = rootGetters['monetization/currentApp']

      const { data } = await axios.post(`/waterfall/apps/${payload.appId}/orders`,
        { orders: payload.orders, version: currentApp.version })

      data.orders.forEach(order =>
        commit('addOrder', { mediation, appId: payload.appId, order })
      )
      commit('monetization/setAppVersion', data.version, { root: true })

      notify(commit, 'New order created', false)
    } catch (e) {
      notify(commit, 'Could not create order: ' + e, true)
    }
  }
}
