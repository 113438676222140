import state from './state'
import actions from './actions'
import mutations from './mutations'
import getters from './getters'
// import * as modules from './modules'

/** @type {import('vuex').Module} */
export default {
  namespaced: true,
  // modules,
  state,
  actions,
  mutations,
  getters
}
