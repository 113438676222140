export default {
  setUser (state, user) {
    state.user = user
  },
  setGroupMembers (state, { groupName, data }) {
    state.groups.list[groupName] = data
  },
  setUserGroups (state, data) {
    state.groups.user = data
  }
}
