<template lang="pug">
  v-card(shaped, outlined)
    v-card-title
      v-spacer

      template(v-if='getIcon(platform)')
        v-icon(
          large,
          :color='getColor(platform)'
        ) {{ getIcon(platform) }}
      template(v-else)
        v-img(:src='getLogo(platform)', contain, max-width='100px', max-height='40px')

      v-spacer

      template(v-if='isIos')
        v-icon(color='cyan', large) {{ icons.mdiAppleIos }}

      template(v-else)
        v-icon(color='green', large) {{ icons.mdiAndroidHead }}

      v-spacer

      template(v-if='isWorldwide')
        v-tooltip(top)
          template(v-slot:activator='{ on }')
            v-btn(
              v-on ='on',
              @click='refresh',
              :loading='loading',
              icon,
              large
            )
              v-icon(large) {{ icons.mdiRefresh }}

          span Refresh App data

      v-spacer

    v-divider

    template(v-if='!app.exists')
      v-card-text
        create-app(:platform='platform', :text='true')

    template(v-else)
      v-card-text
        v-text-field(
          label='Bundle ID',
          readonly,
          outlined,
          v-model='app.bundleId'
        )
          template(v-slot:append)
            v-icon(@click='copyText(app.bundleId)') {{ icons.mdiContentCopy }}

        v-text-field(
          v-if="isIosChina"
          label='Store ID',
          ref="storeId",
          readonly,
          outlined,
          :readonly='isStoreIdReadonly'
          v-model='app.storeId'
          @keydown.enter='saveStoreId'
          @blur='saveStoreId'
          persistent-hint,
          hint='If you modify "Store ID", Publish Status will be set to "Not published\"'
        )
          template(v-slot:append, v-if="isIosChina")
            v-icon(v-if='isPM || isAdmin' @click='toggleStoreIdReadonly') {{ isStoreIdReadonly ? icons.mdiPencilOff : icons.mdiPencil }}
            v-icon(@click='copyText(app.storeId)') {{ icons.mdiContentCopy }}

        v-text-field(
          v-if="isIos && !isIosChina"
          label='Store ID',
          readonly,
          outlined,
          v-model='app.storeId'
        )
          template(v-slot:append)
            v-icon(@click='copyText(app.storeId)') {{ icons.mdiContentCopy }}

        v-text-field(
          label='Publidash Game ID',
          outlined,
          type='number',
          v-model='publidashId'
        )

        v-text-field(
          label='Name',
          outlined,
          v-model='name'
          :readonly='isNameReadonly'
          @keydown.enter='saveAppName'
          @blur='saveAppName'
        )
          template(v-slot:append)
            v-icon(v-if='isPM || isAdmin' @click='toggleNameReadonly') {{ isNameReadonly ? icons.mdiPencilOff : icons.mdiPencil }}

        v-select(
          label='Release Status',
          outlined,
          :append-icon="''",
          :items='releaseStatuses',
          v-model='releaseStatus'
        )

        v-select(
          label='Publish Status',
          outlined,
          ref="publishStatus"
          :append-icon="''",
          :items='publishStatuses',
          item-text='text',
          item-value='value',
          v-model='published',
          @input='setPublishStatus'
          return-object
        )

        v-text-field(
          label='Store URL',
          readonly,
          outlined,
          v-model='storeUrls[platform]'
        )
          template(v-slot:append)
            v-icon(@click="copyText(storeUrls[platform]) || 'N/A'") {{ icons.mdiContentCopy }}
            v-icon.ml-1(@click="_open(storeUrls[platform]) || 'N/A'") {{ icons.mdiOpenInNew }}

        v-text-field(
          label='Icon URL',
          readonly,
          outlined,
          v-model='app.iconUrl'
        )
          template(v-slot:append)
            v-icon(@click='copyText(app.iconUrl)') {{ icons.mdiContentCopy }}

        v-expansion-panels(popout)
          v-expansion-panel
            v-expansion-panel-header Show more
            v-expansion-panel-content
              v-text-field(
                label='ID',
                readonly,
                outlined,
                v-model='app.id'
              )
                template(v-slot:append)
                  v-icon(@click='copyText(app.id)') {{ icons.mdiContentCopy }}

</template>

<script>
import { mapGetters, mapActions, mapState, mapMutations } from 'vuex'

import { mdiContentCopy, mdiChevronDown, mdiOpenInNew, mdiAppleIos, mdiAndroidHead, mdiRefresh, mdiPencil, mdiPencilOff } from '@mdi/js'
import { debounce } from 'lodash'

import copyText from '@/mixins/copyText'
import storeUrl from '@/mixins/storeUrl'
import appDecorators from '@/mixins/appDecorators'
import auth from '@/mixins/auth/groups'

import CreateApp from '@/components/general/createApp.vue'

const PUBLISH_STATUS_PUBLISHED = {
  text: 'Published',
  value: 'published'
}

const PUBLISH_STATUS_UNPUBLISHED = {
  text: 'Not published',
  value: 'not-published'
}

export default {
  name: 'PlatformCard',

  mixins: [copyText, storeUrl, appDecorators, auth],

  components: { CreateApp },

  props: {
    app: Object,
    platform: {
      type: String
    }
  },

  data: () => ({
    icons: { mdiContentCopy, mdiChevronDown, mdiOpenInNew, mdiAppleIos, mdiAndroidHead, mdiRefresh, mdiPencil, mdiPencilOff },
    releaseStatuses: [{
      text: 'Released',
      value: 'released'
    }, {
      text: 'Prototype',
      value: 'prototype'
    }],
    publishStatuses: [PUBLISH_STATUS_PUBLISHED, PUBLISH_STATUS_UNPUBLISHED],
    isNameReadonly: true,
    isStoreIdReadonly: true
  }),

  computed: {
    ...mapState('apps', [
      'loading'
    ]),

    ...mapGetters('apps', [
      'androidApp',
      'iosApp'
    ]),

    isIos () {
      return this.platform.split('_')[0] === 'ios'
    },
    isWorldwide () {
      return this.platform.split('_').length === 1
    },
    isIosChina () {
      return this.platform === 'ios_china'
    },

    publidashId: {
      get () {
        return this.app.publidashId
      },
      set: debounce(async function (val) {
        await this.savePublidashId({ appId: this.app.id, publidashId: val })
      }, 300)
    },
    published: {
      get () {
        const p = this.publishStatuses.find(status => status.value === this.app.publishStatus)

        return p
      },
      set () {
        /**
         * This methods needs to exist to mark the value of published when storeId is being updated
         * but there is no action
         */
      }
    },
    storeId: {
      get () {
        return this.app.storeId
      },
      set (value) {
        this.setAppProperty({
          platform: this.platform,
          property: 'storeId',
          value
        })
      }
    },
    name: {
      get () {
        return this.app.name
      },
      set (val) {
        this.setAppProperty({
          platform: this.platform,
          property: 'name',
          value: val
        })
      }
    },
    releaseStatus: {
      get () {
        return this.app.releaseStatus
      },
      set: debounce(async function (val) {
        await this.saveReleaseStatus({ appId: this.app.id, releaseStatus: val })

        this.$store.commit('adjust/setLoaded', { platform: this.platform, value: false })
        this.$store.commit('adjust/setApp', { platform: this.platform, app: undefined })
        this.$store.commit('adjust/setHas', { platform: this.platform, value: false })
        this.$store.commit('apps/setAppProperty', {
          platform: this.platform,
          property: 'releaseStatus',
          value: val
        })
      }, 300)
    },

    storeUrls () {
      return {
        android: this.androidApp ? this._getStoreUrl('android', this.androidApp.bundleId) : 'N/A',
        ios: this.iosApp ? this._getStoreUrl('ios', this.iosApp.storeId) : 'N/A',
        ios_china: this.app ? this._getStoreUrl('ios_china', this.app.storeId) : 'N/A'
      }
    }
  },

  methods: {
    ...mapActions('apps', [
      'savePublidashId',
      'savePublished',
      'saveName',
      'saveStoreIdPersistent',
      'saveReleaseStatus',
      'refreshData'
    ]),
    ...mapMutations('apps', [
      'setAppProperty'
    ]),

    async refresh () {
      const { id, unifiedAppId } = this.app

      await this.refreshData({
        appId: id,
        unifiedAppId
      })
    },

    async saveAppName () {
      if (!this.isNameReadonly) {
        await this.saveName({ appId: this.app.id, name: this.name })
        this.isNameReadonly = !this.isNameReadonly
      }
    },

    async setPublishStatus (status) {
      const published = status.value === 'published'

      await this.savePublished({ appId: this.app.id, published })

      this.published = published ? 'not-published' : 'published'
    },

    async toggleNameReadonly () {
      if (!this.isNameReadonly) await this.saveAppName()
      else this.isNameReadonly = !this.isNameReadonly
    },

    async saveStoreId () {
      const oldValue = this.$refs.storeId.value
      const newValue = this.app.storeId

      this.$refs.publishStatus.setValue(PUBLISH_STATUS_UNPUBLISHED.value)

      if (!this.isStoreIdReadonly && oldValue !== newValue) {
        try {
          await this.saveStoreIdPersistent({ appId: this.app.id, storeId: newValue })
          this.isStoreIdReadonly = true
        } catch (e) {
          // If persistent store fails, restore the current value
          this.storeId = oldValue
        }
      }
    },

    async toggleStoreIdReadonly () {
      if (!this.isStoreIdReadonly) {
        await this.saveStoreId()
      } else {
        this.isStoreIdReadonly = !this.isStoreIdReadonly
      }
    }
  }
}
</script>
