import { adUnits as constAdUnits } from '@/constants/constants.js'
import { notify } from '@/store/utils'

export default {
  addAdUnits (state, { mediation, appId, adUnits, commit }) {
    let index = 0
    const supportedAdUnits = []
    const unsupportedAdUnits = []
    adUnits.forEach((adUnit) => {
      if (typeof adUnit.format !== 'undefined' && typeof adUnit.mediation !== 'undefined') {
        supportedAdUnits.push(adUnit)
      } else {
        unsupportedAdUnits.push(adUnit)
      }
    })
    state.adUnits[mediation][appId] = supportedAdUnits.sort((a, b) => a.format < b.format ? -1 : 1).filter((adUnit) => adUnit.mediation === mediation)
    constAdUnits.forEach(constAdUnit => {
      if (!supportedAdUnits.find(adUnit =>
        constAdUnit.format.toLowerCase() === adUnit.format.toLowerCase() &&
      constAdUnit.mediation.toLowerCase() === adUnit.mediation.toLowerCase())
      ) {
        state.adUnits[constAdUnit.mediation][appId] = [
          ...(state.adUnits[constAdUnit.mediation][appId] || []),
          {
            format: constAdUnit.format,
            name: constAdUnit.format,
            mediation: constAdUnit.mediation,
            id: constAdUnit.format
          }
        ]
      }
    })
    state.adUnits[mediation][appId].forEach(adUnit => {
      adUnit.index = index++
    })
    if (unsupportedAdUnits.length > 0) {
      notify(commit, `Warning: you have unsupported ad units in your waterfall: ${unsupportedAdUnits.map((adUnit) => `${adUnit.name}`).join(', ')}`, true)
    }
  },
  updateAdUnit (state, { mediation, appId, adUnit }) {
    state.adUnits[adUnit.mediation][appId].map(_adUnit => {
      if (_adUnit.format.toLowerCase() === adUnit.format.toLowerCase()) {
        _adUnit.name = adUnit.name
        _adUnit.id = adUnit.id
        _adUnit.format = adUnit.format
      }
      return _adUnit
    })
  }
}
