export default {
  currentGame (state, getters, rootState, rootGetters) {
    const currentApp = rootGetters['apps/app']
    return currentApp && Array.isArray(currentApp.apps) && currentApp.apps.find(({ platform }) => platform === state.platform)
  },
  currentMediation (state) {
    return state.mediation
  },
  currentPlatform (state) {
    return state.platform
  },
  currentMediationAccounts (state) {
    return state.mediationAccounts[state.mediation]
  },
  currentApp (state) {
    return state.currentApp[state.mediation][state.platform]
  },
  currentGameHasWaterfall (state) {
    return state.hasWaterfall[state.mediation][state.platform]
  },
  currentAppHistory (state) {
    return state.history.filter(entry => entry.appKey === state.currentApp[state.mediation][state.platform].id)
  },
  currentAppFoundNetworkAppIds (state) {
    return state.foundNetworkAppIds[state.mediation][state.platform]
  }
}
