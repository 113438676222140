<template lang="pug">
  v-container(fluid)
    v-row(align='end' justify='end' flex-column)
      v-col(cols='2')
        v-text-field(
          v-model='search'
          label='Search'
          single-line
          hide-details
        )
    v-data-table(
      :headers='headers',
      :items='lineItems'
      :search='search'
      item-key='id'
      show-expand
      disable-pagination
      hide-default-footer
    )
      template(v-slot:item.countries='{ item }')
          div {{ item.countries.length === 0 ? 'ALL' : item.countries.join(' - ') }}
      template(v-slot:item.valid='{ item }')
        v-simple-checkbox(v-model='item.valid' disabled)

      template(v-slot:expanded-item='{ headers, item }')
        td(:colspan='headers.length')
          override-fields-form(
            :lineItemOverrideFields='item.overrideFields'
            :network='network'
            :mediation='mediation'
            v-on:validate='(data) => { onSubmit(item, data) }'
          )

</template>

<script>
import OverrideFieldsForm from '@/components/monetization/form/overrideFieldsForm'

export default {
  name: 'LineItemList',

  components: {
    OverrideFieldsForm
  },

  props: {
    lineItems: Array,
    network: String,
    mediation: String,
    onSubmit: Function
  },

  computed: {
    headers () {
      return [
        { text: 'Bid ($)', value: 'bid' },
        { text: 'Format', value: 'format' },
        { text: 'GeoTargeting', value: 'includeGeoTargeting' },
        { text: 'Countries', value: 'countries' },
        { text: 'Valid ?', value: 'valid' },
        { text: '', value: 'data-table-expand' }
      ]
    }
  },

  data: () => ({
    search: '',
    showAdvanced: false,
    expanded: []
  })
}
</script>
