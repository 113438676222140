<template lang="pug">
  v-card.mb-4(outlined)
    v-card-title
      span Primary texts
      v-spacer
      v-btn(
        icon,
        @click='hide = !hide'
      )
        v-icon {{ hide ? icons.mdiWindowMaximize : icons.mdiWindowMinimize }}

    v-divider

    v-card-text
      v-expand-transition
        v-container(fluid, v-show='!hide')
          template(v-for='index in nbTexts')
            v-row.my-3(align='center')
              v-textarea.px-3(
                :label='`Text #${index}`'
                v-model='texts[index - 1]',
                outlined,
                clearable,
                hide-details
              )

              v-btn(
                icon,
                large,
                @click='remove(index)'
              )
                v-icon(color='red') {{ icons.mdiDelete }}

          template(v-if='nbTexts < maxTexts')
            v-container(fluid)
              v-row(justify='end')
                v-btn(
                  outlined,
                  large,
                  @click='nbTexts += 1'
                ) Add text

</template>

<script>
import { mdiWindowMinimize, mdiWindowMaximize, mdiDelete } from '@mdi/js'

export default {
  name: 'Texts',

  data: () => ({
    icons: { mdiWindowMinimize, mdiWindowMaximize, mdiDelete },

    hide: false,
    nbTexts: 1,
    maxTexts: 5,
    texts: []
  }),

  methods: {
    remove (index) {
      if (this.nbTexts === 1) {
        this.texts[0] = null
        return
      }

      this.texts.splice(index, 1)
      this.nbTexts -= 1
    }
  },

  watch: {
    texts: {
      deep: true,
      handler () {
        this.$emit('update', this.texts)
      }
    }
  }
}
</script>
