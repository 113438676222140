const REDIRECT_URI = `${window.location.origin}/implicit/callback`

export default {
  oidc: {
    clientId: process.env.VUE_APP_CLIENT_ID,
    issuer: 'https://voodoo.okta.com/oauth2/default',
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    testing: {
      disableHttpsCheck: process.env.OKTA_TESTING_DISABLEHTTPSCHECK
    }
  }
}
