<template lang="pug">
  app-wrapper
    v-container(fluid)
      template(v-if='canAccess')
        v-card(outlined)
          v-card-title Adjust

          v-card-subtitle You should be able to configure everything you need here.

          v-divider

          v-card-text
            v-row(justify='space-around')
              template(v-for='platform in platforms')
                v-col(cols='12', sm='8', md='6', lg='4')
                  adjust-platform-card(
                    :ref='`${platform}Card`',
                    :platform='platform'
                  )
      no-access(v-else)
</template>

<script>
import { mapGetters } from 'vuex'

import AppWrapper from '@/components/layout/appWrapper.vue'
import AdjustPlatformCard from '@/components/attribution/adjust/adjustPlatformCard.vue'
import checkIfApp from '@/mixins/checkIfApp'

export default {
  name: 'Adjust',

  mixins: [checkIfApp],

  components: { AdjustPlatformCard, AppWrapper },

  data: () => ({
    channelsConfig: {},
    preventSave: false,
    restrict: {
      groups: ['ADMIN', 'UA_GUY', 'MONET_GUY', 'PM_GUY']
    }
  }),

  computed: {
    ...mapGetters('apps', ['platforms', 'app'])
  }
}
</script>
