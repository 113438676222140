<template lang="pug">
  v-card.app-container(@click='select')
    v-img.white--text.align-end.justify-center(
      :src='iconUrl',
      gradient='to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)',
      :height="small ? '120px' : '200px'"
    )
      v-card-title {{ app.name }}

    v-card-actions
      v-spacer
      v-icon(
        :color="hasPlatform('android') ? 'green' : 'grey'",
        large
      ) {{ icons.android }}

      v-divider.mx-2(vertical)

      v-icon(
        :color="hasPlatform('ios') ? 'cyan' : 'grey'",
        large
      ) {{ icons.ios }}
</template>

<script>
import { mapMutations } from 'vuex'

import { mdiAndroidHead, mdiAppleIos } from '@mdi/js'

import hasPlatform from '@/mixins/hasPlatform'
import localStorage from '@/mixins/localStorage'

import EmptyPlaceholder from '@/assets/empty-placeholder.png'

export default {
  name: 'AppCard',

  mixins: [hasPlatform, localStorage],

  props: {
    app: Object,
    small: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    icons: {
      android: mdiAndroidHead,
      ios: mdiAppleIos
    }
  }),

  computed: {
    iconUrl () {
      return this.app.apps && this.app.apps.length
        ? this.app.apps[0].iconUrl || EmptyPlaceholder
        : EmptyPlaceholder
    }
  },

  methods: {
    ...mapMutations('apps', {
      setApp: 'setCurrentApp'
    }),

    select () {
      this.setApp(this.app)
      this.$router.push({
        path: '/app/general/information',
        query: {
          appId: this.app.id
        }
      })

      // Adding to local storage for history
      this.saveRecentApp(this.app.id)
    },

    hasPlatform (refPlatform) {
      return this.app.apps.find(({ platform }) => platform === refPlatform)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  cursor: pointer;
}
</style>
