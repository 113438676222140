<template lang="pug">
  v-card-text
    template(v-if='token.valid')
      v-container(fluid)
        template(v-if='!canInput')
          v-row(justify='space-around')
            v-btn(
              color='primary',
              @click='getAccount',
              :loading='loading'
            ) Get Account
            v-btn(
              color='secondary',
              @click='canInput = true'
            ) Enter Existing Account

        template(v-else)
          v-form(v-model='valid')
            v-row
              //- Account ID input
              v-col(cols='12')
                v-text-field(
                  label='Account ID',
                  v-model='accountId',
                  :rules='[rules.accountId]',
                  outlined,
                  clearable,
                  persistent-hint,
                  hint='ID of the Tiktok ad account that should be used to launch ad campaigns'
                )

              //- Save button
              v-col.d-flex.justify-end(cols='12')
                v-btn(
                  @click='save',
                  :loading='loading',
                  :disabled='!valid',
                  color='primary'
                )
                  .pr-2 Save
                  v-icon {{ icons.mdiContentSave }}

        v-row.mt-4
          v-col.text-right(cols='12')
            v-btn(
              :href='links.account',
              :disabled='loading',
              target='_blank',
              color='info',
              outlined
            )
              .pr-2 Create a tiktok account
              v-icon {{ icons.mdiOpenInNew }}

          v-col.text-right(cols='12')
            v-btn(
              :href='tokenHref',
              :disabled='loading',
              target='_blank',
              color='info',
              outlined
            )
              .pr-2 Refresh Tiktok Authorization
              v-icon {{ icons.mdiOpenInNew }}

    template(v-else)
      v-alert(
        type='error',
        outlined
      ) The token seems to have expired. Please refresh it #[a(:href='tokenHref', target='_blank') here].
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mdiContentSave, mdiOpenInNew } from '@mdi/js'

import Configuration from '@/mixins/acquisition/configuration.js'

export default {
  name: 'Tiktok',

  mixins: [Configuration],

  props: {
    network: {
      type: Object,
      required: true
    }
  },

  mounted () {
    this.$on('refreshed', () => {
      const configuration = this.networks.configuration[this.platform][this.network.value]

      this.token.valid = !!configuration.tokenIsValid
      this.accountId = configuration.accountId

      if (this.accountId) this.canInput = true
    })
  },

  data: () => ({
    icons: { mdiContentSave, mdiOpenInNew },

    rules: {
      accountId: (value) => (!!value && !!value.length) || 'Invalid Account ID'
    },

    canInput: false,
    valid: false,

    accountId: null,
    token: {
      valid: false
    },

    links: {
      account: 'https://ads.tiktok.com/i18n/bpm/register?customer_id=9ACq8EkZl6Q0kROAd%2FTcTGafV%2FjcG%2FID2AgSFwMkc6M%3D&account_type=mOHyos5ZGuX%2FyC8GBuWRmWafV%2FjcG%2FID2AgSFwMkc6M%3D'
    }
  }),

  computed: {
    ...mapState('acquisition', ['networks', 'platform']),
    ...mapGetters('apps', ['androidApp', 'iosApp']),

    currentApp () {
      return this.platform === 'ios'
        ? this.iosApp
        : this.androidApp
    },

    tokenHref () {
      return `${process.env.VUE_APP_TIKTOK_LINK_AUTHORIZE}&state=${this.currentApp.id}`
    },
    configuration () {
      return {
        accountId: this.accountId,
        isSetup: !!this.accountId
      }
    }
  },

  methods: {
    async getAccount () {
      this.loading = true

      try {
        const { data } = await this.$axios.post('/acquisition/action', {
          action: 'tiktok-account-retrieve',
          appId: this.currentAppId
        })

        this.accountId = data.accountId
        this.canInput = true
      } catch (e) {
        this.notify('Account creation failed, please try again or call an administrator', true)
      }

      this.loading = false
    }
  },

  watch: {
    valid () {
      this.$emit('valid', { value: this.valid, network: this.network.value })
    }
  }
}
</script>
