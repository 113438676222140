<template lang="pug">
  v-container(fluid)
    v-row.title(justify='center', align='center')
      v-col LineItem creation

    v-divider

    LineItemReview(
      @update='updateLineItem'
    )

    v-divider

    v-container(fluid)
      v-row.ma-1(justify='space-between')
        v-btn(
          color='primary',
          @click='cancel',
        ) Cancel
        v-col(cols='4')
          v-row(v-if='!isWaterfallValid', justify='space-around')
            v-icon {{ icons.alert }}
            span Some lineItems may have invalid values
          v-btn(
            color='primary',
            @click='create',
          ) Create
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { mdiAlert } from '@mdi/js'

import LineItemReview from '@/components/monetization/waterfall/lineItemReview.vue'

export default {
  name: 'LineItemCreation',

  components: {
    LineItemReview
  },

  data: () => ({
    allLineItems: [],

    newAdUnitList: [],
    newOrderList: [],

    icons: {
      alert: mdiAlert
    }
  }),

  computed: {
    ...mapGetters('monetization', ['currentGame']),
    ...mapGetters('monetization/adUnits', ['getAdUnitsByAppId']),
    ...mapGetters('monetization/orders', ['getOrdersByAppId']),
    ...mapGetters('monetization/overrideFields', ['overrideFields']),

    ...mapState('monetization/lineItems', ['isWaterfallValid']),

    adUnits () {
      return this.getAdUnitsByAppId(this.currentGame.id)
    },

    orders () {
      return this.getOrdersByAppId(this.currentGame.id)
    },

    affectedNetworks () {
      return Array.from(new Set(this.allLineItems.map(li => li.network)))
    }
  },

  methods: {
    ...mapActions('monetization/lineItems', [
      'pushLineItems',
      'validateLineItems'
    ]),
    ...mapActions('monetization/adUnits', ['createAdUnit']),
    ...mapActions('monetization/orders', ['createOrder']),
    ...mapActions('monetization', ['displayActionSummary']),

    updateLineItemsData () {
      this.allLineItems.forEach(lineItem => {
        lineItem.network = lineItem.order.network
        lineItem.adUnitIds = [lineItem.adUnit.id]
        lineItem.orderId = lineItem.order.id

        if (lineItem.adUnitIds === '') {
          const newAdUnit = { format: lineItem.adUnit.format }
          if (
            !this.newAdUnitList ||
            !this.newAdUnitList.find(unit => unit.format === newAdUnit.format)
          ) {
            this.newAdUnitList.push(newAdUnit)
          }
        }

        if (lineItem.orderId === '') {
          const newOrder = { network: lineItem.order.network }
          if (
            !this.newOrderList ||
            !this.newOrderList.find(order => order.network === newOrder.network)
          ) {
            this.newOrderList.push(newOrder)
          }
        }
      })
    },

    storeOrderAndAdUnitIds () {
      this.allLineItems
        .filter(lineItem => lineItem.adUnit.id && lineItem.order.id)
        .forEach(lineItem => {
          lineItem.network = lineItem.order.network
          lineItem.format = lineItem.adUnit.format
          lineItem.adUnitIds = [lineItem.adUnit.id]
          lineItem.orderId = lineItem.order.id
        })
    },

    async updateLineItem (allLineItems) {
      this.allLineItems = [...allLineItems]
      if (
        this.allLineItems.every(
          lineItem => lineItem.adUnit.id && lineItem.order.id
        )
      ) {
        this.storeOrderAndAdUnitIds()
        await this.validateLineItems({ lineItems: allLineItems })
      }
    },

    async createOrdersAndAdUnits () {
      let adUnitsPromise = async () => {}
      let ordersPromise = async () => {}
      if (this.newAdUnitList.length !== 0) {
        adUnitsPromise = this.createAdUnit({
          appId: this.currentGame.id,
          adUnits: this.newAdUnitList
        })
      }
      if (this.newOrderList.length !== 0) {
        ordersPromise = this.createOrder({
          appId: this.currentGame.id,
          orders: this.newOrderList
        })
      }

      return Promise.all([adUnitsPromise, ordersPromise])
    },

    updateOrdersAndAdUnits () {
      this.allLineItems.forEach(lineItem => {
        if (lineItem.orderId === '') {
          const order = this.orders.find(
            result => result.network === lineItem.network
          )
          lineItem.orderId = order.id
        }
        if (lineItem.adUnitIds === '') {
          const adUnit = this.adUnits.find(
            result => result.format === lineItem.format
          )
          lineItem.adUnitIds = adUnit.id
        }
      })
    },

    async create () {
      this.updateLineItemsData()

      this.displayActionSummary({
        isDisplayed: true,
        networks: this.affectedNetworks,
        action: async () => {
          await this.createOrdersAndAdUnits().then(async () => {
            this.updateOrdersAndAdUnits()

            await this.pushLineItems({
              lineItems: this.allLineItems,
              appKey: this.currentGame.id
            })
            this.$emit('create')
          })
        }
      })
    },

    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
