<template lang="pug">
  v-navigation-drawer(
    fixed,
    permanent,
    app,
    clipped,
    :mini-variant.sync='mini',
    mini-variant-width='100',
    v-model='show'
  )
    v-list(dense, nav)
      template(v-for='item in itemGroup')
        v-subheader(v-if='item.header') {{ item.header }}

        v-divider(v-else-if='item.divider')

        v-list-group(
          v-else-if='item.items',
          :prepend-icon='item.icon'
        )
          template(v-slot:activator)
            v-list-item-content
              v-list-item-title {{ item.title }}

          template(v-for='subItem in item.items')
            v-list-item(
              :key='subItem.title',
              :to='subItem.href',
              :disabled='item.disabled',
              link,
              dense
            )
              v-list-item-action
                v-icon(v-if='!subItem.custom', :color="subItem.color || 'default'") {{ subItem.icon }}
                img(v-else, :src='subItem.icon', width='24', height='24')
              v-list-item-content
                v-list-item-title
                  span {{ subItem.title }}

          v-divider

        v-list-item(
          v-else,
          :to='item.href',
          :disabled='item.disabled',
          link
        )
          v-list-item-action(v-if='!item.avatar')
            v-icon(v-if='!item.custom', :color="item.color || 'default'") {{ item.icon }}
            img(v-else, :src='item.icon', width='24', height='24')

          v-list-item-action(v-else)
            v-badge(
              :color='item.china ? "red" : "blue"'
              overlap
              :content='item.china ? "China" : "WW"'
              bordered
              bottom
            )
              v-avatar(size='40')
                v-img(:src='item.icon')

          v-list-item-content
            v-list-item-title(:class="{ title: item.avatar }")
              div(:class="{ 'game-name': item.avatar }") {{ item.title }}

      v-list-item(
        link,
        @click.stop='toggleMini'
      )
        v-list-item-action
          v-icon {{ mini ? icons.mdiChevronRight : icons.mdiChevronLeft }}

        v-list-item-title Reduce
</template>

<script>
import { mapGetters } from 'vuex'

import {
  mdiChevronLeft,
  mdiChevronRight,

  mdiGoogleDrive,
  mdiLink,
  mdiChartBellCurveCumulative,
  mdiCurrencyUsd,
  mdiHomeCurrencyUsd,
  mdiFileClockOutline,
  mdiHistory
} from '@mdi/js'

import adjustIcon from '@/assets/icons/logo-adjust.png'
import tenjinIcon from '@/assets/icons/logo-tenjin.png'
import vsauceIcon from '@/assets/icons/logo-vsauce.png'

export default {
  name: 'Left-Drawer',

  data: () => ({
    icons: { mdiChevronLeft, mdiChevronRight }
  }),

  computed: {
    ...mapGetters('apps', [
      'app'
    ]),

    mini: {
      get () {
        return this.$store.state.drawer.mini
      },
      set () {}
    },
    show: {
      get () {
        return this.$store.state.drawer.show
      },
      set (val) {
        this.$store.commit('setLeftDrawer', val)
      }
    },

    itemGroup () {
      return [
        {
          avatar: true,
          title: this.app.marketingAttributes && this.app.marketingAttributes.voodooName,
          custom: true,
          icon: this.app.iconUrl,
          china: this.app.isChina,
          href: '/app/general/information'
        },
        { divider: true },
        { header: 'Attribution Partners' },
        {
          title: 'Adjust',
          custom: true,
          icon: adjustIcon,
          href: '/app/attribution/adjust'
        },
        {
          title: 'Tenjin',
          custom: true,
          icon: tenjinIcon,
          href: '/app/attribution/tenjin'
        },
        { divider: true },
        { header: 'Acquisition' },
        {
          title: 'Campaigns',
          icon: mdiHomeCurrencyUsd,
          href: '/app/acquisition/campaigns/creation'
        },
        {
          title: 'History',
          icon: mdiHistory,
          href: '/app/acquisition/campaigns/history'
        },
        { divider: true },
        { header: 'Monetization' },
        {
          title: 'Monetization',
          icon: mdiCurrencyUsd,
          href: '/app/monetization/waterfall'
        },
        {
          title: 'History',
          icon: mdiFileClockOutline,
          href: '/app/monetization/history'
        },
        { divider: true },
        { header: 'Config' },
        {
          title: 'VSauce Configuration',
          icon: vsauceIcon,
          custom: true,
          href: '/app/configuration/vsauce'
        },
        { divider: true },
        { header: 'Misc' },
        {
          title: 'Google Drive',
          icon: mdiGoogleDrive,
          href: '/misc/googleDrive'
        },
        { divider: true },
        { header: 'Developper' },
        {
          title: 'Game Dev Stats',
          icon: mdiChartBellCurveCumulative,
          href: '/app/developper/analytics'
        }, {
          title: 'Custom links',
          icon: mdiLink,
          href: '/app/developper/custom',
          disabled: true
        },
        { divider: true }
      ]
    }
  },

  methods: {
    toggleMini () {
      this.$store.commit('setMiniDrawer', !this.mini)
    },
    cleanApp () {
      this.$store.commit('apps/setCurrentApp', null)
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .game-name {
    line-height: 1.5;
  }
</style>
