<template lang="pug">
  .ml-4
    v-tooltip(top)
      template(v-slot:activator='{ on }')
        v-btn(icon)
          v-icon(v-on='on') {{ icons.mdiHelpCircleOutline }}

      template(v-if='budget.creatives.videos.length')
        div
          h4 {{ budget.creatives.videos.length }} videos selected.
          ul
            template(v-for='video in budget.creatives.videos')
              li {{ video.name }}

      template(v-else)
        h4 No video selected.

      template(v-if='budget.creatives.bodies.length')
        div
          h4 {{ budget.creatives.bodies.length }} primary texts entered.
          ul
            template(v-for='text in budget.creatives.bodies')
              li {{ text }}

      template(v-else)
        h4 No primary text entered.
</template>

<script>
import { mdiHelpCircleOutline } from '@mdi/js'

export default {
  name: 'BudgetInfoTooltip',

  props: {
    budget: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    icons: { mdiHelpCircleOutline }
  })
}
</script>
