<template lang="pug">
  v-container(fluid)
    div(
      v-if='!isAppAvailable',
    )
      h2 The app is currently being updated please come back later
    v-row(justify='space-between' :class='{blur: !isAppAvailable}')
      v-dialog(
        v-model='showDialog',
        transition='dialog-bottom-transition'
      )
        mediation-list(
          @select='linkMediationWaterfall'
        )
      v-col(cols='4')
        v-row(justify='start' align='center')
          v-tooltip(right)
            template(v-slot:activator='{ on }')
              div(v-on='on')
                v-btn(
                  icon,
                  large,
                  @click='reSync'
                ).mr-3
                  v-icon(color='warning') {{ icons.mdiDelete }}
            span Redo synchronization

          v-tooltip(right)
            template(v-slot:activator='{ on }')
              div(v-on='on')
                v-btn(
                  color='warning',
                  @click='showDialog= !showDialog'
                ) Sync {{currentMediation}}
            span This will erase your current waterfall and retrieve the Line Items that are on {{currentMediation}}

          v-tooltip(right)
            template(v-slot:activator="{ on, attr }")
              div.ml-3
                request-networks(
                  :v-if="currentMediation"
                  :title="`Update On ${currentMediation}`"
                  subtitle=`Request ${currentMediation} creation again`
                  description="Use it when some networks are stuck in CREATED_ON_NETWORK state"
                  @update-networks="updateOnMediation"
                  :useLocalWaterfall='true'
                )
            span Create all lineItems on {{currentMediation}}, without calling the networks

      v-col(cols='6')
        v-row(align='center', justify='end')
          request-networks(
            title="Update Networks"
            subtitle="Request networks again using the local waterfall"
            description="Use it when some networks are stuck in NETWORK_ERROR or CREATING_ON_NETWORK state"
            @update-networks="updateOnNetworks"
            :useLocalWaterfall='true'
          )

          div.ml-3
            request-networks(
              title="Update Setup"
              :overrideOrders="allNetworkAsOrder"
              subtitle="Update actual setup using the template"
              @update-networks="updateSetupOnNetworks"
            )

          div.ml-3(v-if='currentMediation === "max"')
            request-networks(
              title="Update Bidders"
              subtitle="Update used bidders"
              description="Disable or enable bidders per adunits"
              :overrideOrders="allNetworkAsOrder"
              :bidders='true'
              :useLocalWaterfall='true'
              @update-networks="updateBiddersOnNetworks"
            )

      waterfall-display(
        :gameId='currentGame.id',
        @line-items-create='onCreate',
        @line-items-update='onUpdate',
      )
</template>

<script>
import { mdiDelete } from '@mdi/js'
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import WaterfallDisplay from '@/components/monetization/waterfall/waterfallDisplay.vue'
import RequestNetworks from '@/components/monetization/waterfall/requestNetworks.vue'
import MediationList from '@/components/monetization/mediation/mediationList.vue'
import { networks } from '@/constants/constants'

export default {
  name: 'LineItemDisplay',

  components: {
    WaterfallDisplay,
    RequestNetworks,
    MediationList
  },

  data: () => ({
    icons: { mdiDelete },
    showDialog: false
  }),

  mounted () {
    setInterval(
      () =>
        !document.hidden && this.isAppAvailable && this.getAppAvailability(),
      10000
    )
  },

  computed: {
    ...mapState('monetization', ['isAppAvailable']),
    ...mapGetters('monetization', ['currentGame', 'currentMediation']),
    ...mapGetters('monetization/orders', ['getOrdersByAppId']),
    ...mapGetters('monetization/lineItems', ['getLineItemsByAppId']),

    getNonIntegratedNetworks () {
      const lineItems = this.getLineItemsByAppId(this.currentGame.id)
      return this.getOrdersByAppId(this.currentGame.id).filter(
        order => !lineItems.find(li => li.network === order.network)
      )
    },
    allNetworkAsOrder () {
      return networks.map((network) => (this.getOrdersByAppId(this.currentGame.id).find((order) => order.network === network) || {
        network,
        mediation: this.currentMediation,
        orderId: ''
      }))
    }
  },

  methods: {
    ...mapMutations('monetization', ['setLoading', 'setHasWaterfall']),

    ...mapActions('monetization/apps', ['getAppAvailability']),
    ...mapActions('monetization', [
      'pushWaterfall',
      'updateNetworks',
      'addNetworks',
      'updateSetup',
      'syncWaterfall',
      'displayActionSummary'
    ]),
    ...mapActions('monetization/bidders', ['upsertBidders', 'upsertBiddersOnNetworks']),
    ...mapActions('monetization/lineItems', [
      'pushLineItems',
      'deleteLineItems'
    ]),
    async updateOnMediation (networks) {
      await this.pushWaterfall({ appKey: this.currentGame.id, networks })
    },
    async updateOnNetworks (networks) {
      await this.updateNetworks({ appKey: this.currentGame.id, networks })
    },
    updateSetupOnNetworks (networks, adUnitIds, templateName, idfaTargeting) {
      this.displayActionSummary({
        isDisplayed: true,
        networks,
        action: async () => {
          await this.updateSetup({
            appKey: this.currentGame.id,
            networks,
            adUnitIds,
            templateName,
            idfaTargeting
          })
        }
      })
    },

    updateBiddersOnNetworks (networks, adUnitIds) {
      this.displayActionSummary({
        isDisplayed: true,
        networks: networks,
        action: async () => {
          await this.upsertBiddersOnNetworks({
            appKey: this.currentGame.id,
            networks,
            adUnitIds
          })
        }
      })
    },

    reSync () {
      this.setHasWaterfall(false)
    },
    async linkMediationWaterfall (app) {
      this.setLoading(true)

      await this.syncWaterfall({
        appKey: this.currentGame.id,
        mediationAppId: app.key
      })

      await this.checkWaterfall()
    },

    onCreate () {
      this.$emit('line-item-create')
    },
    async onUpdate (lineItems, deleteLineItemIds) {
      this.$emit('line-item-update')

      await this.pushLineItems({ lineItems, appKey: this.currentGame.id })
      if (deleteLineItemIds && deleteLineItemIds.length) {
        await this.deleteLineItems({
          lineItemIds: deleteLineItemIds,
          appKey: this.currentGame.id
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.blur {
  filter: blur(5px);
  pointer-events: none;
}
</style>
