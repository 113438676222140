<template lang="pug">
  v-container(fluid)
    v-card(outlined)
      v-card-title Developer Build Information

      v-card-subtitle Those are the data sent by the developer on their last build.

      v-divider

      v-card-text
        v-row(justify='space-around')
          template(v-for='platform in platforms')
            v-col(cols='12', sm='8', md='6', lg='5')
              v-card(shaped)
                v-card-title.text-center
                  v-spacer
                  v-icon(
                    large,
                    :color="platform === 'ios' ? 'cyan' : 'green'"
                  ) {{ platform === 'ios' ? icons.mdiAppleIos : icons.mdiAndroid }}
                  v-spacer

                v-divider

                template(v-if='loading')
                  v-card-text.text-center
                    v-progress-circular(indeterminate)

                template(v-else)
                  v-card-text.text-center
                    template(v-if='currentAnalytics[platform]')
                      template(v-for='(item, key) in currentAnalytics[platform]')
                        v-text-field(
                          :label='key',
                          outlined,
                          readonly,
                          v-model='currentAnalytics[platform][key]'
                        )
                          template(v-slot:append)
                            v-icon(@click='copyText(currentAnalytics[platform][key])') {{ icons.mdiContentCopy }}

                    template(v-else)
                      h3 No data.
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

import { mdiAndroid, mdiAppleIos, mdiContentCopy } from '@mdi/js'

import checkIfApp from '@/mixins/checkIfApp.js'
import copyText from '@/mixins/copyText.js'

export default {
  name: 'Analytics',

  mixins: [checkIfApp, copyText],

  created () {
    this.$on('app-config', async () => {
      await this.refresh()
    })
  },

  mounted () {
    this.$nextTick(async () => {
      if (!this.loaded) await this.refresh()
    })
  },

  data: () => ({
    platforms: ['ios', 'android'],
    icons: { mdiAndroid, mdiAppleIos, mdiContentCopy },

    loading: true,
    loaded: true
  }),

  computed: {
    ...mapState('vsauce', [
      'analytics'
    ]),
    ...mapGetters('apps', [
      'app',
      'androidApp',
      'iosApp'
    ]),

    currentAnalytics () {
      return this.app ? this.analytics[this.app.id] : null
    }
  },

  methods: {
    ...mapActions('vsauce', [
      'retrieveAnalytics'
    ]),

    async refresh () {
      this.loading = true

      try {
        await Promise.all([
          await this.retrieveAnalytics({
            id: this.app.id,
            bundleId: this.androidApp.bundleId,
            platform: 'android'
          }),

          await this.retrieveAnalytics({
            id: this.app.id,
            bundleId: this.iosApp.bundleId,
            platform: 'ios'
          })
        ])

        this.loaded = true
      } catch (e) {

      } finally {
        this.loading = false
      }
    }
  }
}
</script>
