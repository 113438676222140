import { maxadsAutoBidders } from '@/constants/constants'

export default {
  getBiddersByAppId: (state, getters, rootState, rootGetters) => (appId) => {
    const mediation = rootGetters['monetization/currentMediation']
    return state.bidders[mediation][appId]
  },
  getBiddersInfosByAppIdAndAdUnitIds: (state, getters, rootState, rootGetters) => (appId, adUnitIds) => {
    const mediation = rootGetters['monetization/currentMediation']
    const currentGame = rootGetters['monetization/currentGame']
    const adUnits = rootGetters['monetization/adUnits/getAdUnitsByAppId'](currentGame.id)
    return adUnitIds.map((adUnitId) => {
      const adUnit = adUnits.find((ad) => ad.id === adUnitId)
      const used = state.bidders[mediation][appId].filter(bidder => bidder.adUnitId === adUnit.id)
      const usedNetworks = used.map((bidder) => bidder.network)
      return [
        ...maxadsAutoBidders.reduce((acc, network) => {
          if (!usedNetworks.includes(network)) {
            acc.push({
              network,
              adUnitId: adUnit.id,
              format: adUnit.format,
              active: false,
              mediation
            })
          }
          return acc
        }, []),
        ...used
      ]
    }).flat()
  }
}
