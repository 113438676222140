import axios from 'axios'

import { notify } from '@/store/utils'

import { v4 } from 'uuid'

/**
 * GTM-300
 * Interceptor to add a correlation id to track end to end requests
 * naming: trace id following opentelemetry tracing api convention
 */
axios.interceptors.request.use(config => {
  config.headers['x-correlation-id'] = v4()

  return config
})

/** @type {import('vuex').Module} */
export default {
  namespaced: true,

  state: {
    loading: false,
    configs: {
      allFields: [],
      common: []
    },
    currentApp: null,
    analytics: {}
  },

  mutations: {
    setLoading (state, bool) {
      state.loading = bool
    },
    addConfig (state, { key, config }) {
      state.configs = {
        ...state.configs,
        [key]: config
      }
    },
    addAnalytics (state, { key, analytics }) {
      if (state.analytics[key]) Object.assign(state.analytics[key], analytics)
      else state.analytics[key] = analytics
    },
    setAllFields (state, fields) {
      state.configs.allFields = fields
    },
    setCurrentApp (state, app) {
      state.currentApp = app
    }
  },

  actions: {
    async retrieveCommonConfig ({ commit }) {
      commit('setLoading', true)

      try {
        const { data } = await axios.get('/vsauce/commonConfiguration')

        const allFields = data.reduce((acc, category) => ([
          ...acc,
          ...category.fields
        ]), [])

        commit('setAllFields', allFields)

        commit('addConfig', { key: 'common', config: data })
      } catch (e) {
        notify(commit, `Could not retrieve VSauce Common Configuration: ${e.message}`, true)
      }

      commit('setLoading', false)
    },
    async retrieveConfig ({ commit }, id) {
      commit('setLoading', true)

      try {
        const { data } = await axios.get('/vsauce/configuration', { params: { id } })

        commit('addConfig', { key: id, config: data })
      } catch (e) {
        notify(commit, `Could not retrieve this app's configuration: ${e.message}`, true)
      }

      commit('setLoading', false)
    },
    async retrieveAnalytics ({ commit }, { id, bundleId, platform }) {
      try {
        const { data } = await axios.get('/vsauce/analytics', { params: { bundleId, platform } })

        commit('addAnalytics', { key: id, analytics: { [platform]: data[platform] } })
      } catch (e) {
        notify(commit, `Could not retrieve this app's configuration: ${e.message}`, true)
      }
    },

    async saveCommonConfig ({ commit }, config) {
      try {
        const { data } = await axios.put('/vsauce/commonConfiguration', config)

        commit('addConfig', { key: 'common', config: data })
        notify(commit, 'Successfully updated VSauce Common Configuration')
      } catch (e) {
        notify(commit, `Could not update VSauce Common Configuration: ${e.message}`, true)
      }
    },
    async saveConfig ({ state, commit }, { config }) {
      try {
        commit('setLoading', true)

        const { id } = state.currentApp

        const { data } = await axios.put(`/vsauce/configuration/${id}`, config)

        commit('addConfig', { key: id, config: data })
        notify(commit, 'Successfully updated App Configuration')
      } catch (e) {
        notify(commit, `Could not update app's configuration: ${e.message}`, true)
      } finally {
        commit('setLoading', false)
      }
    }
  },

  getters: {
    configs (state) {
      return state.configs
    },
    getConfig (state) {
      return (id) => state.configs[id]
    },
    commonConfig (state) {
      return state.configs.common
    },
    isLoading (state) {
      return state.loading
    }
  }
}
