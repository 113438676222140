import axios from 'axios'

import { notify } from '@/store/utils'

export default {
  async getForm ({ commit }, { appId, formId }) {
    try {
      const { data: { form = null } } = await axios.get(
        `/public/waterfall/forms/${appId}/${formId}`
      )
      commit('setForm', form)
    } catch (e) {
      notify(commit, 'Could not retrieve form', true)
    }
  },

  async submitForm ({ commit }, { appId, formId, form }) {
    commit('setSubmitting', true)
    try {
      const { data } = await axios.put(
        `/public/waterfall/forms/${appId}/${formId}`,
        form
      )
      notify(commit, data.message, false)
    } catch (e) {
      notify(commit, 'Could not submit form', true)
    } finally {
      commit('setSubmitting', false)
    }
  }
}
