import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters('auth', ['userGroups']),

    currentApp () { return this.app },

    isAdmin () { return this.hasGroup('ADMIN') },
    isPM () { return this.hasGroup('PM_GUY') },

    canAccess () {
      // Kitchen doesn\t work for those apps so we need to restrict access
      const genreAllowed = ['HYPER_CASUAL', 'CASUAL']
      if (this.app && !genreAllowed.includes(this.currentApp.genre)) return false
      if (!this.restrict) return true
      else {
        // Only those groups can access the page
        if (this.restrict.groups) {
          return this.restrict.groups.some(this.hasGroup)
        }
      }
    }
  },

  methods: {
    hasGroup (group) {
      return this.userGroups.includes(group)
    }
  }
}
