import axios from 'axios'

import { notify } from '@/store/utils'

export default {
  async createAdUnit ({ rootGetters, commit }, payload) {
    try {
      commit('monetization/setLoading', true, { root: true })

      const mediation = rootGetters['monetization/currentMediation']
      const currentApp = rootGetters['monetization/currentApp']

      const { data } = await axios.post(`/waterfall/apps/${payload.appId}/adunits`,
        { adUnits: payload.adUnits, version: currentApp.version })

      data.adUnits.forEach(adUnit =>
        commit('updateAdUnit', { mediation, appId: payload.appId, adUnit: adUnit })
      )
      commit('monetization/setAppVersion', data.version, { root: true })

      notify(commit, 'New adUnit created', false)
    } catch (e) {
      notify(commit, `Could not create adUnit: ${e}`, true)
    } finally {
      commit('monetization/setLoading', false, { root: true })
    }
  }
}
