<template lang="pug">
  app-wrapper(
    @app-config='onAppConfig'
  )
    //- Shows only when config is loaded
    v-container(fluid, v-if='loaded')
      v-card(outlined)
        v-card-title Voodoo Sauce Configuration
        v-card-subtitle Here you can set values for the Voodoo Sauce Configuration.

        v-divider

        v-card-text
          v-overlay(:value='loading')
            .text-center
              .headline Saving configuration
              v-progress-circular(indeterminate)

          v-tabs(vertical)
            template(v-for='store in availableStores')
              v-tab(
                @click='onAppChange(store)'
              )
                span {{ getName(store) }}

                v-spacer

                template(v-if='getIcon(store)')
                  v-icon(
                    left
                    :color='getColor(store)'
                  ) {{ getIcon(store) }}
                template(v-else)
                  v-img(:src='getLogo(store)', contain, max-width='25px', max-height='25px')

            template(v-for='store in availableStores')
              v-tab-item
                v-card(flat)
                  v-card-text.scroll-section
                    information(
                      :store='store'
                    )

                    configuration(
                      :store='store'
                    )
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'

import AppWrapper from '@/components/layout/appWrapper.vue'

import Information from '@/components/configuration/information.vue'
import Configuration from '@/components/configuration/configuration.vue'

import appDecorators from '@/mixins/appDecorators'

export default {
  name: 'VSauceConfiguration',

  components: { AppWrapper, Configuration, Information },

  mixins: [appDecorators],

  data: () => ({
    loaded: false
  }),

  computed: {
    ...mapState('vsauce', ['loading']),
    ...mapGetters('apps', [
      'app'
    ]),

    availableStores () {
      if (this.app) return this.app.apps.map(({ platform }) => platform)

      return []
    }
  },

  methods: {
    ...mapMutations('vsauce', ['setCurrentApp']),

    onAppConfig () {
      this.setCurrentApp(this.app.apps[0])

      this.loaded = true
    },
    onAppChange (store) {
      const app = this.app.apps.find(({ platform }) => platform === store)

      this.setCurrentApp(app)
    }
  }
}
</script>

<style lang="scss">
  .scroll-section {
    overflow-y: scroll;
    height: 80vh;
  }
</style>
