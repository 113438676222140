<template lang="pug">
  v-card-text.text-center.subtitle-1
    .subtitle-4 The waterfall is on the way please wait...
    v-progress-circular(indeterminate)
</template>

<script>
export default {
  name: 'Loading'
}
</script>
