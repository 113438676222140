function multiStoreDefaults (filledWith = {}) {
  const defaults = {
    ios: filledWith,
    ios_china: filledWith,
    ios_line: filledWith,

    // Android Distributions
    android: filledWith,
    android_xiaomi: filledWith,
    android_huawei: filledWith,
    android_tencent: filledWith,
    android_oppo: filledWith,
    android_line: filledWith
  }
  return {
    max: { ...defaults }
  }
}

export default {
  isInit: {
    max: false
  },
  isLoading: false,
  mediationAccounts: {
    max: []
  },
  platform: 'ios',
  mediation: 'max',
  hasWaterfall: multiStoreDefaults(false),
  isAppAvailable: true,
  currentApp: multiStoreDefaults(),
  history: [],
  networkAppIds: multiStoreDefaults(),
  foundNetworkAppIds: {
    isLoading: false,
    ...multiStoreDefaults()
  },
  templateList: [],
  actionSummary: {
    isDisplayed: false,
    networks: undefined,
    action: () => {}
  }
}
