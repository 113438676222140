<template lang="pug">
  v-container(fluid)
    publicForm
</template>

<script>
import publicForm from '@/components/monetization/form/publicForm'

export default {
  name: 'PublicAppForm',

  components: {
    publicForm
  }
}
</script>

<style>
</style>
