import axios from 'axios'

import { notify } from '@/store/utils'

export default {
  async upsertBidders ({ commit, rootGetters }, payload) {
    try {
      commit('monetization/setLoading', true, { root: true })

      const mediation = rootGetters['monetization/currentMediation']
      const currentApp = rootGetters['monetization/currentApp']

      const { data } = await axios.post(`/waterfall/apps/${currentApp.id}/bidders`,
        { bidders: payload.bidders, version: currentApp.version, mediation })

      if (data.bidders) {
        data.bidders.forEach(bidder =>
          commit('updateBidder', { mediation, appId: payload.appId, bidder: bidder })
        )
      }
      commit('monetization/setAppVersion', data.version, { root: true })

      notify(commit, 'bidder udpated successfully', false)
    } catch (e) {
      notify(commit, `Could not create bidder: ${e}`, true)
    } finally {
      commit('monetization/setLoading', false, { root: true })
    }
  },
  async upsertBiddersOnNetworks ({ commit, rootGetters }, payload) {
    try {
      commit('monetization/setLoading', true, { root: true })

      const mediation = rootGetters['monetization/currentMediation']
      const currentApp = rootGetters['monetization/currentApp']
      const allBidders = rootGetters['monetization/bidders/getBiddersInfosByAppIdAndAdUnitIds'](payload.appKey, payload.adUnitIds)
        .reduce(
          (acc, bidder) => {
            if (!payload.networks.includes(bidder.network)) {
              return acc
            }
            return [...acc, { ...bidder, active: true }]
          },
          []
        )

      const { data } = await axios.post(`/waterfall/apps/${currentApp.id}/bidders`,
        { bidders: allBidders, version: currentApp.version, mediation })

      if (data.bidders) {
        data.bidders.forEach(bidder =>
          commit('updateBidder', { mediation, appId: payload.appKey, bidder: bidder })
        )
      }
      commit('monetization/setAppVersion', data.version, { root: true })

      notify(commit, 'bidder udpated successfully', false)
    } catch (e) {
      notify(commit, `Could not create bidder: ${e}`, true)
    } finally {
      commit('monetization/setLoading', false, { root: true })
    }
  }
}
