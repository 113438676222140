<template lang="pug">
  v-card-text
    v-container(fluid)
      template(v-if='error')
        v-alert(
          dense,
          outlined,
          type='error'
        )
          h4 The app and ad account could not be found in our database or on Facebook.
          h5 Please check if #[code voodoo.admin] (100024257944636) is added as admin in #[code Facebook dev > Roles].
          h5 Please check if the app is added in #[code Voodoo Business Settings] in #[code Account > Apps]. If not, ask the studio to do it.

      template(v-else)
        v-form(ref='facebookAcquisitionForm' v-model='valid')
          v-row
            v-col(cols='12', md='4')
              v-text-field(
                label='App ID',
                v-model='appId',
                outlined,
                disabled,
                persistent-hint,
                hint='App ID in the Facebook system'
              )

            v-col(cols='12', md='4')
              v-text-field(
                label='Ad Account ID',
                v-model='accountId',
                outlined,
                disabled,
                persistent-hint,
                hint='ID of the Facebook Ad account'
              )

            template(v-if='isIos')
              v-col(cols='12', md='4')
                v-text-field(
                  label='App Secret',
                  v-model='appSecret',
                  :rules='[rules.notEmpty]',
                  :disabled='loading',
                  outlined,
                  clearable,
                  persistent-hint,
                  hint='Click on the right side icon to get your app secret'
                )
                  template(v-slot:append-outer)
                    v-btn(
                      @click='openAppSecret',
                      :disabled='!appId',
                      icon
                    )
                      v-icon {{ icons.mdiOpenInNew }}

            template(v-if='isAndroid')
              v-col(cols='12', md='4')
                v-switch(
                  label='It is an old game',
                  @change='setIsOldGame',
                  v-model='isOldGame',
                  persistent-hint,
                  hint='Mark it for old games to skip introducing the Decryption Key, but try to look for it to avoid Adjust missing referrer data'
                )

              v-col(cols='12', md='4')
                v-text-field(
                  label='Decryption Key',
                  v-model='decryptionKey',
                  :rules='[ensureIsValidDecryptionKey]',
                  :disabled='loading',
                  outlined,
                  clearable,
                  persistent-hint,
                  hint='Go to the FB Dev Website to find the decryption key'
                )

          v-row
            v-col(cols='12', md='4')
              v-text-field(
                label='Page ID',
                v-model='pageId',
                :rules='[rules.notEmpty]',
                :disabled='loading',
                outlined,
                clearable,
                persistent-hint,
                hint='ID of the primary Facebook page, commonly used for US campaigns'
              )

            v-col(cols='12', md='4')
              v-text-field(
                label='Secondary Page ID',
                v-model='secondaryPageId',
                :disabled='loading',
                outlined,
                clearable,
                persistent-hint,
                hint='ID of the secondary Facebook page, commonly used for Worldwide campaigns'
              )

          v-row
            v-col.d-flex.justify-start.align-center(cols='6')
              span You can create a page #[a(:href='facebookPageHref', target='_blank') here], don't forget to give to the system user "voodoo.admin" the access "create ads" to the pages.

            v-col.d-flex.justify-end.align-center(cols='6')
              v-switch(
                v-model='isAdaccountInAppDeveloper',
                :disabled='loading',
                label='Toggle on if the ad account is added to Facebook developer'
              )
              v-tooltip(top)
                template(v-slot:activator='{ on }')
                  v-btn(
                    @click='openAdAccount',
                    v-on='on',
                    :disabled='!appId',
                    icon
                  )
                    v-icon {{ icons.mdiOpenInNew }}

                span Check if the ad account is add to #[code Facebook developer]

            v-col.d-flex.justify-end(cols='12')
              v-btn(
                @click='save',
                :loading='loading',
                :disabled='!valid',
                color='primary'
              )
                .pr-2 Save
                v-icon {{ icons.mdiContentSave }}
</template>

<script>
import { mapState } from 'vuex'
import { mdiContentSave, mdiRefresh, mdiOpenInNew } from '@mdi/js'

import Configuration from '@/mixins/acquisition/configuration.js'

export default {
  name: 'Facebook',

  mixins: [Configuration],

  props: {
    network: {
      type: Object,
      required: true
    }
  },

  mounted () {
    this.$on('refreshed', this.updateValues)

    this.updateValues()
  },

  data: () => ({
    icons: { mdiContentSave, mdiRefresh, mdiOpenInNew },

    error: false,
    valid: false,

    rules: {
      notEmpty: (value) => !!value || 'Something is missing here...'
    },

    appId: null,
    appSecret: null,
    accountId: null,
    decryptionKey: null,
    pageId: null,
    secondaryPageId: null,
    isAdaccountInAppDeveloper: false,
    isOldGame: false
  }),

  computed: {
    ...mapState('acquisition', ['networks']),

    isIos () { return this.platform === 'ios' },

    isAndroid () { return this.platform === 'android' },

    configuration () {
      return {
        appSecret: this.appSecret,
        decryptionKey: this.decryptionKey,
        pageId: this.pageId,
        secondaryPageId: this.secondaryPageId,
        isAdaccountInAppDeveloper: this.isAdaccountInAppDeveloper
      }
    },

    facebookPageHref () {
      return process.env.VUE_APP_FACEBOOK_PAGE_URL
    },

    canNext () {
      return this.valid && this.isAdaccountInAppDeveloper
    }
  },

  methods: {
    updateValues () {
      try {
        this.appId = this.networks.configuration[this.platform][this.network.value].facebookAppId
        this.accountId = this.networks.configuration[this.platform][this.network.value].facebookAdAccountId

        if (!this.appId) this.error = true
      } catch (e) {}
    },
    openAppSecret () {
      if (!this.appId) return

      window.open(
        `https://developers.facebook.com/apps/${this.appId}/settings/basic/`,
        '_blank'
      )
    },
    openAdAccount () {
      if (!this.appId) return

      window.open(
        `https://developers.facebook.com/apps/${this.appId}/settings/advanced/`,
        '_blank'
      )
    },
    /**
     * Validate if the decryption key was introduced
     * Decryption key is optional for old games and mandatory for new ones
     * @param {String|undefined} value FB Decryption key
     * @returns {true|String} error To keep Vuetify compatiblity, this method needs to return
     *  true if there is no error or false or error message to indica it
     */
    ensureIsValidDecryptionKey (value) {
      if (this.isOldGame === false) {
        if (!value || value.length === 0) {
          return 'Please, indicate the FB Decryption key'
        }
      }

      return true
    },
    setIsOldGame () {
      /**
       * Decryption key is attached to the toogle isOldGame so to avoid
       * error messages sync we validate all the form data
       */
      this.$refs.facebookAcquisitionForm.validate()
    }
  },

  watch: {
    canNext () {
      this.$emit('valid', { value: this.canNext, network: this.network.value })
    }
  }
}
</script>
