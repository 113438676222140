<template lang="pug">
  v-card-text
    template(v-if='!isIos')
      v-container(fluid)
        v-row(justify='center')
          h3 Sorry but Apple Ad Search only accepts iOS apps.

    template(v-else)
      v-container(fluid)
        v-form(v-model='valid')
          v-row
            v-col(cols='12')
              v-text-field(
                label='Org ID',
                v-model='orgId',
                :rules='[rules.notEmpty, rules.orgId]',
                :disabled='loading',
                outlined,
                clearable,
                persistent-hint,
                hint='Organisation ID in the Apple Ad Search system'
              )

            v-col.d-flex.justify-end(cols='12')
              v-btn(
                @click='save',
                :loading='loading',
                :disabled='!valid',
                color='primary'
              )
                .pr-2 Save
                v-icon {{ icons.mdiContentSave }}
</template>

<script>
import { mdiOpenInNew, mdiContentSave } from '@mdi/js'

import Configuration from '@/mixins/acquisition/configuration.js'
import { mapState } from 'vuex'

export default {
  name: 'Apple',

  mixins: [Configuration],

  props: {
    network: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    icons: {
      mdiOpenInNew,
      mdiContentSave
    },

    creating: false,
    valid: false,

    rules: {
      orgId: (value) => (!!value && !isNaN(value)) || 'Invalid Org ID',
      notEmpty: (value) => !!value || 'No value entered'
    },

    orgId: null
  }),

  computed: {
    ...mapState('acquisition', ['platform']),

    isSetup: {
      set () {},
      get () { return !!this.orgId }
    },

    isIos () { return this.platform === 'ios' },

    configuration () {
      return {
        orgId: this.orgId,
        isSetup: this.isSetup
      }
    },

    canNext () {
      return this.valid && this.isSetup
    }
  },

  watch: {
    canNext () {
      this.$emit('valid', { value: this.canNext, network: this.network.value })
    }
  }
}
</script>
