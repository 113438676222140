<template lang="pug">
  v-data-table(
    :headers='headers',
    :items='historyEntries',
    @click:row='clicked'
  ).clickable
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'HistoryEntries',

  data: () => ({
    headers: [
      {
        text: 'Game',
        value: 'game'
      },
      {
        text: 'Platform',
        value: 'platform'
      },
      {
        text: 'Owner',
        value: 'owner'
      },
      {
        text: 'Date',
        value: 'date'
      },
      {
        text: 'Operation Type',
        value: 'type'
      }
    ]
  }),

  computed: {
    ...mapGetters('apps', ['app']),
    ...mapGetters('monetization', ['currentGame']),

    historyEntries () {
      return this.entries.map(e => ({
        ...e,
        date: new Date(e.date)
      }))
    }
  },

  props: {
    entries: {
      type: Array,
      required: true
    }
  },

  methods: {
    ...mapActions('apps', [
      'getAppFromSubApp'
    ]),
    async clicked (entry) {
      await this.getAppFromSubApp(entry.appKey)
      this.$router.push({
        name: 'Waterfall',
        query: {
          appId: this.app.id,
          platform: entry.platform
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .clickable {
    cursor: pointer;
  }
</style>
