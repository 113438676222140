import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('acquisition', ['platform']),
    ...mapGetters('apps', ['app']),

    currentAppId () {
      return this.app.apps.find(({ platform }) => platform === this.platform).id
    }
  }
}
