<template lang="pug">
  v-container(fluid)
    h2.mb-5 Here are the App Ids that will be used on the networks
    h3 If you know that the app exists on the network but is not present here, please fill in its ID here
    h3 If you see a wrong app ID being used, please fill in the right ID.

    v-progress-circular(
      v-if='isLoading',
      indeterminate
      color="grey"
    )

    v-card(
      outlined,
      v-if='!isLoading && !networkExistingAppIds.length && !networkAppsToBeCreated.length'
    )
      v-card-title No API will be used here, you can submit right now

    v-card(
      outlined,
      v-if='!isLoading && networkExistingAppIds.length'
    ).ma-5
      v-card-title The following existing networks apps will be used:
      v-card-subtitle Leaving the id empty will not erase it, the existing id will be reused
      v-data-table(
        :headers='headers',
        :items='networkExistingAppIds',
        item-key='network',
        hide-default-footer
      )
        template(
          v-slot:item.id="{ item }"
        )
          v-text-field(
            v-model='item.id',
            @change='handleChange({ id: $event, network: item.network })',
            :hint="item.network.includes('admob') ? 'Admob and Admob Native must have the same app id' : ''"
            persistent-hint
          )

    v-card(
      outlined,
      v-if='!isLoading && networkAppsToBeCreated.length'
    ).ma-5
      v-card-title The following networks apps will be created:
      v-card-subtitle Leaving the id empty will not erase it, the existing id will be reused
      v-data-table(
        :headers='headers',
        :items='networkAppsToBeCreated',
        item-key='network',
        hide-default-footer
      )
        template(
          v-slot:item.id="props"
        )
          v-text-field(
            v-model='props.item.id',
            placeholder='Override value'
            @change='handleChange({ id: $event, network: props.item.network })',
            :hint="props.item.network.includes('admob') ? 'Admob and Admob Native must have the same app id' : ''"
            persistent-hint
          )
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mdiAlertOutline } from '@mdi/js'

export default {
  name: 'NetworkAppIds',

  async beforeMount () {
    await this.findAppNetworkIds()
    this.networkData = { ...this.currentAppFoundNetworkAppIds }
  },

  props: {
    networks: {
      type: Array,
      required: false
    }
  },

  data () {
    return {
      headers: [
        {
          text: 'Network Name',
          align: 'start',
          value: 'network',
          sortable: false
        },
        {
          text: 'Network App ID',
          align: 'start',
          value: 'id',
          sortable: false
        }
      ],
      networkData: {},
      icons: { mdiAlertOutline }
    }
  },

  computed: {
    ...mapGetters('monetization', ['currentAppFoundNetworkAppIds']),
    ...mapState('monetization', ['foundNetworkAppIds']),

    isLoading () {
      return this.foundNetworkAppIds.isLoading
    },

    areApiNetworksUnused () {
      return (
        !this.networkAppsToBeCreated.length &&
        !this.networkExistingAppIds.length &&
        !this.isLoading
      )
    },

    networkExistingAppIds () {
      return Object.entries(this.networkData)
        .filter(
          ([network, id]) =>
            (!this.networks || this.networks.includes(network)) && id
        )
        .map(([network, id]) => ({ network, id }))
    },

    networkAppsToBeCreated () {
      return Object.entries(this.networkData)
        .filter(
          ([network, id]) =>
            (!this.networks || this.networks.includes(network)) && !id
        )
        .map(([network, id]) => ({ network, id }))
    }
  },

  methods: {
    ...mapActions('monetization', ['findAppNetworkIds']),

    handleChange (e) {
      this.networkData = {
        ...this.networkData,
        [e.network]: e.id
      }
      this.$emit('change', this.networkData)
    }
  }
}
</script>
