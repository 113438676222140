<template lang="pug">
  v-container(fluid)
    v-row.mb-4.pl-3
      v-btn(
        @click='toggleExpand'
      )
        v-icon {{ icons.mdiExpandAll }}
        .pl-2 {{ isAllExpanded ? 'Close All' : 'Expand All' }}

      v-btn.mx-2(
        @click='askDefaults'
      )
        v-icon {{ icons.mdiCloudDownload }}
        .pl-2 Generate Defaults

    v-expansion-panels(
      v-model='panels',
      focusable,
      popout,
      multiple
    )
      v-expansion-panel
        v-expansion-panel-header
          .title Mediation
        v-expansion-panel-content
          mediations(
            :all='mediations.all',
            :sections='mediations.sections',
            :config='currentConfig',
            :commonConfiguration='commonConfig',
            @saved='updateCurrentConfig'
          )

      v-expansion-panel(
        v-for='section in sections',
        :key='section'
      )
        v-expansion-panel-header
          .title {{ section }}
        v-expansion-panel-content
          configuration-section(
            :section='section',
            :config='currentConfig[section] || null',
            :commonConfiguration='commonConfig.find(({ name }) => name === section)',
            @saved='updateCurrentConfig'
          )
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mdiExpandAll, mdiCloudDownload } from '@mdi/js'

import ConfigurationSection from '@/components/configuration/vsauceConfigurationSection.vue'
import Mediations from '@/components/configuration/mediations.vue'

import appDecorators from '@/mixins/appDecorators'

export default {
  name: 'ConfigurationPanel',

  components: {
    ConfigurationSection,
    Mediations
  },

  mixins: [appDecorators],

  mounted () {
    this.updateCurrentConfig()
    this.toggleExpand()
  },

  props: {
    store: {
      type: String,
      required: true
    }
  },

  data: () => ({
    icons: { mdiExpandAll, mdiCloudDownload },

    panels: [],

    currentConfig: {},

    mediations: {
      current: 'maxads',
      all: [
        'maxads',
        'ironsource'
      ],
      sections: {
        maxads: ['MaxAds'],
        ironsource: ['IronSource Mediation']
      }
    }
  }),

  computed: {
    ...mapGetters('apps', [
      'app'
    ]),

    ...mapGetters('vsauce', [
      'commonConfig',
      'configs'
    ]),

    currentApp () {
      return this.app.apps.find(({ platform }) => this.store === platform)
    },

    nbSections () {
      return this.sections.length + 1 // Because Mediation
    },
    isAllExpanded () {
      return this.panels.length === this.nbSections
    },

    mediationsSections () {
      return this.mediations.all
        .reduce((acc, name) => {
          acc = [...acc, ...this.mediations.sections[name]]

          return acc
        }, [])
    },
    sections () {
      return this.commonConfig
        .filter(({ name }) => this.isSectionEmpty(name) && !this.mediationsSections.includes(name))
        .map(({ name }) => name)
        .sort()
    }
  },

  methods: {
    ...mapActions('vsauce', [
      'saveConfig'
    ]),

    updateCurrentConfig () {
      this.currentConfig = this.configs[this.currentApp.id]
    },

    isSectionEmpty (sectionName) {
      const section = this.commonConfig.find(({ name }) => name === sectionName)

      if (!section) return false

      const fields = section.fields
        .filter(({ isLegacy }) => !isLegacy)

      return !!fields.length
    },
    toggleExpand () {
      if (this.isAllExpanded) this.panels = []
      else this.panels = [...Array(this.nbSections).keys()].map((k, i) => i)
    },

    async askDefaults () {
      await this.saveConfig({})
      this.updateCurrentConfig()
    }
  }
}
</script>
