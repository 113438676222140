<template lang="pug">
  v-card(outlined)
    v-card-title {{ group.title }}
    v-card-subtitle {{ group.description }}

    v-divider

    v-card-text
      v-data-table(
        :headers='headers',
        :items='members',
        :loading='loading',
        :search='search',
        :custom-filter='searchFilter'
      )
        template(v-slot:top)
          v-container(fluid)
            v-row(justify='space-between')
              v-col(cols='6')
                v-text-field(
                  v-model='search',
                  outlined,
                  label='Search user',
                  hint='Need to find someone? You can look for names, unit or team.'
                )
              v-col(cols='6')
                search(
                  :groupName='group.name',
                  @added='refresh'
                )

        template(v-slot:item.name='{ item }')
          span {{ getDisplayName(item) }}

        template(v-slot:item.division='{ item }')
          span {{ item.profile.division || 'N/A' }}

        template(v-slot:item.department='{ item }')
          span {{ item.profile.department || 'N/A'}}

        template(v-slot:item.action='{ item }')
          .flex.align-center.justify-space-around
            v-btn(
              icon,
              @click='remove(item.id)'
            )
              v-icon(color='red') {{ icons.mdiClose }}
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiClose } from '@mdi/js'

import Search from './search.vue'

export default {
  name: 'UserListing',

  components: {
    Search
  },

  props: {
    group: {
      required: true
    }
  },

  async mounted () {
    await this.refresh()
  },

  data: () => ({
    icons: {
      mdiClose
    },

    search: null,
    loading: false,
    headers: [
      { text: 'Name', value: 'name', align: 'center', sortable: true },
      { text: 'Unit', value: 'division', align: 'center', sortable: true },
      { text: 'Team', value: 'department', align: 'center', sortable: true },
      { text: 'Actions', value: 'action', align: 'center', sortable: true }
    ],
    members: []
  }),

  computed: {
    ...mapState('auth', ['groups'])
  },

  methods: {
    ...mapActions('auth', ['listGroup', 'actOnUserInGroup']),

    searchFilter (value, search, item) {
      if (value === null || search === null) return false

      const term = search.toLowerCase()

      return item.profile.displayName.toLowerCase().includes(term) ||
        item.profile.division.toLowerCase().includes(term) ||
        item.profile.department.toLowerCase().includes(term)
    },

    getDisplayName ({ profile }) {
      return profile.displayName || `${profile.firstName} ${profile.lastName}`
    },

    async refresh () {
      this.loading = true

      await this.listGroup(this.group.name)

      this.members = this.groups.list[this.group.name]

      this.loading = false
    },

    async remove (userId) {
      this.loading = true

      await this.actOnUserInGroup({
        action: 'delete',
        groupName: this.group.name,
        userId
      })

      await this.refresh()
    }
  }
}
</script>
