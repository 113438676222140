<template lang="pug">
  v-data-table(
    v-if='bidders.length'
    :headers='headers',
    :items='bidders',
    :items-per-page='-1',
    :hide-default-footer='true',
    class='elevation-1'
  )
    template(v-slot:item.active='{ item }')
      v-simple-checkbox(v-model='item.active', disabled)
    template(v-slot:item.syncStatus.status='{ item }')
      v-tooltip(left)
        template(v-slot:activator='{ on }')
          div(v-on='on')
            v-btn(
              icon,
              @click='copyError(item.syncStatus)'
            )
              v-icon(
                :color='statusColors[item.syncStatus.status]'
              ) {{ icons.circle }}
        h5(class='text-center') {{ item.syncStatus.status }}
        h5(class='text-center') {{ item.syncStatus.message }} {{ item.syncStatus.messageId }}
</template>

<script>
import { mdiAdjust, mdiAlert } from '@mdi/js'
import { mapGetters, mapActions } from 'vuex'
import { statusColors } from '@/constants/constants'

export default {
  name: 'HeaderBidding',

  props: {
    adUnit: { type: Object, required: true }
  },

  data: () => ({
    icons: {
      circle: mdiAdjust,
      alert: mdiAlert
    },
    headers: [
      { text: 'Network', align: 'center', value: 'network' },
      { text: 'Active', value: 'active', align: 'center' },
      {
        text: 'Status',
        value: 'syncStatus.status',
        align: 'center'
      }
    ],
    biddersByAdUnit: [],
    statusColors
  }),

  computed: {
    ...mapGetters('monetization/bidders', ['getBiddersByAppId']),
    ...mapGetters('monetization', ['currentGame']),

    bidders () {
      return this.getBiddersByAppId(this.currentGame.id).filter(
        b => b.adUnitId === this.adUnit.id
      )
    }
  },

  methods: {
    ...mapActions('monetization/bidders', ['upsertBidders']),

    async editBidder (item) {
      await this.upsertBidders({
        bidders: [{ ...item, active: !item.active }],
        appId: this.currentGame.id
      })
    },

    copyError (syncStatus) {
      if (syncStatus.message || syncStatus.messageId) {
        this.copyText(`${syncStatus.message} - ${syncStatus.messageId}`)
      }
    }
  }
}
</script>
