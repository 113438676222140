export default {
  setCreating (state, { platform, value }) {
    state.creating[platform] = value
  },
  setLastCreatedId (state, id) {
    state.createdId = id
  },
  setLoading (state, bool) {
    state.loading = bool
  },
  setCurrentApp (state, app) {
    state.current = app
  },
  setAppProperty (state, { property, platform, value }) {
    if (!state.current) return

    const appIndex = state.current.apps.findIndex(({ platform: p }) => p === platform)
    state.current.apps[appIndex][property] = value
  },
  setApps (state, apps) {
    state.all = apps
  },
  setRecentApps (state, apps) {
    state.recent = apps
  }
}
