<template lang="pug">
  v-container(fluid)
    v-card(outlined)
      v-card-title Recent Apps

      v-card-subtitle Those are apps you recently looked for.

      v-divider

      v-card-text

        template(v-if='hasApps')
          v-fade-transition(
            leave-absolute,
            group,
            ref='apps',
            class='trans layout row wrap justify-center',
            tag='div'
          )
            template(v-for='app in apps')
              v-col(:key='app.id', cols='6', sm='3', lg='2')

                app-card(:app='app', :small='true')

        template(v-else)
          div.text-md-center Nothing to see here. You have no recent apps.
</template>

<script>
import { mapState, mapActions } from 'vuex'

import AppCard from './app.vue'

export default {
  name: 'RecentApps',

  components: { AppCard },

  async mounted () {
    await this.retrieveRecentApps()
  },

  computed: {
    ...mapState('apps', {
      recentApps: 'recent'
    }),

    apps () {
      const apps = [...this.recentApps]

      return apps
        .sort((a, b) => a.name < b.name ? -1 : 1)
    },

    hasApps () { return this.apps.length }
  },

  methods: {
    ...mapActions('apps', [
      'retrieveRecentApps'
    ])
  }
}
</script>
