<template lang="pug">
  v-data-table(
    :headers='headers',
    :items='budgets'
    :hide-default-footer='multiCountry',
    :expanded.sync='expanded',
    :show-expand='needsCreative'
  )
    template(v-slot:header.budget='{ header }')
      span
        span.mr-2 {{ header.text }}

        v-fade-transition(v-if='!multiCountry', group, leave-absolute)
          template(v-if='editAll.budget.show')
            v-text-field(
              key='edit',
              v-model='editAll.budget.value',
              :prepend-icon='icons.mdiCancel',
              :append-outer-icon='icons.mdiKeyboardReturn',
              outlined,
              dense,
              clearable,
              hide-details,
              :error='editAll.budget.error',
              label='Edit all budgets',
              type='text',
              @click:prepend='editAll.budget.show = false',
              @click:append-outer='updateAllBudgets',
              @keyup.enter='updateAllBudgets'
            )

          template(v-else)
            v-tooltip(top, key='button')
              template(v-slot:activator='{ on, attrs }')
                v-btn(icon, v-on='on', v-bind='attrs', @click='editAll.budget.show = true')
                  v-icon {{ icons.mdiPlaylistEdit }}

              span Update all Budgets

    template(v-slot:header.bid='{ header }')
      span
        span.mr-2 {{ header.text }}

        v-scroll-x-reverse-transition(v-if='!multiCountry', group, leave-absolute)
          template(v-if='editAll.bid.show')
            v-text-field(
              key='edit',
              v-model='editAll.bid.value',
              :prepend-icon='icons.mdiCancel',
              :append-outer-icon='icons.mdiKeyboardReturn',
              outlined,
              dense,
              clearable,
              hide-details,
              :error='editAll.bid.error',
              label='Edit all bids',
              type='text',
              @click:prepend='editAll.bid.show = false',
              @click:append-outer='updateAllBids',
              @keyup.enter='updateAllBids'
            )

          template(v-else)
            v-tooltip(top, key='button')
              template(v-slot:activator='{ on, attrs }')
                v-btn(icon, v-on='on', v-bind='attrs', @click='editAll.bid.show = true')
                  v-icon {{ icons.mdiPlaylistEdit }}

              span Update all Bids

    template(v-slot:item.countries='{ item }')
      v-row(align='center').subtitle-2
        span {{ getNames(item.countries) }}

        template(v-if='needsCreative')
          v-icon.ml-4(
            :color="item.isValid ? 'green' : 'red'"
          ) {{ item.isValid ? icons.mdiCheck : icons.mdiClose }}

        template(v-if='needsCreative')
          budget-info-tooltip(
            :budget='item'
          )

    template(v-slot:item.type='{ item }')
      .subtitle-2 {{ item.type }}

    template(v-slot:item.budget='{ item }')
      v-row(justify='center', align='center')
        v-col(cols='6')
          v-text-field(
            v-model='item.budget',
            :append-outer-icon='icons.mdiPlusCircleOutline',
            :prepend-icon='icons.mdiMinusCircleOutline',
            outlined,
            dense
            hide-details,
            label='Budget',
            type='text',
            @click:append-outer='item.budget += 100',
            @click:prepend='item.budget -= 100'
          )

    template(v-slot:item.bid='{ item }')
      v-row(justify='center', align='center')
        v-col(cols='6')
          v-text-field(
            v-model='item.bid',
            :append-outer-icon='icons.mdiPlusCircleOutline',
            :prepend-icon='icons.mdiMinusCircleOutline',
            outlined,
            dense
            hide-details,
            :label="getBidNameLabel",
            type='text',
            @click:append-outer='increaseBid(item)',
            @click:prepend='decreaseBid(item)'
          )

    template(v-slot:expanded-item='{ headers, item }', v-if='needsCreative')
      td(:colspan='headers.length')
        creatives(
          :budget='item',
          :network='network',
          :countries='countries',
          :campaignType='campaignType',
          @update='updateCreatives'
        )
</template>

<script>
import { xor } from 'lodash'
import { mapState } from 'vuex'
import { mdiPlusCircleOutline, mdiMinusCircleOutline, mdiPlaylistEdit, mdiKeyboardReturn, mdiCancel, mdiClose, mdiCheck, mdiHelpCircleOutline } from '@mdi/js'

import { defaultBudgets } from '@/mixins/acquisition/constants.js'

import Creatives from '@/components/acquisition/creatives/index.vue'
import BudgetInfoTooltip from '@/components/acquisition/creatives/budgetInfoTooltip.vue'

export default {
  name: 'Budgets',

  components: {
    Creatives,
    BudgetInfoTooltip
  },

  props: {
    countries: {
      type: Array,
      required: true
    },
    network: {
      required: true,
      type: Object
    },
    multiCountry: {
      required: true,
      type: Boolean
    },
    ios14Min: {
      required: true,
      type: Boolean
    },
    campaignsType: {
      required: true,
      type: Array
    },
    needsCreative: {
      required: true,
      type: Boolean
    },
    campaignType: {
      required: true,
      type: String
    }
  },

  mounted () {
    this.countries.forEach((country) => this.addBudget(country))

    this.setTemplateBudgets()
    this.$emit('isValid', { network: this.network, bool: this.isValid })
  },

  data: () => ({
    expand: false,
    expanded: [],

    budgets: [],
    editAll: {
      budget: {
        show: false,
        value: null,
        error: false
      },
      bid: {
        show: false,
        value: null,
        error: false
      }
    },

    icons: { mdiPlusCircleOutline, mdiMinusCircleOutline, mdiPlaylistEdit, mdiKeyboardReturn, mdiCancel, mdiClose, mdiCheck, mdiHelpCircleOutline },

    defaults: defaultBudgets
  }),

  computed: {
    ...mapState('acquisition', ['platform', 'budgetTemplate']),

    isValid () {
      if (this.needsCreative) {
        return this.budgets.every(({ isValid }) => isValid)
      } else {
        return this.budgets.every(({ countries }) => !!countries.length)
      }
    },

    hasPercentGoal () {
      return this.network.value === 'applovin' && ['roas_ad_based', 'roas_iap'].includes(this.campaignType)
    },

    headers () {
      const headers = [
        {
          text: this.multiCountry ? 'Countries' : 'Country',
          sortable: !this.multiCountry,
          value: 'countries',
          align: 'start'
        }
      ]

      /**
       * GTM-1044 for control test, they want to rename Budget as "Max Spend per Creative"
       */
      let bidHeader = null
      if (this.network.value === 'tiktok' && this.campaignType === 'controlTest') {
        bidHeader = 'Budget per Creative'
      } else if (this.hasPercentGoal) {
        bidHeader = 'Percent goal'
      }

      if (this.network.campaignsType) headers.push({ text: 'Type', sortable: true, value: 'type', align: 'start' })
      if (!(this.network.hide && this.network.hide.bid)) headers.push({ text: bidHeader || 'Bid', sortable: false, value: 'bid', align: 'center' })
      if (!(this.network.hide && this.network.hide.budget)) headers.push({ text: 'Budget', sortable: false, value: 'budget', align: 'center' })

      return headers
    },

    /**
     * Get the bid label to request the bidding but for some networks (tiktok controlTest)
     * this label needs to be renamed
     * This is a workaround because it should be a new component with its own logic
     * @returns {String} bidLabel
     */
    getBidNameLabel () {
      // for Tiktok controlTest, this label needs to be empty, in other case, 'Bid'
      if (this.network.value === 'tiktok' && this.campaignType === 'controlTest') {
        return ''
      } else if (this.hasPercentGoal) {
        return 'Percent goal'
      }

      return 'Bid'
    },

    currentCountries () {
      return this.budgets
        .filter(({ type }) => !type)
        .map(({ countries }) => countries)
        .flat()
    },

    countryNames () {
      return {
        Worldwide: `${this.network.text} Worldwide`,
        Nordics: 'Nordics',
        ...require('svg-country-flags/countries.json')
      }
    }
  },

  methods: {
    updateAllBudgets () {
      const { value } = this.editAll.budget

      if (!value || isNaN(+value)) {
        this.editAll.budget.error = true
        return
      }

      this.editAll.budget.error = false

      this.budgets.forEach((budget, index) => {
        this.$set(this.budgets[index], 'budget', +value)
      })
    },
    updateAllBids () {
      const { value } = this.editAll.bid

      if (!value || isNaN(+value)) {
        this.editAll.bid.error = true
        return
      }

      this.editAll.bid.error = false

      this.budgets.forEach((budget, index) => {
        this.$set(this.budgets[index], 'bid', +value)
      })
    },

    updateCreatives ({ budget, creatives = null, texts = null, headlines = null, endDate = null }) {
      /**
       * Clean an array of string of empty string and trim each string
       *
       * @param {String[]} strings
       */
      function cleanUp (strings) {
        return strings
          .map((string) => string.trim())
          .filter(Boolean)
      }

      // This is the beauty of Javascript
      budget.creatives = {
        videos: creatives || budget.creatives.videos,
        bodies: texts ? cleanUp(texts) : budget.creatives.bodies,
        titles: headlines ? cleanUp(headlines) : budget.creatives.titles
      }
      budget.endDate = endDate || budget.endDate

      /**
       * Conditions to be able to create:
       *  -- Have at least one selected creative for each country for each network
       *  -- Have at least one primary text for each adgroup (Only in AAA campaigns)
       */
      const hasVideos = !!budget.creatives.videos.length
      // const hastexts = !!budget.creatives.bodies.filter(Boolean).length

      if (this.campaignType === 'splitTest') budget.isValid = hasVideos
      if (this.campaignType === 'advantage') budget.isValid = hasVideos
      if (this.network.value === 'tiktok') {
        switch (this.campaignType) {
          case 'splitTest':
            budget.isValid = hasVideos && budget.endDate !== null
            break
          case 'controlTest': {
            budget.isValid = hasVideos && budget.endDate !== null
            break
          }
        }
      }
    },

    increaseBid ({ countries, bid, type = null }) {
      const index = this.getCampaignIndex(countries, type)
      if (index === -1) return

      const newBid = this.hasPercentGoal ? (+bid + 1).toFixed(2) : (+bid + 0.01).toFixed(2)
      this.budgets[index].bid = newBid
    },

    decreaseBid ({ countries, bid, type = null }) {
      const index = this.getCampaignIndex(countries, type)
      if (index === -1) return

      const newBid = this.hasPercentGoal ? (+bid - 1).toFixed(2) : (+bid - 0.01).toFixed(2)
      this.budgets[index].bid = newBid
    },

    getName (country) {
      return `${this.countryNames[country]} (${country})`
    },

    getNames (countries) {
      return countries.map(this.getName).join(', ')
    },

    addCountry (country) {
      !this.budgets.length ? this.addBudget(country) : this.budgets[0].countries.push(country)
    },

    removeCountry (country) {
      if (!this.budgets.length) {
        return
      }

      this.budgets[0].countries.splice(this.budgets[0].countries.indexOf(country), 1)

      if (this.budgets[0].countries.length === 0) {
        this.budgets = []
      }
    },

    addBudget (country, type = null) {
      const countries = [country]
      const budget = {
        countries,
        ios14Min: this.ios14Min,
        ...this.defaults[country.toUpperCase()][this.platform.split('_')[0]]
      }

      if (this.campaignType === 'splitTest') {
        budget.bid = 0
        budget.budget = 50
      }

      if (this.hasPercentGoal) {
        budget.bid = 20
      }

      if (type) {
        budget.type = type
      }

      if (this.needsCreative) {
        budget.isValid = false
        budget.creatives = {
          videos: [],
          bodies: [],
          titles: []
        }
      }

      this.budgets.push(budget)
    },

    removeBudget (country, type = null) {
      const index = this.getCampaignIndex([country], type)
      this.budgets.splice(index, 1)
    },

    getCampaignIndex (countries, type = null) {
      return this.budgets.findIndex(({ countries: c, type: t }) => {
        return xor(c, countries).length === 0 && ((!t && !type) || t === type)
      })
    },

    updateIos14Min () {
      this.budgets = this.budgets.map((budget) => { return { ...budget, ios14Min: this.ios14Min } })
    },

    setTemplateBudgets () {
      if (this.budgetTemplate) {
        const currentNetworkTemplate = this.budgetTemplate.filter(({ network }) => network === this.network.value)

        this.$nextTick(() => {
          currentNetworkTemplate.forEach(({ targeting, bid, budget }) => {
            const currentBudget = this.budgets.find(({ countries }) => countries.includes(targeting))

            if (!currentBudget) return

            currentBudget.bid = bid
            currentBudget.budget = budget
          })
        })
      }
    }

  },

  watch: {
    async countries (countries) {
      const addedCountry = countries
        .filter((country) => !this.currentCountries.includes(country))

      if (addedCountry.length) {
        const action = this.multiCountry ? this.addCountry : this.addBudget

        addedCountry.forEach(country => action(country))
      } else {
        // some country was removed, we need to find it
        const removedCountries = this.currentCountries
          .filter((country) => !countries.includes(country))

        const action = this.multiCountry ? this.removeCountry : this.removeBudget

        removedCountries.length && removedCountries.forEach((country) => action(country))
      }
    },
    ios14Min (ios14Min) {
      this.updateIos14Min(ios14Min)
    },
    campaignsType: {
      deep: true,
      handler () {
        const toRemove = this.budgets.filter(({ type }) => type && !this.campaignsType.filter(({ type: newType }) => newType === type).length)
        const toAdd = this.campaignsType.filter(({ type }) => type && !this.budgets.filter(({ type: oldType }) => oldType === type).length)

        toRemove.forEach(campaign => {
          campaign.countries.forEach(country => {
            this.removeBudget(country, campaign.type)
          })
        })

        toAdd.forEach(campaign => {
          this.addBudget(campaign.country, campaign.type)
        })
      }
    },

    budgetTemplate: {
      deep: true,
      handler () {
        this.setTemplateBudgets()
      }
    },

    isValid (bool) {
      this.$emit('isValid', { network: this.network, bool })
    }
  }
}
</script>
