var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(!_vm.isAppAvailable)?_c('div',[_c('h2',[_vm._v("The app is currently being updated please come back later")])]):_vm._e(),_c('v-row',{class:{blur: !_vm.isAppAvailable},attrs:{"justify":"space-between"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('mediation-list',{on:{"select":_vm.linkMediationWaterfall}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-row',{attrs:{"justify":"start","align":"center"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{staticClass:"mr-3",attrs:{"icon":"","large":""},on:{"click":_vm.reSync}},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(_vm._s(_vm.icons.mdiDelete))])],1)],1)]}}])},[_c('span',[_vm._v("Redo synchronization")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('v-btn',{attrs:{"color":"warning"},on:{"click":function($event){_vm.showDialog= !_vm.showDialog}}},[_vm._v("Sync "+_vm._s(_vm.currentMediation))])],1)]}}])},[_c('span',[_vm._v("This will erase your current waterfall and retrieve the Line Items that are on "+_vm._s(_vm.currentMediation))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attr = ref.attr;
return [_c('div',{staticClass:"ml-3"},[_c('request-networks',{attrs:{"v-if":_vm.currentMediation,"title":("Update On " + _vm.currentMediation),"subtitle":"Request undefined creation again","description":"Use it when some networks are stuck in CREATED_ON_NETWORK state","useLocalWaterfall":true},on:{"update-networks":_vm.updateOnMediation}})],1)]}}])},[_c('span',[_vm._v("Create all lineItems on "+_vm._s(_vm.currentMediation)+", without calling the networks")])])],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{attrs:{"align":"center","justify":"end"}},[_c('request-networks',{attrs:{"title":"Update Networks","subtitle":"Request networks again using the local waterfall","description":"Use it when some networks are stuck in NETWORK_ERROR or CREATING_ON_NETWORK state","useLocalWaterfall":true},on:{"update-networks":_vm.updateOnNetworks}}),_c('div',{staticClass:"ml-3"},[_c('request-networks',{attrs:{"title":"Update Setup","overrideOrders":_vm.allNetworkAsOrder,"subtitle":"Update actual setup using the template"},on:{"update-networks":_vm.updateSetupOnNetworks}})],1),(_vm.currentMediation === "max")?_c('div',{staticClass:"ml-3"},[_c('request-networks',{attrs:{"title":"Update Bidders","subtitle":"Update used bidders","description":"Disable or enable bidders per adunits","overrideOrders":_vm.allNetworkAsOrder,"bidders":true,"useLocalWaterfall":true},on:{"update-networks":_vm.updateBiddersOnNetworks}})],1):_vm._e()],1)],1),_c('waterfall-display',{attrs:{"gameId":_vm.currentGame.id},on:{"line-items-create":_vm.onCreate,"line-items-update":_vm.onUpdate}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }