<template lang="pug">
  v-container(fluid)
    v-row()
      v-menu(
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        top
        offset-x
        min-width="auto"
      )
        template( v-slot:activator="{ on, attrs }")
          v-icon(
            large
            @click="menu = !menu"
            v-on="on"
            v-bind="attrs"
            class="my-auto"
          ) {{icons.mdiCalendarRange}}
        v-date-picker(v-model="date" :allowed-dates="allowedDates")
          v-spacer
          v-btn(
            text
            color="primary"
            @click="menu = false"
          ) Cancel
          v-btn(
            text
            color="primary"
            @click="$refs.menu.save(date)"
          ) OK
      v-text-field.mx-4(
        v-model="dateText"
        :label="getTextFieldLabel"
        class="my-auto"
        placeholder="YYYY-MM-DD"
        :error="!isDateTextValid"
        @blur="onBlur()"
        @click="isDateTextValid = true"
      )
</template>

<script>
import { mdiCalendarRange } from '@mdi/js'

export default {
  name: 'DatePicker',

  props: {
    textFieldLabel: String,
    allowedDates: {
      type: Function,
      default () {
        return true
      }
    }
  },

  data: () => ({
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateText: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu: false,
    isDateTextValid: true,
    icons: {
      mdiCalendarRange
    }
  }),

  mounted () {
    this.$emit('update', this.date)
  },

  watch: {
    date () {
      this.dateText = this.date
      this.isDateTextValid = true
      this.$emit('update', this.date)
    }
  },

  computed: {
    getTextFieldLabel () {
      if (this.isDateTextValid) return this.textFieldLabel
      if (!this.allowedDates(this.dateText)) return 'date not allowed'
      return 'Wrong date format'
    }
  },

  methods: {
    isDateValid (date) {
      const isAllowedDates = this.allowedDates(date)
      const isValidDate = !isNaN(Date.parse(date))
      const parseDateString = date.split('-')
      const isDayDate = parseDateString.length === 3 && !parseDateString.includes('') && !parseDateString.includes(' ')
      return (isAllowedDates && isValidDate && isDayDate)
    },
    onBlur () {
      const isDateValid = this.isDateValid(this.dateText)
      this.isDateTextValid = isDateValid
      if (isDateValid) { this.date = this.dateText }
    }
  }
}
</script>
