<template lang="pug">
  v-container(fluid)
    template(v-if='canAccess')
      v-card(outlined)
        waterfall-title(
          @mediation-update='checkWaterfall'
          @platform-update='checkWaterfall'
        )

        v-divider

        template(v-if='isLoading')
          waterfall-loading

        template(v-else)
          v-container.text-center(fluid)

            template(v-if='!currentGameHasWaterfall')
              .subtitle-2 No Waterfall detected for {{ currentGame.name }}
              v-row(
                justify='space-around',
                align='center',
              )
                v-col(cols='4')
                  v-select(
                    label=`${currentMediation}`,
                    outlined,
                    :items='currentMediationAccounts',
                    v-model='mediationAccount',
                    item-value='value',
                    item-text='name'
                  )

                  template-selector(
                    @template-selected='templateName = $event'
                  )

              v-btn(
                :disabled='!mediationAccount'
                @click='createWaterfall(templateName)',
                color='primary'
              ).mb-9 New waterfall

              v-spacer

              v-divider

              mediation-list(@select='linkMediationWaterfall')

            template(v-else)
              template(v-if="lineItemMode === 'display'")
                line-item-display(
                  @line-item-create='create',
                  @line-item-update='display',
                )

              template(v-else-if="lineItemMode === 'creation'")
                line-item-creation(
                  @cancel='display',
                  @create='display'
                )

    no-access(v-else)

    action-summary
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import WaterfallTitle from '@/components/monetization/title.vue'
import WaterfallLoading from '@/components/monetization/loading.vue'
import WaterfallDialog from '@/components/monetization/dialog.vue'
import ActionSummary from '@/components/monetization/waterfall/actionSummary/actionSummary.vue'

import LineItemDisplay from '@/components/monetization/waterfall/lineItemDisplay.vue'
import LineItemCreation from '@/components/monetization/waterfall/lineItemCreation.vue'

import MediationList from '@/components/monetization/mediation/mediationList.vue'
import TemplateSelector from '@/components/monetization/waterfall/common/templateSelector.vue'

import checkIfApp from '@/mixins/checkIfApp.js'

export default {
  name: 'Waterfall',

  mixins: [checkIfApp],

  data: () => ({
    restrict: {
      groups: ['ADMIN', 'MONET_GUY', 'UA_GUY']
    },

    mediationAccount: null,
    templateName: undefined
  }),

  components: {
    WaterfallTitle,
    WaterfallLoading,
    MediationList,
    LineItemCreation,
    LineItemDisplay,
    WaterfallDialog,
    TemplateSelector,
    ActionSummary
  },

  async created () {
    this.$on('app-config', async () => this.checkWaterfall())
  },

  async mounted () {
    const platform = this.$route.query.platform || 'ios'
    const mediation = this.$route.query.mediation || 'max'
    this.setPlatform(platform)
    this.setMediation(mediation)
    if (this.app) {
      await this.checkWaterfall()
    }
  },

  computed: {
    ...mapState('monetization', ['isLoading']),
    ...mapState('monetization/lineItems', {
      lineItemsDisplayMode: 'displayMode'
    }),

    ...mapGetters('monetization', ['currentGame', 'currentMediation', 'currentGameHasWaterfall', 'currentMediationAccounts']),
    ...mapGetters('apps', ['app']),
    ...mapGetters('auth', ['user']),

    lineItemMode: {
      get () {
        return this.lineItemsDisplayMode
      },
      set (value) {
        this.setDisplayMode(value)
      }
    }
  },

  methods: {
    ...mapMutations('monetization', [
      'setLoading',
      'setPlatform',
      'setMediation'
    ]),
    ...mapMutations('monetization/lineItems', ['setDisplayMode']),

    ...mapActions('monetization', [
      'fetchAppWaterfall',
      'createNewWaterfall',
      'syncWaterfall',
      'displayActionSummary'
    ]),

    display () {
      this.lineItemMode = 'display'
    },
    create () {
      this.lineItemMode = 'creation'
    },

    async checkWaterfall () {
      this.display()

      await this.fetchAppWaterfall()
    },
    async linkMediationWaterfall (app) {
      this.setLoading(true)

      await this.syncWaterfall({
        appKey: this.currentGame.id,
        mediationAppId: app.key
      })

      await this.checkWaterfall()
    },
    createWaterfall (templateName) {
      this.displayActionSummary({
        isDisplayed: true,
        action: async () => {
          this.setLoading(true)

          await this.createNewWaterfall({
            id: this.currentGame.id,
            mediationAccount: this.mediationAccount,
            owner: this.user.email,
            templateName: templateName
          })

          await this.checkWaterfall()
        }
      })
    }
  }
}
</script>
