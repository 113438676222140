<template lang="pug">
  v-card(outlined)
    v-card-title Game information

    v-card-subtitle
      v-row(justify='space-between')
        span
        span {{ lastUpdateText }}

    v-divider

    v-card-text
      v-row(align='center', justify='space-around')
        v-col(cols='12', sm='6', md='4', lg='3')
          v-text-field(
            outlined,
            label='App Name',
            persistent-hint,
            hint='The name that will be used internally.'
            v-model='form.name'
          )
        v-col(cols='12', sm='6', md='4', lg='3')
          v-text-field(
            outlined,
            label='Marketing Code',
            persistent-hint,
            hint='What should the reduced name be?',
            v-model='form.code'
          )
        v-col(cols='12', sm='6', md='4', lg='3')
          v-text-field(
            label='Icon URL',
            readonly,
            outlined,
            v-model='app.iconUrl'
          )
            template(v-slot:append)
              v-icon(@click='refreshIcon') {{ icons.mdiRefresh }}
              v-icon.pl-1(@click='copyText(app.iconUrl)') {{ icons.mdiContentCopy }}

      v-row(justify='center')
        v-col(cols='12', md='8', lg='6')
          v-expansion-panels(popout)
            v-expansion-panel
              v-expansion-panel-header Show more
              v-expansion-panel-content
                v-text-field(
                  label='ID',
                  readonly,
                  outlined,
                  v-model='app.id'
                )
                  template(v-slot:append)
                    v-icon(@click='copyText(app.id)') {{ icons.mdiContentCopy }}

                v-autocomplete(
                  outlined,
                  label='Attribution Partner'
                  persistent-hint,
                  hint='Which one is going to track events?',
                  v-model='form.partner',
                  :items='partners'
                )
</template>

<script>
import { debounce } from 'lodash'
import { mdiRefresh, mdiContentCopy } from '@mdi/js'
import { mapGetters } from 'vuex'

import copyText from '@/mixins/copyText'
import Notifications from '@/mixins/notifications.js'

export default {
  name: 'GeneralInformation',

  mixins: [copyText, Notifications],

  mounted () {
    this.form.name = this.app.name
    this.form.code = this.app.marketingAttributes.marketingCode
    this.form.partner = this.app.marketingAttributes.attributionPartner
  },

  data () {
    return {
      form: {
        name: null,
        code: null,
        partner: null
      },

      preventSave: true,

      icons: { mdiRefresh, mdiContentCopy },
      partners: [{
        text: 'Adjust',
        value: 'adjust'
      }, {
        text: 'Tenjin',
        value: 'tenjin',
        disabled: true
      }]
    }
  },

  computed: {
    ...mapGetters('apps', ['app']),

    lastUpdateText () {
      const lastUpdate = new Date(this.app.marketingAttributes.updatedAt)

      return lastUpdate
        ? `Last update on ${lastUpdate.toLocaleDateString()} at ${lastUpdate.toLocaleTimeString()}.`
        : ''
    }
  },

  methods: {
    async refreshIcon () {
      const { apps } = this.app

      const icon = apps
        .map(({ iconUrl }) => iconUrl)
        .filter(Boolean)
        .sort()
        .reverse()[0]

      if (!icon) {
        this.notify('Could not find any icon URL. Try refreshing iOS or Android apps below.', true)
        return
      }

      this.app.iconUrl = icon

      this.$emit('save', {
        updateType: 'unifiedApp',
        general: {
          iconUrl: this.app.iconUrl
        }
      })
    },

    save: debounce(async function () {
      if (this.preventSave) {
        this.preventSave = false
        return
      }

      this.$emit('save', {
        updateType: 'attributes',
        general: {
          voodooName: this.form.name,
          marketingCode: this.form.code,
          attributionPartner: this.form.partner
        }
      })
    }, 300)
  },

  watch: {
    form: {
      deep: true,
      async handler () {
        return this.save()
      }
    }
  }
}
</script>
