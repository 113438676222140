export const defaultBudgets = {
  // TIER 1
  US: { ios: { bid: 0.30, budget: 2000 }, android: { bid: 0.30, budget: 2000 } },
  GB: { ios: { bid: 0.30, budget: 1000 }, android: { bid: 0.30, budget: 1000 } },
  AU: { ios: { bid: 0.30, budget: 1000 }, android: { bid: 0.30, budget: 1000 } },
  CA: { ios: { bid: 0.30, budget: 1000 }, android: { bid: 0.30, budget: 1000 } },
  RU: { ios: { bid: 0.10, budget: 1000 }, android: { bid: 0.04, budget: 1000 } },
  SA: { ios: { bid: 0.10, budget: 1000 }, android: { bid: 0.08, budget: 500 } },
  BR: { ios: { bid: 0.05, budget: 1000 }, android: { bid: 0.08, budget: 1000 } },
  IN: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.02, budget: 1000 } },
  // TIER 2
  FR: { ios: { bid: 0.25, budget: 1000 }, android: { bid: 0.25, budget: 1000 } },
  DE: { ios: { bid: 0.25, budget: 1000 }, android: { bid: 0.25, budget: 1000 } },
  JP: { ios: { bid: 0.25, budget: 1000 }, android: { bid: 0.30, budget: 1000 } },
  CN: { ios: { bid: 0.20, budget: 1000 }, android: null },
  // TIER 3
  MX: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  NORDICS: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  WORLDWIDE: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  ES: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  IT: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  SE: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  DK: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  NL: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  NO: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  BE: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  CH: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  FI: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  KR: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  AE: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  ID: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  PH: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  TW: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  TR: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  HK: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  SG: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  PL: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  TH: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  MY: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  KW: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  QA: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  AT: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  IL: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  ZA: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  NZ: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  VN: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  CZ: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  AR: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  CL: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  PE: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } },
  PT: { ios: { bid: 0.08, budget: 500 }, android: { bid: 0.08, budget: 500 } }
}
