/**
 * Helper to handle notifications
 *
 * @export
 * @param {import('vuex').Commit} commit The Store commit function
 * @param {String} msg The message to display
 * @param {Boolean} [isError=false] Whether it should be an error
 */
export default async function (commit, msg, isError = false, duration = 5000) {
  await commit('setNotificationMessage', msg, { root: true })
  await commit('setNotificationIsError', isError, { root: true })
  await commit('setNotificationDuration', duration, { root: true })

  await commit('showNotification', true, { root: true })
}
