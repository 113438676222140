<template lang="pug">
  v-container(fluid)
    v-btn(
      @click='exportWaterfall'
    ) Export waterfall
    a(
      ref='fileLink',
      :download='fileName'
    )
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import createBlob from '@/utils/createBlob'

export default {
  name: 'ExportWaterfall',

  computed: {
    ...mapGetters('monetization', ['currentGame']),
    ...mapState('monetization/lineItems', ['exportedLineItems']),

    fileName () {
      return `${this.currentGame.name} - ${this.currentGame.platform}.csv`
    }
  },

  methods: {
    ...mapActions('monetization/lineItems', ['exportLineItems']),

    async exportWaterfall () {
      await this.exportLineItems({ appKey: this.currentGame.id })

      const exportURL = createBlob(this.exportedLineItems, 'text/csv')

      this.$refs.fileLink.setAttribute('href', exportURL)
      this.$refs.fileLink.click()
    }
  }
}
</script>
