<template lang="pug">
  //- TODO: Refactor this file so that it gets smaller and can be more easily apprehended.

  v-container(fluid)
    v-row(justify='center')
      v-col(cols='12')
        v-card(outlined)
          v-card-title
            span VSauce Configuration
            v-spacer
            v-switch(
              label="I know what I'm doing."
              v-model='edit',
              hide-details
            )

          v-card-subtitle Are you sure you should be here?

          v-divider

          v-fade-transition
            template(v-if='isLoading')
              v-card-text.text-center
                v-progress-circular(indeterminate)

            template(v-else)
              v-card-text
                .text-right.mb-4
                  v-scroll-x-reverse-transition(group, leave-absolute)
                    .new-category-input.mr-4(
                      v-if='newCategory.show',
                      key='addLabel',
                    )
                      v-text-field(
                        v-model='newCategory.name',
                        label='New Category name',
                        hint='Press Enter once done.',
                        @keyup.enter='addCategory',
                        :disabled='!edit'
                      )
                    v-btn(
                      v-else,
                      key='addButton',
                      @click='newCategory.show = true',
                      :disabled='!edit'
                    )
                      v-icon {{ icons.mdiPlus }}
                      span.pl-2 Add Category

                  v-btn.ml-8(
                    @click='save',
                    :disabled='!edit',
                    :color="edit ? 'primary' : 'default'"
                    width='10%'
                  )
                    v-icon {{ icons.mdiContentSave }}
                    .pl-2 Save

                v-row(justify='end')
                  v-col(cols='12', lg='3', md='4', sm='6')
                    v-select(
                      label='Platform',
                      hint='This will impact the default values',
                      persistent-hint,
                      outlined,
                      :items='stores.all',
                      v-model='stores.current',
                      @input='updateDefaultValues'
                    )

                v-expansion-panels(popout, multiple, :readonly='!edit')
                  template(v-for='(category, categoryIndex) in localConfig')
                    v-expansion-panel
                      v-expansion-panel-header.py-0
                        template(v-slot:default='{ open }')
                          v-row(justify='space-around', align='center')
                            v-col(cols='8', sm='5', md='6')
                              .title {{ category.name }}
                            v-col(cols='4', sm='2', md='2')
                              .text-secondary {{ category.fields.length }} fields

                            v-col(cols='6', sm='5', md='2')
                              v-btn(
                                @click.stop='removeCategory(categoryIndex)',
                                outlined,
                                color='red',
                                :disabled='!edit'
                              )
                                v-icon {{ icons.mdiMinusCircleOutline }}
                                span.pl-2 Remove

                      v-expansion-panel-content
                        v-divider
                        template(v-for='(field, fieldIndex) in category.fields')
                          v-row(justify='space-around')
                            v-col(cols='12', sm='4', md='2')
                              v-text-field(
                                outlined,
                                label='Display Name',
                                v-model='field.displayName',
                                :disabled='!edit'
                                hint='Name that will be used on this platform.'
                              )
                            v-col(cols='12', sm='4', md='2')
                              v-text-field(
                                :readonly='!editables.includes(`${categoryIndex}-${fieldIndex}`)',
                                outlined,
                                label='Technical Name',
                                v-model='field.name',
                                :disabled='!edit'
                                hint='Name used in the Voodoo Sauce settings.'
                              )
                            v-col(cols='12', sm='4', md='2')
                              v-autocomplete(
                                outlined,
                                label='Attribute\'s type',
                                v-model='field.type',
                                :items='availableTypes',
                                :disabled='!edit'
                                hint='Type of the value.'
                              )
                            v-col(cols='12', sm='4', md='2')
                              v-text-field(
                                outlined,
                                label='Default value',
                                v-model='defaultValues[field.id]',
                                :disabled='!edit'
                                hint='How this value should be defaulted.'
                              )
                            v-col(cols='4', sm='4', md='1')
                              v-switch(
                                label='Editable',
                                hint='Whether this field should be editable on this platform',
                                persistent-hint,
                                :disabled='!edit'
                                v-model='field.isEditable'
                              )
                            v-col(cols='4', sm='4', md='1')
                              v-switch(
                                label='Legacy',
                                hint='Is this field to be forgotten?',
                                persistent-hint,
                                :disabled='!edit'
                                v-model='field.isLegacy'
                              )
                            v-col.text-center(cols='4', sm='4', md='2')

                              v-btn(
                                v-if='fieldIndex !== category.fields.length - 1',
                                icon,
                                large,
                                @click="updateFieldOrder('increment', categoryIndex, fieldIndex)",
                                :disabled='!edit'
                              )
                                v-icon {{ icons.mdiChevronDown }}
                              v-btn(
                                v-if='fieldIndex > 0',
                                icon,
                                large,
                                @click="updateFieldOrder('decrement', categoryIndex, fieldIndex)",
                                :disabled='!edit'
                              )
                                v-icon {{ icons.mdiChevronUp }}
                              v-btn(
                                icon,
                                large,
                                @click='removeField(categoryIndex, fieldIndex)',,
                                :disabled='!edit'
                              )
                                v-icon(color='warning') {{ icons.mdiDelete }}
                              template(v-if='editables.includes(`${categoryIndex}-${fieldIndex}`)')
                                v-btn.ml-4(
                                  @click='save(true)',,
                                  :disabled='!edit'
                                )
                                  v-icon {{ icons.mdiContentSave }}
                                  .pl-2 Save

                        v-divider(v-show='category.fields.length !== 0')

                        v-container.text-right(pb-0)
                          v-btn(
                            @click='addField(categoryIndex)',
                            :disabled='!edit'
                          ) Add field

          v-divider

          v-card-actions
            v-spacer
            v-btn(
              @click='save',
              :disabled='!edit',
              :color="edit ? 'primary' : 'default'"
              width='10%'
            )
              v-icon {{ icons.mdiContentSave }}
              .pl-2 Save
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import { mdiDelete, mdiMinusCircleOutline, mdiPlus, mdiContentSave, mdiChevronDown, mdiChevronUp } from '@mdi/js'

import Notifications from '@/mixins/notifications.js'
import stores from '@/utils/platforms.js'

export default {
  name: 'CommonConfiguration',

  mixins: [Notifications],

  data: () => ({
    icons: { mdiDelete, mdiMinusCircleOutline, mdiPlus, mdiContentSave, mdiChevronDown, mdiChevronUp },

    edit: false,
    editables: [],

    stores: {
      current: 'ios',
      all: stores
    },

    availableTypes: [{
      text: 'String',
      value: 'text'
    }, {
      text: 'File',
      value: 'file'
    }, {
      text: 'Boolean',
      value: 'boolean'
    }],
    newCategory: {
      show: false,
      name: null
    },

    localConfig: [],
    defaultValues: {}
  }),

  async beforeMount () {
    await this.retrieveCommonConfig()
    this.updateDefaultValues()
  },

  computed: {
    ...mapGetters('vsauce', [
      'commonConfig',
      'getConfig',
      'isLoading'
    ]),
    ...mapGetters('apps', [
      'app'
    ]),

    allFields () {
      return this.localConfig.reduce((acc, { fields }) => {
        return {
          ...acc,
          ...fields.reduce((acc2, field) => ({
            ...acc2,
            [field.id]: field
          }), {})
        }
      }, [])
    }
  },

  methods: {
    ...mapActions('vsauce', [
      'retrieveCommonConfig',
      'saveCommonConfig',
      'retrieveConfig'
    ]),

    addField (index) {
      this.localConfig[index].fields.push({
        displayName: null,
        name: null,
        type: 'text',
        isEditable: true
      })

      this.editables.push(`${index}-${this.localConfig[index].fields.length - 1}`)
    },
    removeField (categoryIndex, fieldIndex) {
      this.localConfig[categoryIndex].fields.splice(fieldIndex, 1)

      const editableIndex = this.editables.findIndex((key) => key === `${categoryIndex}-${fieldIndex}`)
      if (editableIndex !== -1) this.editables.splice(editableIndex, 1)
    },
    updateFieldOrder (type, categoryIndex, fieldIndex) {
      const isIncrement = type === 'increment'
      const values = this.localConfig[categoryIndex].fields

      const swapWithIndex = isIncrement ? fieldIndex + 1 : fieldIndex - 1
      const toSwap = values[swapWithIndex]

      values[swapWithIndex] = values[fieldIndex]
      values[fieldIndex] = toSwap

      this.localConfig[categoryIndex].fields = values
      this.$forceUpdate()
    },
    updateDefaultValues () {
      const result = {}
      const store = this.stores.current

      Object.entries(this.allFields).forEach(([key, value]) => {
        const defaultValue = value.defaultValues.find(({ platform }) => platform === store)

        result[value.id] = defaultValue ? defaultValue.value : null
      })

      this.$set(this, 'defaultValues', result)
    },

    addCategory () {
      // We don't want category names to be identical
      if (this.commonConfig.find(({ name }) => name === this.newCategory.name)) {
        this.notify(`Category ${this.newCategory.name} already exists.`)
        return
      }

      this.localConfig = [
        { fields: [], name: this.newCategory.name },
        ...this.localConfig
      ]

      this.newCategory = {
        name: null,
        show: false
      }
    },
    removeCategory (categoryIndex) {
      this.localConfig.splice(categoryIndex, 1)
    },

    async save () {
      await this.saveCommonConfig(this.localConfig)
      this.editables = []
    }
  },

  watch: {
    commonConfig: {
      deep: true,
      handler () {
        this.localConfig = cloneDeep(this.commonConfig)
      }
    },
    defaultValues: {
      deep: true,
      handler (values) {
        const store = this.stores.current

        // Iterating over default values
        // Key === String(fieldId)
        // Value === default value
        for (const [key, value] of Object.entries(values)) {
          // Value shouldn't be null or emtpy string, makes no sense
          if (!value) continue

          // Given a fieldId, we need to find its category to find the field. Tedious work
          const category = this.localConfig.find(({ fields }) => fields.find(({ id }) => +key === id))
          const currentField = category.fields.find(({ id }) => +key === id)

          // Now we need to find back the default value in the current field
          const valueIndex = currentField.defaultValues.findIndex(({ platform }) => platform === store)

          if (valueIndex > -1) {
            // Means we have to update it if different
            currentField.defaultValues[valueIndex].value = value
          } else {
            // Means we have to add it
            currentField.defaultValues.push({
              fieldId: +key,
              platform: store,
              value
            })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.new-category-input {
  max-width: 40%;
  display: inline-block;
}
</style>
