export default {
  hasApp (state) {
    return !!state.current
  },
  app (state) {
    return state.current
  },
  apps (state) {
    return Array.from(state.all)
  },
  androidApp (state) {
    return state.current && Array.isArray(state.current.apps) && state.current.apps.find(({ platform }) => platform === 'android')
  },
  iosApp (state) {
    return state.current && Array.isArray(state.current.apps) && state.current.apps.find(({ platform }) => platform === 'ios')
  },
  platforms (state) {
    return state.current
      ? state.current.apps
        .map(({ platform }) => platform)
        .sort()
      : []
  }
}
