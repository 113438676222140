<template lang="pug">
  v-card-text
    v-container(fluid)
      v-form(v-model='valid')
        v-row
          //- Playable URL
          v-col(cols='12')
            v-text-field(
              label='Playable Url',
              v-model='playableUrl',
              :rules='[rules.playableUrl, rules.notEmpty]',
              :disabled='loading',
              outlined,
              clearable,
              persistent-hint,
              hint='This URL will be used to dowload and send the most popular playable. It must not be empty.'
            )

          //- Save button
          v-col.d-flex.justify-end(cols='12')
            v-btn(
              @click='save',
              :loading='loading',
              :disabled='!valid',
              color='primary'
            )
              .pr-2 Save
              v-icon {{ icons.mdiContentSave }}
</template>

<script>
import { mdiContentSave } from '@mdi/js'

import { VTextField } from 'vuetify/lib'

import Configuration from '@/mixins/acquisition/configuration.js'

export default {
  name: 'Applovin',

  components: { VTextField },

  mixins: [Configuration],

  props: {
    network: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      icons: { mdiContentSave },

      valid: false,

      rules: {
        playableUrl: (value) => this.validPlayableUrl(value) || 'Invalid Playable URL',
        notEmpty: (value) => {
          if (!value || value.length === 0) return 'Playable must not be null'
          return true
        }
      },

      driveUrl: null,

      playableUrl: null
    }
  },

  computed: {
    configuration () {
      return {
        driveUrl: this.driveUrl,
        playableUrl: this.playableUrl
      }
    }
  },

  methods: {
    validPlayableUrl (value) {
      const validPlayableUrlPatterns = [
        /^https:\/\/drive.google.com\/uc\?export=download&id=.*$/,
        /^https:\/\/drive.google.com\/file\/d\/.*$/,
        /^https:\/\/s3.eu-west-2.amazonaws.com\/voodoo-playable-ads\/.+\/applovin\/.+.html$/
      ]

      const isValid = validPlayableUrlPatterns.some(pattern => pattern.test(value))

      return isValid
    }
  },

  watch: {
    valid () {
      this.$emit('valid', { value: this.valid, network: this.network.value })
    }
  }
}
</script>
