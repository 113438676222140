<template lang="pug">
  v-container(fluid)
    p(v-if='!form && !loading') This form does not exist
    p(v-else-if=`form.status === 'AWAITING_REVIEW'`) This form has already been submitted
    v-card(
      outlined,
      v-else
    )
      v-card-title
        form-header(
          :network='form.network',
          :appInfo='form.appInfo',
          :mediation='form.mediation',
        )

        v-card-text
          v-row(align='center' justify='center')
            v-col(cols='10')
              h3 Classic Placements
              line-item-list(
                :lineItems='form.lineItems'
                :network='form.network'
                :mediation='form.mediation'
                :onSubmit='lineItemSubmit'
              )
          v-row(align='center' justify='center')
            v-col(cols='10')
              h3 In-App bidding placements
              bidder-list(
                :bidders='form.bidders'
                :network='form.network'
                :mediation='form.mediation'
                :onSubmit='bidderSubmit'
              )

        v-row(align='center' justify='center')
            v-btn(:disabled='!valid' @click='submit') Submit form
</template>

<script>
import { mapState, mapActions } from 'vuex'

import FormHeader from '@/components/monetization/form/formHeader'
import LineItemList from '@/components/monetization/form/lineItemList'
import BidderList from '@/components/monetization/form/bidderList'

import fetchForm from '@/mixins/fetchForm'

export default {
  name: 'PublicForm',

  components: {
    FormHeader,
    LineItemList,
    BidderList
  },

  mixins: [fetchForm],

  computed: {
    ...mapState('monetization/forms', ['form', 'loading']),
    valid () {
      return (
        this.form.bidders.every(li => li.valid === true) &&
        this.form.lineItems.every(li => li.valid === true) &&
        !this.submitting
      )
    }
  },

  methods: {
    ...mapActions('monetization/forms', ['submitForm']),
    lineItemSubmit (lineItem, { values, valid }) {
      const idx = this.form.lineItems.findIndex(li => li === lineItem)
      lineItem.overrideFields = values
      lineItem.valid = valid
      this.$set(this.form.lineItems, idx, lineItem)
    },
    bidderSubmit (bidder, { values, valid }) {
      const idx = this.form.bidders.findIndex(bid => bid === bidder)
      bidder.overrideFields = values
      bidder.valid = valid
      this.$set(this.form.bidders, idx, bidder)
    },
    submit () {
      this.submitForm({
        appId: this.appId,
        formId: this.formId,
        form: this.form
      })
    }
  }
}
</script>
