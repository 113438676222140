<template lang="pug">
  v-card.mb-4(outlined)
    v-card-title
      span Useful Information
      v-spacer
      v-btn(
        icon,
        @click='hide = !hide'
      )
        v-icon {{ hide ? icons.mdiWindowMaximize : icons.mdiWindowMinimize }}

    v-card-subtitle May help with generating values for this store

    v-divider

    v-card-text
      v-expand-transition
        v-container(fluid, v-show='!hide')
          v-row(justify='center', align='center')
            v-col(cols='12', sm='6', md='4', lg='3')
              v-text-field(
                label='App Name',
                readonly,
                outlined,
                v-model='currentApp.name'
              )
                template(v-slot:append)
                  v-icon(@click='copyText(currentApp.name)') {{ icons.mdiContentCopy }}

            v-col(cols='12', sm='6', md='4', lg='3')
              v-text-field(
                label='Marketing Code',
                readonly,
                outlined,
                v-model='app.marketingAttributes.marketingCode'
              )
                template(v-slot:append)
                  v-icon(@click='copyText(app.marketingAttributes.marketingCode)') {{ icons.mdiContentCopy }}

            v-col(cols='12', sm='6', md='4', lg='3')
              v-text-field(
                label='Bundle ID',
                readonly,
                outlined,
                v-model='currentApp.bundleId'
              )
                template(v-slot:append)
                  v-icon(@click='copyText(currentApp.bundleId)') {{ icons.mdiContentCopy }}

            v-col(cols='12', sm='6', md='4', lg='3', v-if='isIos')
              v-text-field(
                label='Store ID',
                readonly,
                outlined,
                v-model='currentApp.storeId'
              )
                template(v-slot:append)
                  v-icon(@click='copyText(currentApp.storeId)') {{ icons.mdiContentCopy }}

            v-col(cols='12', sm='6', md='4', lg='3')
              v-text-field(
                label='Store URL',
                readonly,
                outlined,
                v-model='storeUrl'
              )
                template(v-slot:append)
                  v-icon(@click='copyText(storeUrl)') {{ icons.mdiContentCopy }}
                  v-icon.ml-1(@click='_open(storeUrl)') {{ icons.mdiOpenInNew }}
</template>

<script>
import { mapGetters } from 'vuex'

import { mdiWindowMaximize, mdiWindowMinimize, mdiContentCopy, mdiOpenInNew, mdiInformationOutline } from '@mdi/js'

import copyText from '@/mixins/copyText'
import storeUrl from '@/mixins/storeUrl'
import appDecorators from '@/mixins/appDecorators'

export default {
  name: 'VSauceInformation',

  mixins: [copyText, storeUrl, appDecorators],

  props: {
    store: {
      type: String,
      required: true
    }
  },

  data: () => ({
    icons: { mdiWindowMaximize, mdiWindowMinimize, mdiContentCopy, mdiOpenInNew, mdiInformationOutline },

    hide: false
  }),

  computed: {
    ...mapGetters('apps', [
      'app'
    ]),

    currentApp () {
      return this.app.apps.find(({ platform }) => this.store === platform)
    },
    isIos () {
      return this.store.split('_')[0] === 'ios'
    },
    storeUrl () {
      return this._getStoreUrl(this.currentApp.platform, this.currentApp.storeId)
    }
  }
}
</script>
