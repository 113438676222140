<template lang="pug">
  v-card.app-container(@click='select')
    v-img.white--text.align-end.justify-center(
      :src='iconUrl',
      gradient='to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)',
      height='200px'
    )
      v-card-title {{ app.name }}

    v-card-actions
      v-spacer
      v-icon(
        color='cyan',
        v-if=`hasPlatform('ios')`,
        large
      ) {{ icons.ios }}

      v-icon(
        color='green',
        v-if=`hasPlatform('android')`,
        large
      ) {{ icons.android }}
</template>

<script>
import { mdiAndroidHead, mdiAppleIos } from '@mdi/js'

import EmptyPlaceholder from '@/assets/empty-placeholder.png'

export default {
  name: 'MediationAppCard',

  props: {
    app: Object
  },

  data: () => ({
    icons: { android: mdiAndroidHead, ios: mdiAppleIos }
  }),

  computed: {
    iconUrl () {
      return this.app.iconUrl !== null ? this.app.iconUrl : EmptyPlaceholder
    }
  },

  methods: {
    select () {
      this.$emit('select')
    },

    hasPlatform (refPlatform) {
      return this.app.platform === refPlatform
    }
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  cursor: pointer;
}
</style>
