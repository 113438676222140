<template lang="pug">
  v-container.pa-0.d-block(fluid, fill-height)
    template(v-if='app')
      slot

    template(v-else)
      v-row(justify='center')
        v-col(cols='12').text-center
          h2 Retrieving application info
        v-col(cols='12').text-center
          v-progress-circular(indeterminate)
</template>

<script>
import { mapGetters } from 'vuex'

import checkIfApp from '@/mixins/checkIfApp.js'

export default {
  name: 'AppWrapper',

  mixins: [checkIfApp],

  computed: {
    ...mapGetters('apps', ['app'])
  }
}
</script>
