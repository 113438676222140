import Vue from 'vue'
import Vuex from 'vuex'

import * as modules from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  modules,

  state: {
    dark: false,
    drawer: {
      show: true,
      mini: true
    },
    notification: {
      message: null,
      show: false,
      isError: false,
      duration: 5000
    }
  },
  getters: {
    isDark (state) {
      return state.dark
    },
    notification (state) {
      return state.notification
    }
  },
  mutations: {
    setDark (state, bool) {
      state.dark = bool
    },
    setMiniDrawer (state, bool) {
      state.drawer.mini = bool
    },
    setLeftDrawer (state, bool) {
      state.drawer.show = bool
    },

    setNotificationMessage (state, msg) {
      state.notification.message = msg
    },
    setNotificationDuration (state, duration) {
      state.notification.duration = duration
    },
    showNotification (state, bool) {
      state.notification.show = bool
    },
    setNotificationIsError (state, bool) {
      state.notification.isError = bool
    }
  }
})
