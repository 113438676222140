<template lang="pug">
  v-card-title
    span Waterfall Managers

    v-spacer

    mediation-selection(
      @update='onMediationUpdate',
    )

    v-spacer

    app-selection(
      @update='onPlatformUpdate',
    )
</template>

<script>

import AppSelection from '@/components/monetization/appSelection.vue'
import MediationSelection from '@/components/monetization/mediationSelection.vue'

export default {
  name: 'Title',

  components: {
    AppSelection,
    MediationSelection
  },

  methods: {
    onMediationUpdate () {
      this.$emit('mediation-update')
    },

    onPlatformUpdate () {
      this.$emit('platform-update')
    }
  }
}
</script>
