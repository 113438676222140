<template lang="pug">
  v-tooltip(top)
    template(v-slot:activator='{ on, attr }')
      v-icon(
        v-bind='attr'
        v-on='on'
      ) {{ mdiHelpCircleOutline }}
    div(v-html='text')

</template>

<script>
import { mdiHelpCircleOutline } from '@mdi/js'

export default {
  name: 'Tooltip',

  props: {
    text: {
      type: String,
      required: true
    }
  },

  data: () => ({
    mdiHelpCircleOutline
  })
}
</script>
