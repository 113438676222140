<template lang="pug">
  v-dialog(
    v-model='show'
  )
    template(v-slot:activator='{ on, attrs }')
      v-tooltip(top)
        template(v-slot:activator='{ on: onDialog }')
          v-btn(
            v-bind='attrs'
            v-on='{ ...on, ...onDialog }',
            icon,
            large
          )
            v-icon(large) {{ icons.mdiImport }}

        span Import store from another App

    v-card
      v-card-title Import store

      v-divider

      v-card-text
        v-form(
          v-model='valid'
        )
          v-container
            v-row(justify='center', align='center')
              v-text-field(
                :rules='[rules.notEmpty, rules.appId, rules.notExisting]',
                :loading='loading',
                label='App ID to import',
                v-model='appId'
              )

      v-divider

      v-card-actions
        v-spacer
        v-btn(
          text,
          @click='show = false'
        ) Close
        v-btn(
          @click='importApp',
          :disabled='!valid',
          :loading='loading'
        ) Import
</template>

<script>
import { mdiImport } from '@mdi/js'
import { validate } from 'uuid'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'ImportDialog',

  data: () => ({
    icons: { mdiImport },

    show: false,
    valid: false,

    appId: null
  }),

  computed: {
    ...mapState('apps', ['loading']),
    ...mapGetters('apps', ['app']),

    currentIds () {
      return this.app.apps.map(({ id }) => id)
    },

    rules () {
      return {
        appId: (value) => (!!value && validate(value)) || 'Invalid App ID',
        notExisting: (value) => (!!value && !this.currentIds.includes(value)) || 'App is already there',
        notEmpty: (value) => !!value || 'No value entered'
      }
    }
  },

  methods: {
    ...mapActions('apps', ['import']),

    async importApp () {
      return this.import(this.appId)
    }
  }
}
</script>
