<template lang="pug">
  v-row.my-1
    v-container(v-if='withTier', fluid)
      v-row.mb-1(justify='space-around')
        template(v-for='i in 3')
          v-btn(outlined, small, @click='addTier(i)') Add Tier {{ i }}

    v-autocomplete(
      label='Choose Countries to Target',
      :items='filteredCountries',
      v-model='selected',
      clearable,
      chips,
      deletable-chips,
      filled,
      :multiple='!disableMultiple',
      rounded,
      solo-inverted,
      hide-details
    )
      template(v-slot:selection='data')
        v-chip(
          v-bind='data.attrs',
          :input-value='data.selected',
          close,
          @click='data.select',
          @click:close='remove(data.item.value)',
        )
          template(v-if='data.item.image')
            v-img(:src='data.item.image', contain, max-width='40px')
          .ml-2 {{ data.item.text }}

      template(v-slot:item='data')
        template(v-if="typeof data.item !== 'object'")
          v-list-item-content(v-text='data.item')
        template(v-else)
          template(v-if='data.item.image')
            v-list-item-avatar(tile)
              v-img(:src='data.item.image', contain)
          v-list-item-content
            v-list-item-title {{ data.item.text }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Countries',

  props: {
    /**
     * This component will be used for the main country selector and for
     * each network bid/budget card. If this component is given a base
     * value for selected countries, it means that it is inside a
     * network bid/budget card.
     */
    isMain: {
      type: Boolean,
      required: false
    },
    withTier: {
      type: Boolean,
      required: false
    },
    network: {
      type: Object,
      required: false
    },
    disableMultiple: {
      type: Boolean,
      required: false
    }
  },

  data: () => ({
    selected: [],
    base: [],
    countries: [
      { header: 'Tier 1' },
      { value: 'US', tier: 1, available: { ios: true, android: true } },
      { value: 'GB', tier: 1, available: { ios: true, android: true } },
      { value: 'AU', tier: 1, available: { ios: true, android: true } },
      { value: 'CA', tier: 1, available: { ios: true, android: true } },
      { value: 'RU', tier: 1, available: { ios: true, android: true } },
      { value: 'SA', tier: 1, available: { ios: true, android: false } },
      { value: 'BR', tier: 1, available: { ios: true, android: true } },
      { value: 'IN', tier: 1, available: { ios: false, android: true } },

      { divider: true },
      { header: 'Tier 2' },
      { value: 'FR', tier: 2, available: { ios: true, android: true } },
      { value: 'DE', tier: 2, available: { ios: true, android: true } },
      { value: 'JP', tier: 2, available: { ios: true, android: true } },
      { value: 'CN', tier: 2, available: { ios: true, android: false } },

      { divider: true },
      { header: 'Tier 3' },
      { value: 'MX', tier: 3, available: { ios: true, android: true } },
      { value: 'SA', tier: 3, available: { ios: false, android: true } },
      { value: 'Nordics', tier: 3, available: { ios: true, android: true } },
      { value: 'Worldwide', tier: 3, available: { ios: true, android: true } },
      { value: 'ES', tier: 3, available: { ios: true, android: true } },
      { value: 'IT', tier: 3, available: { ios: true, android: true } },
      { value: 'SE', tier: 3, available: { ios: true, android: true } },
      { value: 'DK', tier: 3, available: { ios: true, android: true } },
      { value: 'NL', tier: 3, available: { ios: true, android: true } },
      { value: 'NO', tier: 3, available: { ios: true, android: true } },
      { value: 'BE', tier: 3, available: { ios: true, android: true } },
      { value: 'CH', tier: 3, available: { ios: true, android: true } },
      { value: 'FI', tier: 3, available: { ios: true, android: true } },
      { value: 'KR', tier: 3, available: { ios: true, android: true } },
      { value: 'AE', tier: 3, available: { ios: true, android: true } },
      { value: 'ID', tier: 3, available: { ios: true, android: true } },
      { value: 'PH', tier: 3, available: { ios: true, android: true } },
      { value: 'TW', tier: 3, available: { ios: true, android: true } },
      { value: 'TR', tier: 3, available: { ios: true, android: true } },
      { value: 'HK', tier: 3, available: { ios: true, android: true } },
      { value: 'SG', tier: 3, available: { ios: true, android: true } },
      { value: 'IN', tier: 3, available: { ios: true, android: false } },
      { value: 'PL', tier: 3, available: { ios: true, android: true } },
      { value: 'TH', tier: 3, available: { ios: true, android: true } },
      { value: 'MY', tier: 3, available: { ios: true, android: true } },
      { value: 'KW', tier: 3, available: { ios: true, android: true } },
      { value: 'QA', tier: 3, available: { ios: true, android: true } },
      { value: 'AT', tier: 3, available: { ios: true, android: true } },
      { value: 'IL', tier: 3, available: { ios: true, android: true } },
      { value: 'ZA', tier: 3, available: { ios: true, android: true } },
      { value: 'NZ', tier: 3, available: { ios: true, android: true } },
      { value: 'VN', tier: 3, available: { ios: true, android: true } },
      { value: 'CZ', tier: 3, available: { ios: true, android: true } },
      { value: 'AR', tier: 3, available: { ios: true, android: true } },
      { value: 'CL', tier: 3, available: { ios: true, android: true } },
      { value: 'PE', tier: 3, available: { ios: true, android: true } },
      { value: 'PT', tier: 3, available: { ios: true, android: true } }
    ]
  }),

  computed: {
    ...mapState('acquisition', ['platform']),

    os () {
      return this.platform.split('_')[0]
    },

    filteredCountries () {
      const countryNames = require('svg-country-flags/countries.json')

      return this.countries
        .filter((country) => {
          if (country.header || country.divider) return true

          return country.available[this.os]
        })
        .map((country) => {
          if (country.header || country.divider) return country

          if (country.value === 'Worldwide' && this.network) return { ...country, text: `${this.network.text} Worldwide` }

          if (['Nordics', 'Worldwide'].includes(country.value)) return { ...country, text: country.value }

          return {
            ...country,
            text: `${countryNames[country.value]} (${country.value})`,
            image: require(`svg-country-flags/svg/${country.value.toLowerCase()}.svg`)
          }
        })
    }
  },

  methods: {
    addTier (tier) {
      const tierCountries = this.countries
        .filter((country) => country.available && country.available[this.os])
        .filter((country) => country.tier === tier)

      tierCountries.forEach(({ value }) => {
        if (!this.selected.includes(value)) {
          this.selected.push(value)
        }
      })
    },

    remove (item) {
      if (this.disableMultiple) {
        this.selected = []
        return
      }
      const country = item
      const index = this.selected.indexOf(country)

      if (index >= 0) this.selected.splice(index, 1)
    },

    setBase (newBase) {
      this.selected = newBase
    },

    addCountry (country) {
      if (this.selected.includes(country)) return

      this.selected.push(country)
    }
  },

  watch: {
    selected () {
      this.$emit('update', Array.isArray(this.selected) ? this.selected : [this.selected])
    }
  }
}
</script>
