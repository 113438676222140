import axios from 'axios'

import { notify } from '@/store/utils'

export default {
  async fetchOverrideFields ({ state, commit, rootGetters }, silent = false) {
    try {
      const med = rootGetters['monetization/currentMediation']
      const { data: { data } } = await axios.get('/public/waterfall/mediation/override-fields', {
        params: { mediation: med }
      })

      commit('setOverrideFields', { data, mediation: med })
    } catch (e) {
      !silent && notify(commit, 'Could not fetch overrideFields: ' + e, true)
    }
  }
}
