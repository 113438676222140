<template lang="pug">
  v-container(v-if='!campaign.deleted')
    v-card-title
      span.title Campaign {{ campaign.name }}
        v-btn(icon, @click='removeCampaign')
          v-icon {{ icons.mdiCloseCircle }}

      template(v-if='canCreateIos14MinOnlyCampaign')
        v-spacer
        v-switch(
          label='iOS 14+ Only',
            v-model='ios14Min',
            hide-details
          )

      v-container(fluid)

        template(v-if='!multiAdgroups')
          v-row(v-if='network.optimizationType')
            v-row(class="mt-5" justify="start")
              v-col(cols="12" sm="2" md="2" lg="2")
                h5(class="mt-1") Optimization type
              v-radio-group(v-model="optimizationType" row @change="onOptimizationTypeChange")
                v-radio(v-for="optim in network.optimizationType" class="mr-4" :key='optim.value' :label='optim.label' :value='optim.value' :disabled='optim.disabled')

          v-row(v-if='bidStrategiesAvailable && bidStrategiesAvailable.length')
            v-row(class="mt-1" justify="start")
              v-col(cols="12" sm="2" md="2" lg="2")
                h5(class="mt-1") Bid strategy
              v-radio-group(v-model="bidStrategy" row @change="onBidStrategyChange")
                v-radio(v-for="strategy in bidStrategiesAvailable" class="mr-4" :key='strategy.value' :label='strategy.label' :value='strategy.value' :disabled='strategy.disabled')

          v-row(v-if='mustSetRoasGoal')
            v-row(class="mt-1 mb-1" justify="start")
              v-col(cols="12" sm="2" md="2" lg="2")
                h5(class="mt-1") ROAS goal
              v-row(class="mt-1" justify='start', align='center')
                v-col(cols='3')
                  v-text-field(
                    v-model='goalValue',
                    :append-outer-icon='icons.mdiPlusCircleOutline',
                    :prepend-icon='icons.mdiMinusCircleOutline',
                    outlined,
                    dense
                    :rules='[roasGoalRules.notEmpty, roasGoalRules.rightRange]',
                    label='ROAS goal',
                    type='text',
                    @click:append-outer='goalValue = (+goalValue + 0.01).toFixed(3)',
                    @click:prepend='goalValue = (+goalValue - 0.01).toFixed(3)'
                  )

          v-row(class="mt-3")
            v-col(cols='12')
              countries(
                ref='countries',
                :network='network',
                @update='updateSelectedCountries'
              )

          v-col(cols='12')
            budgets(
              @isValid='isValid',
              :countries='countries.selected',
              :multiCountry='true',
              :campaignsType='campaignsType',
              :network='network',
              :ios14Min='ios14Min',
              :needsCreative='needsCreative',
              :campaignType='campaignType',
              ref='multiCountryBudget'
            )

        template(v-else)
          template(v-for='(message, name) in warnings')
            v-alert(v-if='message', type='warning', outlined, text).mb-0
              h4 {{ message }}

          v-row(v-if='network.optimizationType')
            v-row(class="mt-5" justify="start")
              v-col(cols="12" sm="2" md="2" lg="2")
                h5(class="mt-1") Optimization type
              v-radio-group(v-model="optimizationType" row @change="onOptimizationTypeChange")
                v-radio(v-for="optim in network.optimizationType" class="mr-4" :key='optim.value' :label='optim.label' :value='optim.value' :disabled='optim.disabled')

          v-row(v-if='bidStrategiesAvailable && bidStrategiesAvailable.length')
            v-row(class="mt-1" justify="start")
              v-col(cols="12" sm="2" md="2" lg="2")
                h5(class="mt-1") Bid strategy
              v-radio-group(v-model="bidStrategy" row @change="onBidStrategyChange")
                v-radio(v-for="strategy in bidStrategiesAvailable" class="mr-4" :key='strategy.value' :label='strategy.label' :value='strategy.value' :disabled='strategy.disabled')

          v-row(v-if='mustSetRoasGoal')
            v-row(class="mt-1" justify="start")
              v-col(cols="12" sm="2" md="2" lg="2")
                h5(class="mt-1") ROAS goal
              v-row(class="mt-1" justify='start', align='center')
                v-col(cols='3')
                  v-text-field(
                    v-model='goalValue',
                    :append-outer-icon='icons.mdiPlusCircleOutline',
                    :prepend-icon='icons.mdiMinusCircleOutline',
                    outlined,
                    dense
                    :rules='[roasGoalRules.notEmpty, roasGoalRules.rightRange]',
                    label='ROAS goal',
                    type='text',
                    @click:append-outer='goalValue = (+goalValue + 0.01).toFixed(3)',
                    @click:prepend='goalValue = (+goalValue - 0.01).toFixed(3)'
                  )

          v-card(class="mt-3")
            div(
              v-for='(adgroup, adgroupIndex) in adgroups',
              :key='adgroupIndex'
            )
              adgroup(
                @isValid='isValid',
                :network='network',
                :adgroup='adgroup',
                :index='adgroupIndex',
                :ios14Min='ios14Min',
                :needsCreative='needsCreative',
                :campaignType='campaignType',
                ref='adgroups'
                @update='updateAdgroupCountries'
              )

            v-card-actions.px-4
              v-spacer
              v-btn(@click='addAdgroup', color='primary', v-if='!needsCreative && !allowedToHaveServeralsAdGroups') Add adgroup

          template(v-for='(message, name) in warnings')
            v-alert(v-if='message', type='warning', outlined, text).mb-0
              h4 {{ message }}
</template>

<script>
import { mapState } from 'vuex'
import { mdiPlusCircleOutline, mdiMinusCircleOutline, mdiCloseCircle } from '@mdi/js'

import Countries from './countries.vue'
import Budgets from './budgets.vue'
import Adgroup from './adgroup.vue'

export default {
  name: 'Campaign',

  components: {
    Budgets,
    Countries,
    Adgroup
  },

  props: {
    network: {
      required: true,
      type: Object
    },
    index: {
      required: true,
      type: Number
    },
    campaign: {
      required: true,
      type: Object
    },
    needsCreative: {
      required: true,
      type: Boolean
    },
    campaignType: {
      required: true,
      type: String
    }
  },

  data: () => ({
    icons: { mdiCloseCircle, mdiPlusCircleOutline, mdiMinusCircleOutline },
    ios14Min: false,
    campaignsType: [],
    adgroups: [],
    optimizationType: null,
    bidStrategy: null,
    bidStrategiesAvailable: null,
    goalValue: null,
    countries: {
      selected: []
    },
    warnings: {},
    roasGoalRules: {
      notEmpty: (value) => !!value || 'No value entered',
      rightRange: (value) => (value > 0.001 && value < 10000) || 'Goal must be between 0.001 and 1000'
    }
  }),

  beforeMount () {
    if (this.canCreateIos14MinOnlyCampaign) this.ios14Min = true
    this.adgroups.push({ name: 1, baseCountry: this.campaign.baseCountry, deletable: false })
    if (this.network.optimizationType && this.network.optimizationAvailableFor.includes(this.campaignType)) this.optimizationType = this.network.optimizationType[0].value
  },

  mounted () {
    if (this.campaign.baseCountry && this.$refs.countries) {
      this.$refs.countries.setBase([this.campaign.baseCountry])
    }
  },

  computed: {
    ...mapState('acquisition', ['platform']),

    isIos () { return this.platform === 'ios' },

    canCreateIos14MinOnlyCampaign () { return this.isIos && this.network.ios14MinOnlyCampaign },

    multiAdgroups () {
      return (this.isIos || this.isFacebook) && this.network.multiAdgroups
    },

    isFacebook () {
      return this.network.value === 'facebook'
    },

    allowedToHaveServeralsAdGroups () {
      return this.campaignType === 'advantage' && this.adgroups.length > 0
    },
    mustSetRoasGoal () {
      return this.bidStrategy && ['roas_goal'].includes(this.bidStrategy)
    }
  },

  methods: {
    isValid (params) {
      this.$emit('isValid', params)
    },

    updateSelectedCountries (countries) {
      if (this.needsCreative) {
        if (countries.length && this.countries.selected.length) return
      }

      this.countries.selected = countries
    },
    removeCampaign () {
      // eslint-disable-next-line vue/no-mutating-props
      this.campaign.deleted = true
      // eslint-disable-next-line vue/no-mutating-props
      this.campaign.baseCountry = ''
      this.updateSelectedCountries([])
    },
    budgets () {
      if (this.campaign.deleted) {
        return []
      }

      if (this.multiAdgroups) {
        const adgroups = this.$refs.adgroups.map(a => {
          const b = a.budgets()
          if (this.optimizationType) b[0].optimizationType = this.optimizationType
          if (this.bidStrategy) b[0].bidStrategy = this.bidStrategy
          if (this.goalValue) b[0].goalValue = this.goalValue
          return b
        }).flat()
        return adgroups
      } else {
        if (this.optimizationType) this.$refs.multiCountryBudget.budgets[0].optimizationType = this.optimizationType
        if (this.bidStrategy) this.$refs.multiCountryBudget.budgets[0].bidStrategy = this.bidStrategy
        if (this.goalValue) this.$refs.multiCountryBudget.budgets[0].goalValue = this.goalValue
        return this.$refs.multiCountryBudget.budgets
      }
    },
    addAdgroup () {
      this.adgroups.push({})
    },
    updateAdgroupCountries ({ countries, adgroupIndex }) {
      this.$set(this.adgroups[adgroupIndex], 'countries', countries)
      this.countries.selected = this.adgroups.map(c => c.countries || []).flat()
    },
    refreshAdgroups () {
      const undeletedAdgroups = this.adgroups.filter(a => !a.deleted)

      if (undeletedAdgroups.length <= 1) {
        undeletedAdgroups.forEach(a => { a.deletable = false })
      } else {
        undeletedAdgroups.forEach(a => { a.deletable = true })
      }

      undeletedAdgroups.forEach((a, index) => { a.name = index + 1 })

      if (this.$refs.adgroups) {
        this.$refs.adgroups.map(c => c.$forceUpdate())
      }
    },
    checkAdgroupLimit () {
      if (this.adgroups.filter(a => !a.deleted).length > this.network.adgroupLimit) {
        this.$set(
          this.warnings,
          'adgroupLimit',
          `${this.network.text} has an adgroup limit of ${this.network.adgroupLimit}`
        )
      } else {
        this.$set(this.warnings, 'adgroupLimit', null)
      }
    },
    onOptimizationTypeChange (type) {
      if (this.network.bidStrategy && this.network.bidStrategy[type]) {
        this.bidStrategy = this.network.bidStrategy[type][0].value
        this.bidStrategiesAvailable = this.network.bidStrategy[type]
      } else {
        this.bidStrategy = null
        this.bidStrategiesAvailable = null
      }
    },
    onBidStrategyChange () {
      this.goalValue = this.mustSetRoasGoal ? 0.010.toFixed(3) : null
    }
  },

  watch: {
    countries: {
      deep: true,
      handler () {
        this.$emit('update', { countries: this.countries.selected, campaignIndex: this.index })
      }
    },
    adgroups: {
      deep: true,
      handler () {
        this.refreshAdgroups()
        this.checkAdgroupLimit()
      }
    }
  }
}
</script>
