export default {
  methods: {
    saveRecentApp (appId) {
      if (!appId) {
        return
      }

      /**
       * app ids are being stored as strings separated by ';' and there is always
       * one empty string
       * To keep retrocompatibility, let's avoid storing 1 empty string '' and store an array of
       * app ids
       * (Edge case: someone can have data in their store after this new release)
       */

      const storage = window.localStorage
      const apps = (storage.getItem('recentApps') || '').split(';').filter(appId => appId !== '')

      if (!apps.includes(appId)) {
        const lastVisitedApps = [appId, ...apps.slice(0, 4)]
        storage.setItem('recentApps', lastVisitedApps.join(';'))
      }
    }
  }
}
