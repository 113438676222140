<template lang="pug">
  div Custom
</template>

<script>
import checkIfApp from '@/mixins/checkIfApp.js'

export default {
  mixins: [checkIfApp]
}
</script>
