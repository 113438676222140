<template lang="pug">
  v-card-text
    v-container(fluid)
      v-alert(type='success', outlined) Nothing to do here 😀
</template>

<script>
import Configuration from '@/mixins/acquisition/configuration.js'

export default {
  name: 'Mintegral',

  mixins: [Configuration],

  props: {
    network: {
      type: Object,
      required: true
    }
  },

  mounted () {
    this.$emit('valid', { value: this.valid, network: this.network.value })
  },

  data: () => ({
    valid: true
  }),

  computed: {
    configuration () {
      return {}
    }
  }
}
</script>
