<template lang="pug">
  v-dialog(v-model='actionSummary.isDisplayed', width='800')
    v-card(v-if='actionSummary.isDisplayed')
      v-card-text
        network-app-ids(
          :networks='actionSummary.networks'
          @change='networkData = $event'
        )
      v-card-actions
        v-row(align='end')
        v-btn(
          @click='applyAction',
          color='primary'
          ) Submit
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import NetworkAppIds from './networkAppIds'

export default {
  name: 'ActionSummary',

  components: {
    NetworkAppIds
  },

  data () {
    return {
      networkData: {}
    }
  },

  computed: {
    ...mapState('monetization', ['actionSummary', 'findAppNetworkIds'])
  },

  methods: {
    ...mapMutations('monetization', ['setActionSummary']),
    ...mapActions('monetization', ['upsertAppNetworkIds']),

    async applyAction () {
      await this.upsertAppNetworkIds({
        networkAppIds: this.networkData
      })
      this.actionSummary.action()
      this.setActionSummary({
        isDisplayed: false,
        networks: [],
        action: () => {}
      })
    }
  }
}
</script>
