export default {
  setLineItems (state, { mediation, appId, lineItems }) {
    lineItems = lineItems.map((lineItem) => {
      if (lineItem.syncStatus === undefined) {
        lineItem.syncStatus = { status: 'CREATED' }
      }
      return lineItem
    })
    state.lineItems[mediation][appId] = lineItems
  },

  setDisplayMode (state, value) {
    state.displayMode = value
  },

  setExportedLineItems (state, value) {
    state.exportedLineItems = value
  },

  setWaterfallValidity (state, isValid) {
    state.isWaterfallValid = isValid
  },

  addInvalidLineItems (state, lineItems) {
    state.invalidLineItems = {
      ...state.invalidLineItems,
      ...lineItems.reduce((acc, li) => ({ ...acc, [li.id]: li }), {})
    }
  },

  removeInvalidLineItems (state, lineItems) {
    lineItems.forEach(lineItem => {
      const { [lineItem.id]: _, ...invalidLineItems } = state.invalidLineItems
      state.invalidLineItems = invalidLineItems
    })
  }
}
