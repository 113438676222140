<template lang="pug">
  v-card-text
    v-container(fluid)
      v-form(v-model='valid')
        v-row
          //- Video link input
          v-col(cols='12')
            v-text-field(
              label='S3 Video link',
              v-model='videoLink',
              :rules='[rules.videoLink]',
              :disabled='loading'
              outlined,
              clearable,
              persistent-hint,
              hint='Link of the video on S3'
            )
              template(v-slot:message)
                div You can find S3 Video link on #[a(:href='renderKidLink', target='_blank') Render Kid]

          v-col.d-flex.justify-space-between(cols='12')
            v-col.d-flex.justify-start(cols='3')
              v-text-field(
                label='Bundle ID',
                :value='bundleId',
                :rules='[rules.bundleId]',
                :loading='isAppOnStore.isLoading',
                disabled,
                outlined,
              )
                v-icon(
                  slot="append"
                  :color="isBundleIdValid ? 'green' : 'red'"
                ) {{ isBundleIdValid ? icons.mdiCheck : icons.mdiClose }}
            v-col(cols='3')
              v-switch(
                v-model='isSetup',
                :disabled='loading',
                label='Active crosspromo campaign',
                hide-details
              )
          //- Save button
          v-col.d-flex.justify-end(cols='12')
            v-btn(
              @click='refreshAll',
              :loading='loading',
              color='primary',
              outlined
            )
              .pr-2 Refresh Video Link
              v-icon {{ icons.mdiRefresh }}

            v-btn.ml-2(
              @click='save',
              :loading='loading',
              :disabled='!valid || !isBundleIdValid',
              color='primary'
            )
              .pr-2 Save
              v-icon {{ icons.mdiContentSave }}
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiContentSave, mdiRefresh, mdiCheck, mdiClose } from '@mdi/js'

import Configuration from '@/mixins/acquisition/configuration.js'

export default {
  name: 'Crosspromo',

  mixins: [Configuration],

  props: {
    network: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    icons: { mdiContentSave, mdiRefresh, mdiCheck, mdiClose },

    valid: false,

    isAppOnStore: {
      isLoading: false,
      isOnIos: false,
      isOnAndroid: false
    },

    rules: {
      videoLink: (value) => !!value || 'No link entered.',
      bundleId: (value) => !!value || 'No bundle ID found.'
    },

    videoLink: null,
    isSetup: null,

    renderKidLink: 'https://cm-dashboard.voodoo-tech.io'
  }),

  mounted () {
    this.refreshAvailability()
  },

  computed: {
    ...mapGetters('apps', [
      'androidApp',
      'iosApp',
      'androidApp'
    ]),
    configuration () {
      return {
        videoLink: this.videoLink,
        isSetup: this.isSetup
      }
    },
    isBundleIdValid () {
      return this.platform === 'ios' ? this.isAppOnStore.isOnIos : this.isAppOnStore.isOnAndroid
    },
    bundleId () {
      return this.platform === 'ios' ? this.iosApp.bundleId : this.androidApp.bundleId
    }
  },

  methods: {
    async refreshAll () {
      this.refreshAvailability()
      this.refreshLink()
    },
    async refreshAvailability () {
      this.isAppOnStore.isLoading = true
      if (this.bundleId) {
        try {
          const { data } = await this.$axios.post('/acquisition/action', {
            action: 'crosspromo-application-avaiability',
            appId: this.currentAppId
          })
          this.isAppOnStore = data
        } catch (e) {
          this.$notify('Error trying to refresh availability', true)
        }
      }
      this.isAppOnStore.isLoading = false
    },
    async refreshLink () {
      this.loading = true
      try {
        await this.refreshConfig()
      } catch (error) {
        this.$notify('Error', true)
      }
      this.loading = false
    }
  },

  watch: {
    valid () {
      this.$emit('valid', { value: this.valid, network: this.network.value })
    }
  }
}
</script>
