<template lang="pug">
  v-container(fluid)
    waterfall-title(@update='fetchAppInfo')
    history-entries(:entries='currentAppHistory')
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import checkIfApp from '../../mixins/checkIfApp'

import WaterfallTitle from '../../components/monetization/title'
import HistoryEntries from '../../components/monetization/historyEntries'

export default {
  name: 'AppHistory',

  mixins: [checkIfApp],

  components: {
    HistoryEntries,
    WaterfallTitle
  },

  data: function () {
    return {
      appKey: {
        type: String
      }
    }
  },

  mounted () {
    if (this.app) {
      this.fetchAppInfo()
    }
  },

  computed: {
    ...mapGetters('apps', ['app']),
    ...mapGetters('monetization', ['currentAppHistory'])
  },

  methods: {
    ...mapActions('monetization', ['fetchHistory', 'fetchAppWaterfall']),

    async fetchAppInfo () {
      await this.fetchAppWaterfall()
      await this.fetchHistory()
    }
  }

}
</script>
