<template lang="pug">
  v-container(fluid)
    ValidationReview(
      ref='review',
      @create='create'
    )

    Result(
      ref='result'
    )

    template(v-if='canAccess')
      v-card(outlined)
        campaign-title(
          title='Campaign Creation',
          :show-platform='false'
        )

        v-card-subtitle You can find documentation about campaign creation on #[a(:href='notionLink', target='_blank') Notion].

        v-divider

        v-card-text
          notes

          v-stepper(vertical, v-model='step')
            v-stepper-step(
              :complete='step > 1',
              step='1'
            ) Platform

            platforms

            v-stepper-step(
              :complete='step > 2',
              step='2'
            ) Choose Networks

            networks

            v-stepper-step(
              :complete='step > 3',
              step='3'
            ) Configure Networks

            configuration(
              ref='configuration'
            )

            v-stepper-step(
              :complete='step > 4',
              step='4'
            ) Configure Targeting

            setup(
              @create='validate'
            )

    no-access(v-else)
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { merge } from 'lodash'

import step from '@/mixins/acquisition/step.js'

import checkIfApp from '@/mixins/checkIfApp.js'

import ValidationReview from '@/components/acquisition/review/dialog.vue'
import CampaignTitle from '@/components/acquisition/title.vue'

import Notes from '@/components/acquisition/notes.vue'

import Platforms from '@/components/acquisition/platforms.vue'
import Result from '@/components/acquisition/result.vue'
import Networks from '@/components/acquisition/networks.vue'
import Configuration from '@/components/acquisition/configuration/index.vue'
import Setup from '@/components/acquisition/setup/index.vue'

export default {
  name: 'Campaigns',

  mixins: [step, checkIfApp],

  components: {
    ValidationReview,
    Result,

    Notes,

    CampaignTitle,
    Platforms,
    Networks,
    Configuration,
    Setup
  },

  destroyed () {
    this.RESET_STATE()
  },

  data: () => ({
    restrict: {
      groups: ['ADMIN', 'UA_GUY']
    },
    notionLink: 'https://www.notion.so/voodoo/5576d905303e4b62a3506b26e38f02df?v=3f8256ccdf314b198928401d9363927c'
  }),

  computed: {
    ...mapState('acquisition', ['platform', 'campaigns', 'creatives', 'budgets', 'type']),
    ...mapGetters('apps', ['app'])
  },

  methods: {
    ...mapMutations('acquisition', ['RESET_STATE']),
    ...mapActions('acquisition', ['createCampaigns']),

    validate () {
      const configurations = this.$refs.configuration.configurations
      const campaigns = merge(this.budgets, configurations)

      const data = {
        appId: this.app.apps.find(({ platform }) => this.platform === platform).id,
        campaigns: Object.values(campaigns)
      }

      this.$refs.review.setData(data)
      this.$refs.review.reveal()
    },

    async create (data) {
      await this.createCampaigns(data)

      this.$refs.review.hide()

      this.$refs.result.toggleShow()

      this.step = 1
    }
  }
}
</script>
