<template lang="pug">
  v-data-table(
    :headers='headers',
    :items='lineItems',
    :expanded.sync='expanded',
    :items-per-page='-1',
    item-key='id',
    show-expand,
    class='elevation-1'
  )
    template(v-slot:expanded-item='{ headers, item }')
      td(:colspan='headers.length')
        line-item-edition(
          :lineItem='item',
          @temp-line-item='onLineItemChanges',
          @deletion-request='onLineItemDeletion'
        )

    template(v-slot:item.active='{ item }')
      v-simple-checkbox(v-model='item.active', disabled)

    template(v-slot:item.syncStatus.status='{ item }')
      v-tooltip(left)
        template(v-slot:activator='{ on }')
          div(v-on='on')
            v-btn(
              icon,
              @click='copyError(item.syncStatus)'
            )
              v-icon(
                v-if='!invalidLineItems[item.id]'
                :color='statusColors[item.syncStatus.status]'
              ) {{ icons.circle }}
              v-icon(
                v-else
                color='red'
              ) {{ icons.alert }}
        h5(class='text-center') {{ item.syncStatus.status }}
        h5(class='text-center') {{ item.syncStatus.message }} {{ item.syncStatus.messageId }}
</template>

<script>
import { mdiAdjust, mdiAlert } from '@mdi/js'
import { mapState } from 'vuex'
import copyText from '@/mixins/copyText.js'
import { statusColors } from '@/constants/constants'

import LineItemEdition from '@/components/monetization/waterfall/lineItemEdition.vue'

export default {
  name: 'LineItems',

  components: { LineItemEdition },

  mixins: [copyText],

  props: {
    lineItems: { type: Array, required: true }
  },

  computed: {
    ...mapState('monetization/lineItems', ['invalidLineItems'])
  },

  data: () => ({
    icons: {
      circle: mdiAdjust,
      alert: mdiAlert
    },

    statusColors,

    expanded: [],

    headers: [
      { text: 'Network', value: 'network', sortable: true, align: 'start' },
      { text: 'CPM', value: 'bid', sortable: true, align: 'start' },
      { text: 'Active', value: 'active', sortable: true, align: 'start' },
      { text: 'IDFA', value: 'idfaTargeting', sortable: true, align: 'start' },
      {
        text: 'Status',
        value: 'syncStatus.status',
        sortable: false,
        align: 'start'
      },
      { text: '', value: 'data-table-expand' }
    ]
  }),

  methods: {
    onLineItemChanges (oldLineItem, tempLineItem) {
      this.$emit('store-selected-line-item', oldLineItem)
      this.$emit('store-temp-line-item', tempLineItem)
    },

    onLineItemDeletion (lineItem) {
      this.$emit('line-item-deletion-request', lineItem)
    },

    copyError (syncStatus) {
      if (syncStatus.message || syncStatus.messageId) {
        this.copyText(`${syncStatus.message} - ${syncStatus.messageId}`)
      }
    }
  }
}
</script>
