<template lang="pug">
  v-container(fluid)
    template(v-if='!commonConfiguration')
      .text-center.subtitle-1
        v-btn(
          outlined,
          @click='redirectToConfig'
        ) No field in this category. Go create some and come back

    template(v-else)
      v-card-text
        v-row(justify='space-around', align='center')
          v-col(
            v-for='field in validFields',
            :key='field.name',
            cols='12',
            sm='6',
            md='4',
            lg='3'
          )
            template(v-if="field.type === 'file' && (typeof currentConfig[field.name] !== 'string' || currentConfig[field.name] === '')")
              v-file-input(
                show-size,
                outlined,
                v-model='currentConfig[field.name]',
                :readonly='!field.isEditable',
                :label='field.displayName || field.name',
                :hint='getHint(field)',
                persistent-hint,
                @change='save'
              )
            template(v-else-if="field.type === 'boolean'")
              v-switch(
                v-model='currentConfig[field.name]',
                :readonly='!field.isEditable',
                :label='field.displayName || field.name',
                :hint='getHint(field)',
                persistent-hint,
                @change='save'
              )
            template(v-else)
              v-text-field(
                outlined,
                clearable,
                v-model='currentConfig[field.name]',
                :readonly="!field.isEditable || field.type === 'file'",
                :label='field.displayName || field.name',
                :hint='getHint(field)',
                persistent-hint,
                @input='save'
              )
</template>

<script>
import { mapActions } from 'vuex'
import { debounce, cloneDeep } from 'lodash'
import { createHash } from 'crypto'

export default {
  name: 'ConfigurationSection',

  props: {
    section: String,
    config: Object,
    commonConfiguration: Object
  },

  computed: {
    currentConfig () {
      return cloneDeep(this.config) || {}
    },
    legacyFields () {
      return this.commonConfiguration.fields.reduce((acc, field) => ([
        ...acc,
        ...(field.legacyIds || [])
      ]), [])
    },
    validFields () {
      return this.commonConfiguration.fields.filter(({ isLegacy, id }) => !isLegacy && !this.legacyFields.includes(id))
    }
  },

  methods: {
    ...mapActions('vsauce', [
      'saveConfig'
    ]),

    getHint (field) {
      let hint = `Will result in the ${field.name} key.`

      if (field.defaultValue) hint += ` (Defaults to ${field.defaultValue})`

      return hint
    },
    async sanitize (rawConfig) {
      const config = cloneDeep(rawConfig)
      const promises = []

      const sectionConfig = config[this.section]
      const keys = Object.keys(sectionConfig)

      keys.forEach((key) => {
        const value = sectionConfig[key]
        const type = this.commonConfiguration.fields.filter(({ name }) => name === key).type

        if (value === null) {
          config[this.section][key] = ''
        } else if (value === '' && type === 'file') {
          config[this.section][key] = undefined
        }

        if (value instanceof File) {
          promises.push((async () => {
            const fileContent = Buffer.from(await value.arrayBuffer())

            config[this.section][key] = {
              name: value.name,
              text: fileContent,
              checksum: createHash('md5').update(fileContent).digest('hex')
            }
          })())
        }
      })

      await Promise.all(promises)

      return config
    },
    redirectToConfig () {
      this.$router.push({ path: '/vsauce/configuration', query: this.$route.query })
    },
    save: debounce(async function () {
      await this.saveConfig({
        config: await this.sanitize({ [this.section]: this.currentConfig })
      })

      this.$emit('saved')
    }, 300)
  }
}
</script>
