<template lang="pug">
  v-container(fluid)
    v-tabs(v-model='current', grow)
      v-tab(
        v-for='section in Object.keys(sections)',
        :key='section'
      )
        .pr-2 {{ section }}
        v-icon(
          color='green',
          v-if='activeMediations.includes(section)'
        ) {{ icons.mdiCheckCircleOutline }}

    v-tabs-items(v-model='current')
      v-tab-item(
        v-for='section in Object.keys(sections)',
        :key='section'
      )
        template(v-for='subSection in sections[section]')
          v-card.mt-4
            v-card-title {{ subSection }}

            v-divider

            v-card-text
              configuration-section(
                :section='subSection',
                :config='config[subSection] || null',
                :commonConfiguration='commonConfiguration.find(({ name }) => name === subSection)',
                @saved="$emit('saved')"
              )
</template>

<script>
import ConfigurationSection from '@/components/configuration/vsauceConfigurationSection.vue'

import { mdiCheckCircleOutline } from '@mdi/js'

export default {
  name: 'Mediations',

  components: { ConfigurationSection },

  props: {
    all: Array[String],
    sections: Object,
    config: Object,
    commonConfiguration: Array
  },

  data: () => ({
    icons: { mdiCheckCircleOutline },

    current: 0
  }),

  mounted () {
    this.$nextTick(() => {
      this.setActiveTab()
    })
  },

  computed: {
    /**
     * Gives the mediations that have at least some values in the app's configuration.
     * Such mediations will be labels as active.
     */
    activeMediations () {
      return this.all.reduce((acc, mediation) => {
        const sections = this.sections[mediation]

        sections.forEach((section) => {
          if (this.config[section]) acc.push(mediation)
        })

        return acc
      }, [])
    }
  },

  methods: {
    setActiveTab () {
      if (!this.activeMediations.length) return

      this.current = this.all.indexOf(this.activeMediations[0])
    }
  }
}
</script>
