<template lang="pug">
  v-menu(
    :close-on-content-click='false',
    :nudge-width='500'
    offset-y
  )
    template(v-slot:activator='{ on, attr }')
      v-btn(
        color='primary',
        v-bind='attr',
        v-on='on'
      ) {{ title }}
    v-card(outlined)
        v-card-title {{ subtitle }}
        v-card-text(v-if='useLocalWaterfall') {{ description }}
        v-card-text
          v-row(
            v-if='!useLocalWaterfall',
            no-gutters
          )
            v-checkbox.mr-4(
              v-model='idfa',
              label='idfa'
            )
            v-checkbox(
              v-model='noIdfa',
              label='no idfa'
            )
          v-select(
            v-if='!useLocalWaterfall || bidders'
            name='AdUnitSelector'
            label=`AdUnits to update`
            outlined
            v-model='adUnitsSelection'
            multiple=true
            :items='adUnits'
            item-text='name'
            item-value='id'
          )

          v-select(
            name='NetworkSelector'
            label='Networks to update'
            multiple
            outlined
            v-model='ordersSelection'
            :items='orders'
            item-text='network'
            item-value='network'
          )

          template-selector(
            v-if='!useLocalWaterfall'
            @template-selected='templateName = $event'
          )

        v-card-actions
          v-btn(
            color='primary',
            @click='submit',
            :disabled='!isFormValid'
          ) {{ title }}

</template>

<script>
import { mapGetters } from 'vuex'
import TemplateSelector from '@/components/monetization/waterfall/common/templateSelector'
import { maxadsAutoBidders } from '@/constants/constants'

export default {
  name: 'RequestNetworks',

  components: {
    TemplateSelector
  },

  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    bidders: {
      type: Boolean,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    overrideOrders: {
      type: Array,
      required: false
    },
    useLocalWaterfall: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    ordersSelection: [],
    adUnitsSelection: [],
    biddersSelection: [],
    templateName: undefined,
    idfa: true,
    noIdfa: true
  }),

  computed: {
    ...mapGetters('monetization', ['currentGame']),
    ...mapGetters('monetization/orders', ['getOrdersByAppId']),
    ...mapGetters('monetization/adUnits', ['getAdUnitsByAppId']),
    ...mapGetters('monetization', ['currentPlatform']),

    orders () {
      const orders = this.overrideOrders || this.getOrdersByAppId(this.currentGame.id)
      if (this.bidders) {
        return maxadsAutoBidders
      }
      return orders
    },

    adUnits () {
      return this.getAdUnitsByAppId(this.currentGame.id)
    },

    isFormValid () {
      return (this.useLocalWaterfall || this.templateName) &&
        (this.useLocalWaterfall || this.adUnitsSelection.length) &&
        this.ordersSelection.length &&
      (this.useLocalWaterfall || (!this.isIosApp || (this.idfa || this.noIdfa)))
    },

    isIosApp () {
      return this.currentPlatform === 'ios'
    }
  },

  methods: {
    getIdfaTargeting () {
      if (this.idfa && this.noIdfa) return 'both'
      if (this.idfa) return 'only_idfa'
      return 'no_idfa'
    },

    submit () {
      this.$emit(
        'update-networks',
        this.ordersSelection.length ? this.ordersSelection : undefined,
        this.adUnitsSelection,
        this.templateName,
        this.getIdfaTargeting()
      )
    }
  }
}
</script>
