<template lang="pug">
  v-container(fluid)
    history-entries(:entries='history')
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'

import HistoryEntries from '../../components/monetization/historyEntries'

export default {
  name: 'History',

  components: {
    HistoryEntries
  },

  data: function () {
    return {
      appKey: {
        type: String
      }
    }
  },

  async beforeMount () {
    await this.fetchHistory()
  },

  computed: {
    ...mapGetters('apps', ['app']),
    ...mapState('monetization', ['history'])
  },

  methods: {
    ...mapActions('monetization', ['fetchHistory'])

  }

}
</script>
