<template lang="pug">
  app-wrapper
    v-container(fluid)
      Review(
        ref='review',
        :canCreate='false'
      )

      template(v-if='canAccess')
        v-card(outlined)
          campaign-title(
            title='Campaign Creation History',
            :show-platform='true'
          )

          v-card-subtitle

          v-divider

          v-card-text
            template(v-if='loading')
              v-container.text-center(fluid)
                v-progress-circular(indeterminate)

            template(v-else)
              v-container(fluid)
                template(v-if='campaigns.current.error')
                  v-alert(type='error', outlined)
                    h5 Something unexpected occurred
                    h5 > {{ campaigns.current.error }}
                    h5 Try refreshing the page. If the problem persists, contact the administrator.

                template(v-else)
                  template(v-if='currentBatches.length')
                    batches(
                      :batches='currentBatches',
                      @review='review',
                      ref='batches'
                    )
                  template(v-else)
                    v-alert(type='warning', outlined) No Batch were created yet for {{ getName(platform) }}
      no-access(v-else)
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import checkIfApp from '@/mixins/checkIfApp.js'
import appDecorators from '@/mixins/appDecorators.js'

import AppWrapper from '@/components/layout/appWrapper.vue'

import CampaignTitle from '@/components/acquisition/title.vue'
import Review from '@/components/acquisition/review/dialog.vue'
import Batches from '@/components/acquisition/history/batches.vue'

export default {
  name: 'CampaignsHistory',

  mixins: [checkIfApp, appDecorators],

  components: {
    AppWrapper,
    CampaignTitle,
    Review,
    Batches
  },

  mounted () {
    this.$on('app-config', async () => {
      await this.getCampaigns()

      if (this.$route.query.batchId) {
        if (!this.currentBatches.find(({ id }) => id === this.$route.query.batchId)) return

        this.$refs.batches.openBatch({
          id: this.$route.query.batchId
        })
      }
    })
  },

  data: () => ({
    restrict: {
      groups: ['ADMIN', 'UA_GUY']
    }
  }),

  computed: {
    ...mapState('acquisition', ['platform', 'campaigns', 'loading']),
    ...mapGetters('apps', ['app']),

    currentBatches () {
      const currentApp = this.app.apps.find(({ platform }) => platform === this.platform)
      const batches = this.campaigns.current.batches.all[currentApp.id]

      return batches.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
    }
  },

  methods: {
    ...mapActions('acquisition', ['getCampaigns']),

    review (data) {
      this.$refs.review.setData(data)
      this.$refs.review.reveal()
    }
  }
}
</script>
