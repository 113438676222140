<template lang="pug">
  v-container(fluid)
    v-data-table(
      :headers='headers',
      :items='allLineItems',
      :expanded.sync='expanded',
      item-key='localId',
      show-expand
    )

      template(v-slot:item.adUnit='{ item }')
        v-autocomplete(
          name='AdUnitSelection',
          outlined,
          v-model='item.adUnit',
          label='Select adUnit',
          :items='adUnits',
          item-text='name',
          return-object,
          @change='adUnit => updateLineItem(item, { adUnit })'
        ).pt-5

      template(v-slot:item.order='{ item }')
        v-autocomplete(
          name='NetworkSelection',
          outlined,
          v-model='item.order',
          label='Select network',
          :items='orders',
          item-text='network',
          return-object,
          @change='order => updateLineItem(item, { order })'
        ).pt-5

      template(v-slot:item.bid='{ item }')
        v-edit-dialog(
          :return-value.sync='item.bid',
          large,
          persistent
        )
          v-row.px-2
            .subtitle-1 {{ item.bid }}
            v-icon.pl-2 {{ icons.edit }}

          template(v-slot:input)
            v-text-field(
              v-model='item.bid',
              type='number',
              label='Edit',
              :min="0",
              single-line,
              autofocus,
              @change='bid => updateLineItem(item, { bid })'
            )

      template(v-slot:item.countries='{ item }')
        countries-selector(
          :countries='item.countries',
          @change='countries => updateLineItem(item, { countries })'
        )

      template(v-slot:item.includeGeoTargeting='{ item }')
        v-btn(
          name='IncludeExclude',
          @click='setGeoTargeting(item)',
          :color='setGeoTargetingColor(item.includeGeoTargeting)',
        ).mb-5 {{ item.includeGeoTargeting }}

      template(v-slot:item.active='{ item }')
        v-switch(
          v-model='item.active',
          @change='active => updateLineItem(item, { active })'
        )

      template(v-slot:item.actions='{ headers, item }')
        v-icon(
          small,
          @click='duplicateItem(item)'
        ) {{ icons.copy }}
        v-icon(
          small
          @click='removeLineItem(item)'
        ).ml-5 {{ icons.minus }}

      template(v-slot:body.append)
        td(:colspan='4')
        td(:colspan='1')
          v-icon(
            @click='removeLineItem',
            small
          ) {{ icons.minus }}
          v-icon(
            @click='createNewLineItem',
            small
          ).ml-5 {{ icons.plus }}

      template(v-slot:expanded-item='{ headers, item }')
        td(
          :colspan='headers.length',
          v-if='item.order.network'
        )
          advanced-line-item-form(
            :isUpdate='false'
            :lineItemOverrideFields='item.overrideFields'
            :network='item.order.network'
            :networkSync='newLineItem.networkSync'
            :mediationLineItemId='newLineItem.mediationLineItemId'
            :mediationSync='newLineItem.mediationSync'
            @set-advanced-options='(values) => updateLineItem(item, values)'
          )
</template>

<script>
import { mapGetters } from 'vuex'
import { cloneDeep } from 'lodash'
import {
  mdiPlus, mdiMinus, mdiInformationOutline, mdiPencil,
  mdiContentDuplicate
} from '@mdi/js'

import CountriesSelector from '@/components/monetization/waterfall/common/countriesSelector'
import AdvancedLineItemForm from '@/components/monetization/waterfall/advancedLineItemForm'

export default {
  name: 'LineItemReview',

  components: {
    CountriesSelector,
    AdvancedLineItemForm
  },

  mounted () {
    this.createNewLineItem()
  },

  watch: {
    allLineItems: {
      deep: true,
      handler () {
        this.updateLineItems()
      }
    }
  },

  data: () => ({
    icons: {
      plus: mdiPlus,
      minus: mdiMinus,
      info: mdiInformationOutline,
      edit: mdiPencil,
      copy: mdiContentDuplicate
    },
    constants: {
      colors: {
        exclude: 'red',
        include: 'green',
        all: 'grey'
      }
    },

    newLineItem: {
      order: {
        network: '',
        id: ''
      },
      bid: 0,
      countries: [],
      includeGeoTargeting: 'include',
      active: true,
      adUnit: {
        name: 'AdUnit',
        format: 'AdUnit',
        id: 0
      },
      adUnitIds: [],
      mediationSync: false,
      networkSync: false
    },
    lineItemIndex: 0,

    items: null,
    allLineItems: [],

    expanded: [],

    headers: [
      { text: 'CPM', value: 'bid', align: 'start', sortable: true },
      { text: 'AdUnit', value: 'adUnit', align: 'start', sortable: true },
      { text: 'Network', value: 'order', align: 'start', sortable: true },
      { text: 'Countries', value: 'countries', align: 'start', sortable: true, width: '20%' },
      { text: 'GeoTargeting', value: 'includeGeoTargeting', align: 'start', sortable: true },
      { text: 'Active ?', value: 'active', align: 'start', sortable: true },
      { text: 'Actions', value: 'actions', sortable: false },
      { text: '', value: 'data-table-expand' }
    ]
  }),

  computed: {
    ...mapGetters('monetization', ['currentGame', 'currentMediation']),
    ...mapGetters('monetization/adUnits', ['getAdUnitsByAppId']),
    ...mapGetters('monetization/orders', ['getOrdersByAppId']),
    ...mapGetters('monetization/overrideFields', ['overrideFields']),

    gameId () {
      return this.currentGame.id
    },

    adUnits () {
      return this.getAdUnitsByAppId(this.gameId)
    },

    orders () {
      return this.getOrdersByAppId(this.gameId)
    }
  },

  methods: {
    setGeoTargetingColor (status) {
      return this.constants.colors[status]
    },

    setGeoTargeting (item) {
      const status = item.includeGeoTargeting
      let newStatus

      switch (status) {
        case 'include':
          newStatus = 'exclude'
          break
        case 'exclude':
          newStatus = 'all'
          break
        case 'all':
          newStatus = 'include'
          break
      }
      this.updateLineItem(item, { includeGeoTargeting: newStatus })
    },

    updateLineItem (item, values) {
      this.$set(this.allLineItems, item.localId, {
        ...item,
        mediation: this.currentMediation,
        ...values
      })
      this.$emit('update', this.allLineItems)
    },

    duplicateItem (item) {
      const newItem = cloneDeep(item)
      newItem.localId = this.lineItemIndex++
      this.allLineItems.push(newItem)
      this.$emit('update', this.allLineItems)
    },

    createNewLineItem () {
      const newItem = {
        ...this.newLineItem,
        localId: this.lineItemIndex++
      }
      this.allLineItems.push(newItem)
      this.$emit('update', this.allLineItems)
    },

    removeLineItem (item) {
      const index = this.allLineItems.indexOf(item)
      this.allLineItems.splice(index, 1)
    }
  }
}
</script>
