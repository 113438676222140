<template>
  <v-container fluid>
    <creative-group
      :country="country"
      :creatives="getAvailableCreatives()"
      :campaignType="campaignType"
      :network="network"
      @update="updateCreatives"
    />
    <texts
      @update="updateTexts"
      v-if="isAdvantage"
    />
    <headlines
      class="mb-4"
      :max-headlines="nbHeadlines"
      @update="updateHeadlines"
      v-if="hasHeadline"
    />
    <template v-if="isTiktokSplitTest">
      <v-card outlined>
        <v-card-text>
          <datePicker textFieldLabel="Split test end date" @update="updateEndDate" :allowedDates="allowedDates"></datePicker>
        </v-card-text>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import CreativeGroup from '@/components/acquisition/creatives/creativesGroup.vue'
import Headlines from '@/components/acquisition/creatives/headlines.vue'
import Texts from '@/components/acquisition/creatives/texts.vue'
import DatePicker from '@/components/common/datePicker'

export default {
  name: 'Creatives',

  components: {
    CreativeGroup,
    Headlines,
    Texts,
    DatePicker
  },

  props: {
    budget: {
      type: Object,
      required: true
    },
    network: {
      type: Object,
      required: true
    },
    campaignType: {
      type: String,
      required: true
    }
  },

  computed: {
    ...mapState('acquisition', ['creatives']),

    isAdvantage () {
      return this.campaignType === 'advantage'
    },
    isTiktokSplitTest () {
      return (this.campaignType === 'splitTest' && this.network.value === 'tiktok')
    },
    nbHeadlines () {
      if (this.isAdvantage) { return 5 }
      if (this.isTiktokSplitTest) { return 1 }
      return 0
    },
    hasHeadline () {
      return this.nbHeadlines !== 0
    },
    currentCreatives () {
      return this.creatives.available
    }
  },

  methods: {
    ...mapMutations('acquisition', ['setPrimaryTexts', 'setHeadlines']),

    getAvailableCreatives () {
      return this.currentCreatives
        .filter(({ name }) => this.term ? name.toLowerCase().includes(this.term.toLowerCase()) : true)
    },

    updateCreatives (creatives) {
      this.$emit('update', { budget: this.budget, creatives })
    },

    updateTexts (texts) {
      this.$emit('update', { budget: this.budget, texts })
    },

    updateHeadlines (headlines) {
      this.$emit('update', { budget: this.budget, headlines })
    },

    updateEndDate (date) {
      this.$emit('update', { budget: this.budget, endDate: date })
    },

    allowedDates (date) {
      const today = new Date((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10))
      return new Date(date) >= today
    }
  }
}
</script>
