<template lang="pug">
  v-container(fluid)
    v-alert(
      dense,
      outlined,
      border='left',
      type='error'
    )
      v-row(justify='space-between', align='center')
        v-col.grow
          h5 Kitchen could not retrieve creatives for your campaigns.
          h5 {{ errorMessage }}
          h5 Please make sure you have generated and/or uploaded creatives using Render Kid.

        v-col.shrink
          v-btn(
            @click='retry',
            outlined
          ) Retry
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CreativeError',

  computed: {
    ...mapState('acquisition', ['creatives']),

    errorMessage () {
      return this.creatives.error.message
    }
  },

  methods: {
    retry () {
      this.$emit('retry')
    }
  }
}
</script>
