export default {
  setLoading (state, bool) {
    state.loading = bool
  },

  setSubmitting (state, bool) {
    state.submitting = bool
  },

  setForm (state, form) {
    state.form = form
  }
}
