<template lang="pug">
  v-card.mt-3(outlined)
    v-card-title
      span Stores
      v-spacer
      import-platform

    v-card-subtitle General information per store

    v-divider

    v-card-text
      v-row(justify='space-around')
        template(v-if='app', v-for="platform in platforms")
          v-col(
            :key='platform',
            cols='10',
            sm='8',
            md='4',
            xl='3'
          )
            platform-card(
              :app='getApp(platform)',
              :platform='platform'
            )

        v-col(
          cols='10',
          sm='8',
          md='4',
          xl='3'
        ).d-flex.align-center
          attach-platform(@created='onCreated')
</template>

<script>
import { mapGetters } from 'vuex'

import PlatformCard from './platformCard.vue'
import AttachPlatform from './attachPlatform.vue'
import ImportPlatform from './importPlatform.vue'

import hasPlatform from '@/mixins/hasPlatform.js'

export default {
  name: 'Platforms',

  components: { PlatformCard, AttachPlatform, ImportPlatform },

  mxins: [hasPlatform],

  computed: {
    ...mapGetters('apps', ['app', 'platforms']),

    apps () {
      return this.platforms.map(this.getApp)
    }
  },

  methods: {
    formatApp (app) {
      return {
        exists: true,
        unifiedAppId: app.unifiedAppId,
        id: app.id,
        name: app.name || 'N/A',
        bundleId: app.bundleId || 'N/A',
        storeId: app.storeId || 'N/A',
        platform: app.platform || 'N/A',
        publishStatus: app.isPublished ? 'published' : 'not-published',
        releaseStatus: app.releaseStatus || 'released',
        publidashId: app.publidashGameId || null,
        iconUrl: app.iconUrl
      }
    },
    getApp (platform) {
      const app = this.app.apps.find(({ platform: p }) => platform === p)

      return app ? this.formatApp(app) : null
    },
    onCreated (platform) {
      this.$emit('created', platform)
    }
  }
}
</script>
