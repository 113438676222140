
export default {
  RESET_STATE (state) {
    state.step = 1

    state.networks.selected = []
    state.type = null
    state.creatives = {
      error: null,
      available: null
    }
    state.budgets = []

    Object.keys(state.networks.configuration)
      .forEach((store) => {
        state.networks.configuration[store] = {}
      })
  },

  setStep (state, val) {
    state.step = val
  },
  setLoading (state, bool) {
    state.loading = bool
  },
  setPlatform (state, value) {
    state.platform = value
  },
  setBudgets (state, budgets) {
    state.budgets = budgets
  },
  setBudgetTemplate (state, template) {
    state.budgetTemplate = template
  },

  resetCreatives (state) {
    state.creatives = {
      error: null,
      available: null
    }
  },
  setAvailableCreatives (state, creatives) {
    state.creatives.available = creatives
  },
  setCreativesError (state, error) {
    state.creatives.error = error
  },

  /**
   * Update selected networks. Will result in a filtered array of `state.networks.all`.
   *
   * @param {Object} state
   * @param {String[]} networks
   */
  setSelectedNetworks (state, networks) {
    state.networks.selected = networks
      .map((network) => state.networks.all.find(({ value }) => value === network))
      .filter(Boolean)
  },
  setNetworkConfiguration (state, { network, configuration }) {
    state.networks.configuration[state.platform][network] = configuration
  },

  setCreatedCampaigns (state, campaigns) {
    state.campaigns.created.data = campaigns
  },
  setCreatedError (state, error) {
    state.campaigns.created.error = error
  },
  setCreatedStatus (state, bool) {
    state.campaigns.done = bool
  },

  setCurrentBatch (state, { data, id }) {
    state.campaigns.current.batches[id] = data
  },
  setCurrentBatches (state, data) {
    state.campaigns.current.batches.all = data
  },
  setCurrentCampaignError (state, msg) {
    state.campaigns.current.error = msg
  }
}
