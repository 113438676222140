<template lang="pug">
  v-card(outlined)
    v-card-title
      span Headlines
      v-spacer
      v-btn(
        icon,
        @click='hide = !hide'
      )
        v-icon {{ hide ? icons.mdiWindowMaximize : icons.mdiWindowMinimize }}

    v-divider

    v-card-text
      v-expand-transition
        v-container(fluid, v-show='!hide')
          template(v-for='index in nbHeadlines')
            v-row.my-3(align='center')
              v-text-field.px-3(
                :label='`Headline #${index}`'
                v-model='headlines[index - 1]',
                outlined,
                clearable,
                hide-details
              )

              v-btn(
                icon,
                large,
                @click='remove(index)'
              )
                v-icon(color='red') {{ icons.mdiDelete }}

          template(v-if='nbHeadlines < maxHeadlines')
            v-container(fluid)
              v-row(justify='end')
                v-btn(
                  outlined,
                  large,
                  @click='nbHeadlines += 1'
                ) Add headline

</template>

<script>
import { mdiWindowMinimize, mdiWindowMaximize, mdiDelete } from '@mdi/js'

export default {
  name: 'Headlines',

  props: {
    maxHeadlines: {
      type: Number,
      default: 5
    }
  },

  data: () => ({
    icons: { mdiWindowMinimize, mdiWindowMaximize, mdiDelete },

    hide: false,
    nbHeadlines: 1,
    headlines: []
  }),

  methods: {
    remove (index) {
      if (this.nbHeadlines === 1) {
        this.headlines[0] = null
        return
      }

      this.headlines.splice(index, 1)
      this.nbHeadlines -= 1
    }
  },

  watch: {
    headlines: {
      deep: true,
      handler () {
        this.$emit('update', this.headlines)
      }
    }
  }
}
</script>
