/** @type {import('vuex').Module} */
export default {
  namespaced: true,

  state: {
    api: {
      url: process.env.VUE_APP_API_URL
    },
    autoUA: {
      url: process.env.VUE_APP_AUTO_UA_URL
    }
  },

  getters: {
    apiUrl (state) { return state.api.url },
    autoUAUrl (state) { return state.autoUA.url }
  }
}
