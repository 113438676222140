<template lang="pug">
  v-container(fluid)
    v-card(outlined)
      v-card-title Advanced Options
      v-card-text
        v-form(
          ref='form',
          @submit='submit',
        )
          template(v-if='allOverrideFields[currentMediation][network]')
            v-row(justify='space-between' align='center')
              v-col(
                :cols='9 / allOverrideFields[currentMediation][network].length'
                v-for='{ key, description } in allOverrideFields[currentMediation][network]',
                :key='key'
              )
                v-text-field(
                  flat,
                  :label='description',
                  v-model='values.overrideFields[key]',
                  @change='submit'
                )

              v-col(cols='2')
                v-row(justify='space-between' align='center')
                  v-switch(
                    outlined,
                    name='NetworkSync',
                    label='Network Sync *',
                    v-model='values.networkSync'
                    @change='submit'
                  )
                  tooltip(:text="`Setting NetworkSync to true will create the lineItem directly on mediation,<br />\
                  without creating it on ${network}.<br />\
                  Only enable it if you already created the lineItem on ${network}<br />\
                  and filled the networks fields with up-to-date values.`")

          v-row(justify='space-between' align='center')
            v-col(cols='4')
              v-text-field(
                flat,
                label='Mediation LineItem ID',
                v-model='values.mediationLineItemId'
                @change='submit'
              )

            v-col(cols='2')
              v-row(justify='space-between' align='center')
                v-switch(
                  outlined,
                  name='MediationSync',
                  label='Mediation Sync *',
                  v-model='values.mediationSync'
                  @change='submit'
                )
                tooltip(:text="`Setting MediationSync to true tells kitchen that the lineItems is already live on ${currentMediation},<br />\
                  so it will not create anything on ${network} and ${currentMediation}. <br />\
                  Only enable it if you already created the lineItem on ${currentMediation}<br />\
                  and filled the Mediation LineItem ID with up-to-date id.`")
    v-card(outlined v-if="isUpdate")
      v-card-title
        span Danger Zone
      v-card-actions
        v-spacer
        v-btn(
          color='error'
          @click='requestDeletion'
          ) Delete Line Item
        v-spacer
</template>

<script>
import { mapGetters } from 'vuex'
import Tooltip from '@/components/monetization/tooltip'

export default {
  name: 'AdvancedLineItemForm',

  components: {
    Tooltip
  },

  props: {
    isUpdate: {
      type: Boolean,
      required: true
    },
    lineItemOverrideFields: {
      type: Object
    },
    network: {
      type: String,
      required: true
    },
    networkSync: {
      type: Boolean,
      required: true
    },
    mediationLineItemId: {
      type: String
    },
    mediationSync: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    ...mapGetters('monetization', ['currentMediation']),
    ...mapGetters('monetization/overrideFields', ['allOverrideFields']),

    values () {
      return {
        overrideFields: { ...this.lineItemOverrideFields },
        networkSync: this.networkSync,
        mediationSync: this.mediationSync,
        mediationLineItemId: this.mediationLineItemId
      }
    }
  },

  methods: {
    submit () {
      this.$emit('set-advanced-options', this.values)
    },

    requestDeletion () {
      this.$emit('deletion-request')
    }
  }
}
</script>
