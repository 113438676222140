import { mapMutations } from 'vuex'

export default {
  methods: {
    ...mapMutations([
      'setNotificationMessage',
      'setNotificationDuration',
      'showNotification',
      'setNotificationIsError'
    ]),

    notify (msg, isError = false, duration = 5000) {
      this.setNotificationMessage(msg)
      this.setNotificationIsError(isError)
      this.setNotificationDuration(duration)

      this.showNotification(true)
    }
  }
}
