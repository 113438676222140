<template lang="pug">
  v-container(fluid)
    v-card(shaped, outlined)
      v-card-title Attach new app

      v-card-subtitle Attach any kind of app to {{ app.name || 'this app' }} as long as it doesn't already exist

      v-divider

      v-card-text(v-if='!isCreating')
        v-container(fluid, fill-height)
          v-row(justify='center', align='center')
            v-col(cols='9')
              v-autocomplete(
                :items='availableStores',
                v-model='store',
                label='Platform to create for',
                @input='isCreating = true'
              )

      template(v-else)
        v-card-text

          template(v-if="['ios', 'android'].includes(store)")
            create-app(:platform='store', @created='isCreating = false')

          template(v-else)
            v-card
              v-card-title Attach recap
              v-card-subtitle You are about to create an app under the {{ app.marketingAttributes.voodooName }} project with the following information

              v-card-text
                v-text-field(
                  disabled,
                  outlined,
                  v-model='currentPlatform',
                  label='Platform'
                )

                v-text-field(
                  disabled,
                  outlined,
                  v-model='currentStore',
                  label='Store'
                )

                template(v-if="currentPlatform === 'ios' || currentStore === 'huawei'")
                  v-text-field(
                    outlined,
                    v-model='storeId',
                    label='Store ID',
                    autofocus
                  )

                v-text-field(
                  disabled,
                  outlined,
                  v-model='bundleId',
                  label='Bundle ID'
                )

        v-divider

        v-card-actions
          v-spacer
          v-btn(outlined, @click='isCreating = false') Go back
          v-btn(outlined, @click='create', v-if='isCreating', :disabled='!canCreate') Create
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import CreateApp from '@/components/general/createApp.vue'

export default {
  name: 'AttachPlatform',

  components: { CreateApp },

  data: () => ({
    storeId: null,
    store: null,
    storeList: [
      { divider: true },
      { header: 'Worlwide Distributions' },
      { text: 'Apple Store', value: 'ios' },
      { text: 'Play Store', value: 'android' },

      { divider: true },
      { header: 'Chinese iOS Distributions' },
      { text: 'iOS China', value: 'ios_china' },

      { divider: true },
      { header: 'Chinese Android Distributions' },
      { text: 'Xiaomi', value: 'android_xiaomi' },
      { text: 'Huawei', value: 'android_huawei' },
      { text: 'Tencent', value: 'android_tencent' }
      // { text: 'Oppo', value: 'android_oppo' },

      // { divider: true },
      // { header: 'Japanese Distributions' },
      // { text: 'iOS Line', value: 'ios_line' },
      // { text: 'Android Line', value: 'android_line' }
    ],

    stores: {
      line: {
        suffixes: {
          ios: 'lineios',
          android: 'lineandroid'
        }
      },
      china: {
        suffixes: {
          ios: 'china'
        }
      },
      xiaomi: {
        suffixes: {
          android: 'mi'
        }
      },
      huawei: {
        suffixes: {
          android: 'huawei'
        }
      },
      tencent: {
        suffixes: {
          android: 'tencent'
        }
      },
      oppo: {
        suffixes: {
          android: 'oppo'
        }
      }
    },

    isCreating: false
  }),

  computed: {
    ...mapGetters('apps', ['app', 'androidApp', 'iosApp']),

    availableStores () {
      const currentStores = this.app.apps.map(({ platform }) => platform)

      return this.storeList
        .filter(({ value: store }) => !currentStores.includes(store))
    },

    isIos () { return this.currentPlatform === 'ios' },

    currentPlatform () { return this.store && this.store.split('_')[0] },
    currentStore () { return this.store && this.store.split('_')[1] },

    currentSuffixe () {
      if (!this.store) return null

      const platform = this.store.split('_')[0]
      const store = this.store.split('_')[1]

      return this.stores[store].suffixes[platform]
    },
    bundleId () {
      if (!this.store) return null

      const currentApp = this.isIos
        ? this.iosApp
        : this.androidApp

      return `${currentApp.bundleId}.${this.currentSuffixe}`
    },

    params () {
      return {
        app: this.app || undefined,
        isPublished: false,
        platform: this.store,
        storeId: this.isIos ? this.storeId : this.bundleId,
        bundleId: this.bundleId,
        publidashId: this.iosApp.publidashGameId || this.androidApp.publidashGameId,
        releaseStatus: 'released'
      }
    },

    canCreate () {
      return this.isIos
        ? !!this.storeId
        : true
    }
  },

  methods: {
    ...mapMutations('apps', [
      'setLastCreatedId'
    ]),
    ...mapActions('apps', [
      'createApp'
    ]),

    async create () {
      await this.createApp(this.params)
      this.setLastCreatedId(null)
      this.isCreating = false
    },
    onCreated (platform) {
      this.$emit('created', platform)
    }
  }
}
</script>
