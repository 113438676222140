import axios from 'axios'

import { notify } from '@/store/utils'

export default {
  async fetchMediationApps ({ commit, rootGetters }, silent = false) {
    try {
      const mediation = rootGetters['monetization/currentMediation']
      const { data: { data } } = await axios.get('/waterfall/mediation/apps', {
        params: { mediation }
      })

      data.forEach((app) => commit('addMediationApp', { mediation, app }))
    } catch (e) {
      !silent && notify(commit, `Could not fetch apps on mediation: ${e}`, true)
    }
  },

  async setOwner ({ commit, rootGetters }, payload) {
    try {
      const user = rootGetters['auth/user']
      const currentApp = rootGetters['monetization/currentApp']

      const { data } = await axios.post(`waterfall/apps/${payload.appKey}/owner`, {
        owner: user.email,
        version: currentApp.version
      })
      commit('monetization/setAppVersion', data.version, { root: true })
    } catch (e) {
      notify(commit, 'Could not set app owner: ' + e, true)
    }
  },

  async getAppAvailability ({ commit, rootGetters }) {
    try {
      if (process.env.NODE_ENV !== 'development') {
        const currentApp = rootGetters['monetization/currentApp']

        await axios.get(`waterfall/apps/${currentApp.id}/availability?version=${currentApp.version}`)

        commit('monetization/setAppAvailability', true, { root: true })
      }
    } catch (e) {
      commit('monetization/setAppAvailability', false, { root: true })
    }
  }
}
