import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('acquisition', ['networks'])
  },

  methods: {
    formatCampaign (network, campaign) {
      return {
        network,
        budgets: [[campaign].concat(campaign.adgroups.slice(1)).map(adgroup => ({
          countries: adgroup.countryKeys,
          bid: adgroup.bid,
          budget: adgroup.budget,
          type: campaign.type || null,
          status: campaign.status,
          ios14Min: campaign.ios14Min,
          error: campaign.error
        }))],
        configuration: {
          workerMessageId: campaign.workerMessageId
        },
        options: {
          exclude: {
            worlwide: campaign.excludeFromWorldwide,
            ios14: campaign.excludeIos14
          }
        }
      }
    },
    formatBatch (batchInfo) {
      return batchInfo.reduce((acc, info) => {
        const network = this.networks.all.find(({ value }) => value === info.networkKey)

        // We need to find if an entry for this network is already in acc
        const networkIndex = acc.findIndex(({ network: _network }) => _network.value === network.value)

        // Meaning, we are adding this network to acc
        if (networkIndex < 0) {
          acc.push(this.formatCampaign(network, info))
        } else {
          const budget = info.adgroups.map(adgroup => ({
            countries: adgroup.countryKeys,
            bid: adgroup.bid,
            budget: adgroup.budget,
            type: info.type || null,
            status: info.status,
            ios14Min: info.ios14Min,
            error: info.error
          }))
          acc[networkIndex].budgets.push(budget)
        }
        return acc
      }, [])
    }
  }
}
