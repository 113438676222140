var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.group.title))]),_c('v-card-subtitle',[_vm._v(_vm._s(_vm.group.description))]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.members,"loading":_vm.loading,"search":_vm.search,"custom-filter":_vm.searchFilter},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Search user","hint":"Need to find someone? You can look for names, unit or team."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('search',{attrs:{"groupName":_vm.group.name},on:{"added":_vm.refresh}})],1)],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getDisplayName(item)))])]}},{key:"item.division",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.profile.division || 'N/A'))])]}},{key:"item.department",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.profile.department || 'N/A'))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex align-center justify-space-around"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item.id)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }