<template lang="pug">
  v-btn-toggle(
    v-model='platform',
    @change='onChange',
    mandatory
  )
    template(
      v-for='plat in platforms'
    )
      v-btn(
        icon,
        :value='plat',
        :disabled="(isLoading && platform !== plat) || !hasPlatform(plat)"
      )
        template(v-if='getIcon(plat)')
          v-icon(
            large,
            :color='getColor(plat)'
          ) {{ getIcon(plat) }}
        template(v-else)
          v-img(:src='getLogo(plat)', contain, max-width='35px', max-height='40px')
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import hasPlatform from '@/mixins/hasPlatform.js'
import appDecorators from '@/mixins/appDecorators.js'

export default {
  name: 'AppSelection',

  mixins: [appDecorators, hasPlatform],

  computed: {
    ...mapGetters('apps', ['app', 'platforms']),
    ...mapState('monetization', ['isLoading']),

    platform: {
      get () { return this.$store.state.monetization.platform },
      set (val) {
        this.$router.replace({ query: { ...this.$route.query, platform: val } })
        this.$store.commit('monetization/setPlatform', val)
      }
    }
  },

  methods: {
    onChange () {
      this.$emit('update')
    }
  }
}
</script>
