<template lang="pug">
  v-stepper-content(step='1')
    v-card
      v-card-title Select the platform to create the campaigns on.

      v-divider

      v-card-text
        v-container
          v-row(justify='center', align='center')
            template(v-for='plat in platforms')
              v-col.text-center(cols='12', sm='8', md='4', lg='3')
                v-btn(
                  outlined,
                  large,
                  :color='getColor(plat)',
                  :disabled='loading || !hasPlatform(plat) || !isAvailable(plat)',
                  @click='next(plat)'
                )
                  template(v-if='getIcon(plat)')
                    v-icon(
                      large,
                      :color='getColor(plat)'
                    ) {{ getIcon(plat) }}
                  template(v-else)
                    v-img(:src='getLogo(plat)', contain, max-width='35px', max-height='30px')

                  span.ml-2 {{ getName(plat) }}

                template(v-if='!isPublished(plat)')
                  v-tooltip(top)
                    template(v-slot:activator='{ on }')
                      h6.red--text(v-on='on') {{ getName(plat) }} app is not published

                    span You can change the #[code Publish status] on the home page of this app.

                template(v-if='!hasIcon(plat)')
                  v-tooltip(top)
                    template(v-slot:activator='{ on }')
                      h6.red--text(v-on='on') {{ getName(plat) }} app has no icon

                    span You can refresh the app status on the home page of this app.

</template>

<script>
import { mapGetters, mapState, mapMutations } from 'vuex'

import hasPlatform from '@/mixins/hasPlatform.js'
import appDecorators from '@/mixins/appDecorators.js'
import step from '@/mixins/acquisition/step.js'

export default {
  name: 'Platforms',

  mixins: [appDecorators, hasPlatform, step],

  computed: {
    ...mapGetters('apps', ['app', 'platforms']),
    ...mapState('acquisition', {
      storeLoading: 'loading',
      storePlatform: 'platform'
    }),

    platform: {
      get () { return this.storePlatform },
      set (value) { this.setPlatform(value) }
    },
    loading: {
      get () { return this.storeLoading },
      set () {}
    }
  },

  methods: {
    ...mapMutations('acquisition', [
      'setPlatform',
      'RESET_STATE'
    ]),

    getApp (platform) {
      return this.app.apps.find(({ platform: p }) => p === platform)
    },

    isAvailable (platform) {
      return this.isPublished(platform) && this.hasIcon(platform)
    },

    next (platform) {
      this.platform = platform
      this.step += 1
    },

    isPublished (platform) {
      return this.getApp(platform).isPublished
    },
    hasIcon (platform) {
      return !!this.getApp(platform).iconUrl
    }
  },

  watch: {
    step () {
      // Resets everything when coming back to step 1
      if (this.step === 1) {
        this.RESET_STATE()
      }
    }
  }
}
</script>
