<template lang="pug">
  v-card
    v-card-title
      v-row(justify='space-between', align='center')
        v-col(cols='12', sm='4', md='3')
          span Waterfall found on {{currentMediation}}

        v-col(cols='12', sm='3', md='3', offset-md='2')
          v-text-field(
            v-model='term',
            label='Search',
            color='primary',
            :rules='termRule ? [termRule] : []'
            :loading='loading',
            dense,
            rounded,
            solo-inverted,
            clearable,
            persistent-hint,
            :hint='`Search ${apps.length} apps`'
          )

    v-divider

    v-card-text
      v-container(fluid)
        v-row(no-gutters)
          v-col(cols='12')
            v-fade-transition(
              leave-absolute,
              group,
              ref='apps',
              class='layout row wrap justify-space-around',
              tag='div'
            )
              template(v-for='app in reducedApps')
                v-col(:key='app.key', cols='6', sm='6', md='4', lg='3')
                  app-card(
                    :app='app',
                    @select='selectMediationApp(app)'
                  )

          v-col(cols='12')
            v-pagination(
              v-model='page',
              :length='pages'
            )
    waterfall-dialog(
      ref='dialog',
      :onConfirm='sendSelectEvent'
    )
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import WaterfallDialog from '@/components/monetization/dialog.vue'

import AppCard from './mediationApp.vue'

export default {
  name: 'MediationList',

  components: {
    AppCard,
    WaterfallDialog
  },

  data: () => ({
    term: '',
    selectedMediationApp: null,
    termRule: null,
    page: 1,
    entryPerPage: 24
  }),

  computed: {
    ...mapGetters('monetization/apps', ['getMediationList']),
    ...mapGetters('monetization', ['currentGame', 'currentGameHasWaterfall', 'currentMediation']),

    ...mapState('monetization', { loading: 'isLoading' }),

    apps () {
      return Object.values(this.getMediationList)
        .filter(({ name }) =>
          this.term
            ? name.toLowerCase().includes(this.term.toLowerCase())
            : true
        )
        .sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
    },

    pages () {
      return Math.ceil(this.apps.length / this.entryPerPage)
    },
    reducedApps () {
      const index = this.page - 1

      const range = {
        inf: index * this.entryPerPage,
        sup: (index + 1) * this.entryPerPage
      }

      return this.apps.slice(range.inf, range.sup)
    }
  },

  methods: {
    openDialog () {
      this.$refs.dialog.setTitle('Confirm synchronization')
      this.$refs.dialog.setBody(
        `Are you sure you want to synchronize the Mediation waterfall
        "${this.selectedMediationApp.name}" (${this.selectedMediationApp.platform}) with the application
        "${this.currentGame.name}"  (${this.currentGame.platform}) ?`
      )
      this.$refs.dialog.open()
    },
    selectMediationApp (mediationApp) {
      this.selectedMediationApp = mediationApp
      this.openDialog()
    },

    sendSelectEvent () {
      this.$emit('select', this.selectedMediationApp)
    }
  }
}
</script>
