<template lang="pug">
  v-dialog(
    v-model='show',
    max-width='60%'
  )
    v-card
      v-card-title

      v-divider

      v-card-text.pb-0
        v-container
          template(v-if='campaigns.created.error')
            v-alert(type='error', outlined)
              h4 Something unexpected happened. We were unable to send your request for creation.
              h4 > {{ campaigns.created.error }}
              h4 Please try again in a few minutes. If the problem persists, contact the administrators.

          template(v-else)
            v-alert.mb-0(type='success', outlined, text)
              h3 Your request was successfullly sent for creation!

      v-divider

      v-card-actions
        v-spacer

        v-btn(
          outlined,
          @click='show = false'
        ) Close

        template(v-if='!campaigns.created.error')
          v-btn(
            color='primary',
            @click='openCreated'
          ) Check it out
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Result',

  data: () => ({
    show: false
  }),

  computed: {
    ...mapState('acquisition', ['campaigns'])
  },

  methods: {
    toggleShow () {
      this.show = !this.show
    },

    openCreated () {
      this.$router.push({
        path: '/app/acquisition/campaigns/history',
        query: {
          ...this.$route.query,
          batchId: this.campaigns.created.data[0][0].campaignBatchId
        }
      })
    }
  }
}
</script>
