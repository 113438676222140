<template>
    <div class="creative-item">
      <v-checkbox :input-value="selected" :disabled="disabled" @click="toggle"></v-checkbox>
      <a :href="url" target="_blank">{{ name }}</a>
    </div>
</template>

<script>
export default {
  name: 'Creative',

  props: {
    creative: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      required: true,
      default: false
    },
    disabled: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  computed: {
    name () {
      return this.creative.name
    },
    url () {
      return this.creative.url
    }
  },

  methods: {
    toggle () {
      this.$emit('toggle', this.creative)
    }
  }
}
</script>
<style scoped>
.creative-item {
  margin: 20px;
  display: flex;
  align-items: center;
}
</style>
