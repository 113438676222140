import axios from 'axios'

import { notify } from '@/store/utils'

import { v4 } from 'uuid'

/**
 * GTM-300
 * Interceptor to add a correlation id to track end to end requests
 * naming: trace id following opentelemetry tracing api convention
 */
axios.interceptors.request.use(config => {
  config.headers['x-correlation-id'] = v4()

  return config
})

function getRecentApps () {
  try {
    const recentAppsStored = window.localStorage.getItem('recentApps') || ''
    if (!recentAppsStored.length) {
      return []
    }

    const recentApps = recentAppsStored.split(';').filter(appId => appId !== '')

    return recentApps
  } catch (e) {
    return []
  }
}

export default {
  async retrieveRecentApps ({ commit }) {
    const ids = getRecentApps('recentApps')

    if (!ids.length) return

    try {
      const { data: { apps } } = await axios.get('/unified-apps/ids', { params: { ids: ids.join(';') } })

      commit('setRecentApps', apps)
    } catch (e) {
      await notify(commit, 'Could not retrieve recent apps', true)
    }
  },
  async retrieveApps ({ commit }, { term = '', load = false }) {
    load && commit('setLoading', true)

    try {
      const { data: { apps = [] } } = await axios.get('/unified-apps', { params: { term } })

      commit('setApps', apps)
    } catch (e) {
      await notify(commit, 'Could not retrieve apps', true)
    } finally {
      load && commit('setLoading', false)
    }
  },
  async getApp ({ commit }, appKey) {
    commit('setLoading', true)

    try {
      const { data: { app = null } } = await axios.get(`/unified-apps/id/${appKey}`)

      commit('setCurrentApp', app)
    } catch (e) {
      await notify(commit, 'Could not retrieve app information', true)
    } finally {
      commit('setLoading', false)
    }
  },
  async getAppFromSubApp ({ commit, dispatch }, appKey) {
    commit('setLoading', true)

    try {
      const { data: { app = null } } = await axios.get(`/app/${appKey}`)

      await dispatch('getApp', app.unifiedAppId)
    } catch (e) {
      await notify(commit, 'Could not retrieve app information', true)
    } finally {
      commit('setLoading', false)
    }
  },
  async createApp ({ commit, getters }, params) {
    commit('setCreating', { value: true, platform: params.platform })

    try {
      const { data } = await axios.post('/unified-apps', params)

      if (!data) throw new Error('No App received.')

      commit('setLastCreatedId', data ? data.id : data.apps.id)

      if (getters.app) commit('setCurrentApp', data)
    } catch (e) {
      let error
      if (e.response) {
        error = (typeof e.response.data.error === 'object') ? e.response.data.reason : e.response.data.error
      } else { error = e.message }

      await notify(commit, `Could not create ${params.platform} app: ${error}`, true)
    } finally {
      commit('setCreating', { value: false, platform: params.platform })
    }
  },
  async import ({ commit, dispatch, getters }, appId) {
    commit('setLoading', true)

    try {
      const app = getters.app

      await axios.post('/app/import/', { unifiedAppId: app.id, appId })

      await dispatch('getApp', app.id)

      await notify(commit, 'Successfully imported app')
    } catch (e) {
      await notify(commit, 'Could not import app: ' + e.message, true)
    } finally {
      commit('setLoading', false)
    }
  },
  async refreshData ({ commit, dispatch }, { appId, unifiedAppId }) {
    commit('setLoading', true)

    try {
      await axios.get(`/app/refresh/${appId}`)

      await dispatch('getApp', unifiedAppId)

      await notify(commit, 'Successfully refreshed app data')
    } catch (e) {
      await notify(commit, 'Could not refresh app data: ' + e.message, true)
    } finally {
      commit('setLoading', false)
    }
  },
  async updateStoreId ({ commit, dispatch }, { appId, storeId }) {
    try {
      const { data } = await axios.put(`/unified-apps/id/${appId}/storeId`, { storeId })

      await dispatch('getApp', data.unifiedAppId)

      return data
    } catch (e) {
      await notify(commit, 'Could not update app\'s store id', true)
      throw e
    }
  },
  async saveInfo ({ commit }, { appId, updateType, general, publidash, published, releaseStatus, name }) {
    try {
      const { data: update } = await axios.put(`/unified-apps/id/${appId}`, {
        updateType,
        general,
        publidash,
        published,
        releaseStatus,
        name
      })

      return update
    } catch (e) {
      await notify(commit, 'Could not update app\'s general information', true)
      throw e
    }
  },
  async saveGeneralInfo ({ commit, dispatch }, { updateType, appId, general }) {
    await dispatch('saveInfo', {
      appId,
      updateType,
      general
    })

    await notify(commit, 'Successfully updated general information')
  },
  async savePublidashId ({ commit, dispatch }, { appId, publidashId }) {
    await dispatch('saveInfo', {
      appId,
      updateType: 'publidashId',
      publidash: publidashId
    })

    await notify(commit, 'Successfully updated Publidash Game ID')
  },
  async savePublished ({ commit, dispatch }, { appId, published }) {
    await dispatch('saveInfo', {
      appId,
      updateType: 'isPublished',
      published
    })

    await notify(commit, 'Successfully updated Published status')
  },
  async saveName ({ commit, dispatch }, { appId, name }) {
    await dispatch('saveInfo', {
      appId,
      updateType: 'name',
      name
    })
    await notify(commit, 'Successfully updated name')
  },
  async saveStoreIdPersistent ({ commit, dispatch }, { appId, storeId }) {
    await dispatch('updateStoreId', { appId, storeId })
    await notify(commit, 'Successfully updated')
  },
  async saveReleaseStatus ({ commit, dispatch }, { appId, releaseStatus }) {
    await dispatch('saveInfo', {
      appId,
      updateType: 'releaseStatus',
      releaseStatus
    })

    await notify(commit, 'Successfully updated Published status')
  }
}
