<template lang="pug">
  v-app-bar.px-4(
    app, fixed,
    color='primary',
    clipped-left
  )

    v-app-bar-nav-icon(@click='goToHome')
      v-img(:src='icons.kitchenIcon', height='32', width='32')

    v-toolbar-title(@click='goToHome').title.pl-2.white--text Voodoo Kitchen

    v-spacer

    search.mr-8

    v-menu
      template(v-slot:activator='{ on }')
        v-btn(icon, v-on='on')
          v-icon {{ icons.mdiDotsVertical }}

      v-list
        v-list-item(v-if='isAuthenticated')
          v-list-item-action.pl-2
            v-icon {{ icons.mdiAccountCircle }}
          v-list-item-title Connected as {{ user.name }}

        v-divider

        v-list-item
          v-list-item-title Dark Theme
          v-switch.ml-2(v-model='dark')

        v-divider

        v-list-item(
          v-if='isAuthenticated',
          @click='goToConfiguration'
        )
          v-list-item-title VSauce Configuration
          v-list-item-action
            v-icon {{ icons.mdiSettings }}

        v-divider

        v-list-item(
          v-if='isAuthenticated && isAdmin',
          @click='goToUserAdmin'
        )
          v-list-item-title User Administration
          v-list-item-action
            v-icon {{ icons.mdiAccountEdit }}

        v-divider

        v-list-item(
          v-if='isAuthenticated',
          @click='goToHistory'
        )
          v-list-item-title Waterfall History
          v-list-item-action
            v-icon {{ icons.mdiFileClockOutline }}

        v-divider

        v-list-item(
          v-if='isAuthenticated',
          @click='logout'
        )
          v-list-item-title Logout
          v-list-item-action
            v-icon {{ icons.mdiLogout }}
</template>

<script>
import { mapGetters } from 'vuex'

import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiDotsVertical,
  mdiHome,
  mdiLogout,
  mdiAccountCircle,
  mdiSettings,
  mdiMagnify,
  mdiFileClockOutline,
  mdiAccountEdit
} from '@mdi/js'

import Search from './search.vue'

import kitchenIcon from '@/assets/logo.png'

export default {
  name: 'Toolbar',

  components: { Search },

  data: () => ({
    icons: {
      kitchenIcon,
      mdiChevronLeft,
      mdiChevronRight,
      mdiDotsVertical,
      mdiHome,
      mdiLogout,
      mdiAccountCircle,
      mdiSettings,
      mdiMagnify,
      mdiFileClockOutline,
      mdiAccountEdit
    }
  }),

  mounted () {
    const storage = window.localStorage
    const shoudlDark = storage.getItem('dark')

    if (shoudlDark) this.dark = true
  },

  computed: {
    ...mapGetters('apps', [
      'hasApp',
      'app'
    ]),

    isAuthenticated () {
      return this.authState.isAuthenticated
    },
    user () {
      return {
        name: this.authState.idToken.claims.name
      }
    },

    drawer: {
      get () {
        return this.$store.state.drawer
      },
      set () {}
    },
    miniDrawer: {
      get () {
        return this.drawer.mini
      },
      set (bool) {
        this.$store.commit('setMiniDrawer', bool)
      }
    },
    dark: {
      get () {
        return this.$store.getters.isDark
      },
      set (bool) {
        this.$store.commit('setDark', bool)
        this.$vuetify.theme.dark = bool

        if (bool) {
          window.localStorage.setItem('dark', 'true')
          document.body.style.backgroundColor = '#303030'
        } else {
          window.localStorage.removeItem('dark')
          document.body.style.backgroundColor = 'white'
        }
      }
    },
    isHome () {
      return this.$route.path === '/'
    }
  },

  methods: {
    async logout () {
      await this.$auth.signOut()
    },

    cleanApp () {
      this.$store.commit('apps/setCurrentApp', null)
    },

    goToHome () {
      this.cleanApp()
      this.$router.push({ path: '/' })
    },

    goToConfiguration () {
      this.cleanApp()
      this.$router.push({ path: '/vsauce/configuration' })
    },

    goToUserAdmin () {
      this.cleanApp()
      this.$router.push({ path: '/auth/admin' })
    },

    goToHistory () {
      this.cleanApp()
      this.$router.push({ name: 'History' })
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    cursor: pointer;
  }
</style>
