import localStorage from '@/mixins/localStorage'

export default {
  mixins: [localStorage],

  async beforeMount () {
    if (this.$store.state.apps.createdId && this.$store.getters['apps/hasApp']) return

    const appId = this.$route.query.appId

    if (!appId) return this.$router.push({ path: '/' })

    this.saveRecentApp(appId)

    await Promise.all([
      this.$store.dispatch('apps/getApp', appId),
      this.$store.dispatch('vsauce/retrieveCommonConfig')
    ])

    await Promise.all(
      this.$store.state.apps.current.apps.map(async (app) => this.$store.dispatch('vsauce/retrieveConfig', app.id))
    )

    this.$emit('app-config')
  }
}
