import axios from 'axios'

import { notify } from '@/store/utils'

export default {
  async initWaterfall ({ commit, dispatch }, silent = false) {
    await Promise.all([
      dispatch('fetchMediationAccounts', silent),
      dispatch('fetchAppNetworkIds', silent),
      dispatch('apps/fetchMediationApps', silent),
      dispatch('overrideFields/fetchOverrideFields', silent)
    ])

    commit('init')
  },

  async fetchAppWaterfall ({ state, commit, dispatch, getters }, silent = false) {
    try {
      commit('setLoading', true)

      if (!state.isInit[state.mediation]) await dispatch('initWaterfall')

      const { data: { app } } = await axios.get(`/waterfall/apps/${getters.currentGame.id}`, {
        params: { mediation: state.mediation }
      })

      commit('setHasWaterfall', true)
      commit('setCurrentApp', app)
      commit('orders/addOrders', { mediation: state.mediation, appId: app.id, orders: app.orders })
      commit('adUnits/addAdUnits', { mediation: state.mediation, appId: app.id, adUnits: app.adUnits, commit })
      commit('bidders/addBidders', { mediation: state.mediation, appId: app.id, bidders: app.bidders })
      commit('lineItems/setLineItems', { mediation: state.mediation, appId: app.id, lineItems: app.lineItems })
      await dispatch('apps/getAppAvailability')
    } catch (e) {
      !silent && notify(commit, 'Could not fetch app waterfall: ' + e, true)
    } finally {
      commit('setLoading', false)
    }
  },

  async displayActionSummary ({ commit, dispatch }, actionSummary) {
    await dispatch('fetchAppNetworkIds')
    commit('setActionSummary', actionSummary)
  },

  async fetchAppNetworkIds ({ commit, getters }, silent = false) {
    try {
      const { data: { networkAppIds } } = await axios.get(`/waterfall/apps/${getters.currentGame.id}/network-ids`)
      commit('setNetworkAppIds', networkAppIds)
    } catch (e) {
      !silent && notify(commit, 'Could not fetch app ids: ' + e, true)
    }
  },

  async upsertAppNetworkIds ({ getters }, { networkAppIds }) {
    await axios.put(`/waterfall/apps/${getters.currentGame.id}/network-ids`, {
      networkAppIds
    })
  },

  async findAppNetworkIds ({ state, commit, getters }) {
    commit('setNetworkAppIdsLoading', true)
    const { data: networkAppIds } = await axios.get(`/waterfall/apps/${getters.currentGame.id}/network-ids/find`, {
      version: getters.currentGame.version,
      networkAppIds: getters.currentAppFoundNetworkAppIds
    })
    commit('setFoundNetworkAppIds', networkAppIds)
    commit('setNetworkAppIdsLoading', false)
  },

  async createNewWaterfall ({ state, commit }, payload) {
    try {
      await axios.post('/waterfall', {
        appKey: payload.id,
        mediationAccount: payload.mediationAccount,
        mediation: state.mediation,
        owner: payload.owner,
        templateName: payload.templateName
      })

      notify(commit, 'New waterfall created', false)
    } catch (e) {
      notify(commit, 'Could not create app waterfall: ' + e, true)
    }
  },

  async syncWaterfall ({ state, commit }, payload) {
    try {
      await axios.post('/waterfall', {
        appKey: payload.appKey,
        mediationAppId: payload.mediationAppId,
        mediation: state.mediation
      })

      notify(commit, 'Waterfall sync sent', false)
    } catch (e) {
      notify(commit, `Could not sync app waterfall from ${state.mediation}: ` + e, true)
    } finally {
      commit('setLoading', false)
    }
  },

  async pushWaterfall ({ state, getters, commit }, { appKey, networks }) {
    try {
      commit('setLoading', true)

      const currentApp = getters.currentApp
      const { data } = await axios.post('/waterfall/mediation',
        { appKey, version: currentApp.version, networks, mediation: state.mediation }
      )

      commit('setAppVersion', data.version)
      notify(commit, 'Waterfall update sent', false)
    } catch (e) {
      notify(commit, `Could not push app waterfall on ${state.mediation}: ` + e, true)
    } finally {
      commit('setLoading', false)
    }
  },

  async updateNetworks ({ state, commit, getters, dispatch }, { appKey, networks }) {
    try {
      commit('setLoading', true)

      await dispatch('apps/setOwner', { appKey })
      const updatedApp = getters.currentApp
      const { data } = await axios.post('/waterfall/networks', {
        appKey,
        version: updatedApp.version,
        networks,
        mediation: state.mediation
      })
      commit('setAppVersion', data.version)

      notify(commit, 'Networks update sent', false)
    } catch (e) {
      notify(commit, 'Could not push app waterfall on networks: ' + e, true)
    } finally {
      commit('setLoading', false)
    }
  },

  async updateSetup ({ state, commit, getters, dispatch }, { appKey, networks, adUnitIds, templateName, idfaTargeting }) {
    try {
      commit('setLoading', true)

      await dispatch('apps/setOwner', { appKey })
      const updatedApp = getters.currentApp
      const { data } = await axios.post('/waterfall/networks/setup', {
        appKey,
        version: updatedApp.version,
        networks,
        adUnitIds,
        templateName,
        mediation: state.mediation,
        idfaTargeting
      })
      commit('setAppVersion', data.version)

      notify(commit, 'Waterfall setup update on networks sent', false)
    } catch (e) {
      const errorDetails = (e.response && e.response.data && e.response.data.message) ? e.response.data.message : e.message
      notify(commit, 'Could not send waterfall setup update: ' + errorDetails, true, 20000)
    } finally {
      commit('setLoading', false)
    }
  },

  async fetchMediationAccounts ({ state, commit }, silent = false) {
    try {
      const { data: { data } } = await axios.get('waterfall/mediation/accounts', {
        params: { mediation: state.mediation }
      })

      const result = Object.keys(data).map((key) => {
        return {
          name: key,
          value: data[key]
        }
      })

      commit('setMediationAccounts', result)
    } catch (e) {
      !silent && notify(commit, `Could not fetch ${state.mediation} accounts: ` + e, true)
    }
  },

  async fetchTemplateList ({ commit }) {
    try {
      const { data: { data } } = await axios.get('waterfall/templates')
      commit('setTemplateList', data.templateNames)
    } catch (e) {
      notify(commit, 'Could not fetch template list: ' + e, true)
    }
  },

  async uploadFacebookFile ({ commit, getters }, payload) {
    try {
      const currentApp = getters.currentApp
      const currentMediation = getters.currentMediation
      await axios.post('waterfall/networks/facebook',
        { file: payload.file, appKey: payload.appKey, version: currentApp.version, mediation: currentMediation })

      notify(commit, 'CSV file uploaded', false)
    } catch (e) {
      notify(commit, 'Could not upload facebook csv: ' + e.response.data.message, true)
    }
  },

  async fetchHistory ({ commit }) {
    try {
      const { data: history } = await axios.get('/waterfall/history')
      commit('setHistory', history)
    } catch (e) {
      notify(commit, 'Could not fetch history: ' + e.response.data.message, true)
    }
  }
}
