<template lang="pug">
  v-snackbar(
    v-model='show',
    top,
    :timeout="duration",
    :color="isError ? 'error' : 'success'"
  )
    span {{ message }}
    v-btn(
      @click='show = false',
      text
    ) Close
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Notification',

  computed: {
    ...mapGetters([
      'notification'
    ]),

    show: {
      get () { return this.notification.show },
      set (bool) { this.setShow(bool) }
    },
    message: {
      get () { return this.notification.message },
      set (msg) { this.setMsg(msg) }
    },
    isError: {
      get () { return this.notification.isError },
      set (msg) { this.setMsg(msg) }
    },
    duration: {
      get () { return this.notification.duration },
      set (msg) { this.setMsg(msg) }
    }
  },

  methods: {
    ...mapMutations({
      setMsg: 'setNotificationMessage',
      setShow: 'showNotification',
      setIsError: 'setNotificationIsError',
      setDuration: 'setNotificationDuration'
    })
  }
}
</script>
