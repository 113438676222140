<template lang="pug">
  v-card.mt-3(outlined)
    v-card-title App Settings

    v-divider

    v-card-text
      v-row(align='center', justify='center')
        v-col(cols='12', sm='10', md='8', lg='6', xl='4')
          v-text-field(
            v-model='form.token',
            label='Access Token',
            ref='accessToken',
            hint='This token is used by the studio to retrieve this app\'s configuration',
            persistent-hint,
            readonly,
            disabled,
            type='text'
          )
            template(v-slot:prepend, v-if='!form.token')
              v-tooltip(bottom)
                template(v-slot:activator='{ on }')
                  v-icon(large, @click='generateAccessToken', v-on='on') {{ icons.mdiRefresh }}
                span Generate Token

            template(v-slot:append)
              v-icon(@click='copyText(form.token)') {{ icons.mdiContentCopy }}
</template>

<script>
import { v4 as uuid } from 'uuid'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { mdiRefresh, mdiContentCopy } from '@mdi/js'

import copyText from '@/mixins/copyText'

export default {
  name: 'Settings',

  mixins: [copyText],

  props: {
    info: Object
  },

  mounted () {
    this.form.token = this.app.marketingAttributes.settingsAccessTokenOverride
  },

  data () {
    return {
      form: {
        token: null
      },

      updateType: 'attributes',
      preventSave: true,

      icons: { mdiRefresh, mdiContentCopy }
    }
  },

  computed: {
    ...mapGetters('apps', ['app'])
  },

  methods: {
    generateAccessToken () {
      this.preventSave = false
      this.form.token = uuid()
    },
    save: debounce(async function () {
      if (this.preventSave) {
        this.preventSave = false
        return
      }

      this.$emit('save', {
        updateType: this.updateType,
        general: {
          voodooName: this.app.marketingAttributes.marname,
          marketingCode: this.app.marketingAttributes.marketingCode,
          attributionPartner: this.app.marketingAttributes.attributionPartner,
          settingsAccessTokenOverride: this.form.token
        }
      })
    }, 300)
  },

  watch: {
    form: {
      deep: true,
      async handler () {
        return this.save()
      }
    }
  }
}
</script>
