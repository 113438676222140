<template lang="pug">
  v-autocomplete(
    name='CountriesSelection',
    multiple,
    outlined,
    v-model='lineItemCountries'
    @change='emitChange'
    label='Select countries',
    :items='allCountries',
    item-text='name',
    item-value='code',
    ref='autocomplete'
  ).pt-5
    template(v-slot:selection='{ item, index }')
      span(v-if='index < previewCount') {{ item.name }},
      span(v-if='index === previewCount').grey--text.caption (+{{ lineItemCountries.length - previewCount }} others)
    v-list-item(
      name='list',
      slot='prepend-item',
      @click='selectAllCountries($event)'
    )
      v-list-item-title Select All

</template>

<script>
import { countries } from '@/constants/constants.js'

export default {
  name: 'CountriesSelector',

  computed: {
    lineItemCountries: {
      get () {
        return [...this.countries]
      },
      set () {}
    },

    allCountries () {
      return countries
        .sort((a, b) => a.name.localeCompare(b.name))
        .sort((a, b) =>
          Number(this.lineItemCountries.includes(b.code)) -
          Number(this.lineItemCountries.includes(a.code))
        )
    }
  },

  props: {
    previewCount: {
      type: Number,
      default: 1
    },
    countries: {
      type: Array
    }
  },

  methods: {
    selectAllCountries (mode) {
      if (this.lineItemCountries.length === this.allCountries.length) {
        this.emitChange([])
      } else {
        this.emitChange(this.allCountries.map(({ code }) => code))
      }
    },

    emitChange (countries) {
      this.$refs.autocomplete.internalSearch = ''
      this.$emit('change', countries)
    }
  }
}
</script>

<style>
</style>
