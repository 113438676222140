<template lang="pug">
  v-card-text
    v-container(fluid)
      v-form(v-model='valid')
        v-row

          //- Google Drive URL
          v-col(cols='12')
            v-text-field(
              label='Google Drive Url',
              v-model='driveUrl',
              :rules='[rules.driveUrl]',,
              :disabled='loading',
              outlined,
              clearable,
              persistent-hint,
              hint='This URL will be distributed as containing the creatives to use for the Ad campaign'
            )

          //- App ID
          template(v-if='needsId')
            v-col(cols='12')
              v-text-field(
                label='App ID',
                v-model='appId',
                :rules='[rules.appId]',,
                :disabled='loading',
                outlined,
                clearable,
                persistent-hint,
                hint='App ID internal to this network'
              )

          //- Save button
          v-col.d-flex.justify-end(cols='12')
            v-btn(
              @click='save',
              :loading='loading',
              :disabled='!valid',
              color='primary'
            )
              .pr-2 Save
              v-icon {{ icons.mdiContentSave }}
</template>

<script>
import { mdiContentSave } from '@mdi/js'

import { VTextField } from 'vuetify/lib'

import Configuration from '@/mixins/acquisition/configuration.js'

export default {
  name: 'Email',

  // smh...
  components: { VTextField },

  mixins: [Configuration],

  props: {
    needsId: {
      type: Boolean,
      required: false,
      default: true
    },
    network: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    icons: { mdiContentSave },

    valid: false,

    rules: {
      driveUrl: (value) => (!!value && /^https:\/\/drive\.google\..+\/folders\/\w+/.test(value)) || 'Invalid Drive URL',
      appId: (value) => !!value || 'No value entered'
    },

    appId: null,
    driveUrl: null
  }),

  computed: {
    configuration () {
      return {
        appId: this.needsId ? this.appId : undefined,
        driveUrl: this.driveUrl
      }
    }
  },

  watch: {
    valid () {
      this.$emit('valid', { value: this.valid, network: this.network.value })
    }
  }
}
</script>
