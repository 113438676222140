<template lang="pug">
  v-container(fluid)
    v-dialog(
      v-model='show',
      width='600'
    )
      v-card
        v-card-title(primary-title).headline {{ title }}

        v-card-text(v-html='body')

        v-card-actions
          v-btn(
            color='primary',
            @click='close'
          ) Cancel
          v-spacer
          v-btn(
            color='primary',
            @click='close(), onConfirm()'
          ) Confirm
</template>

<script>

export default {
  name: 'Dialog',

  props: {
    onConfirm: {
      type: Function,
      default: () => {}
    }
  },

  data: () => ({
    show: false,
    title: null,
    body: null
  }),

  methods: {
    // Will be used remotely
    open () {
      this.show = true
    },
    setTitle (title) {
      this.title = title
    },
    // Will be used remotely
    setBody (body) {
      this.body = body
    },

    close () {
      this.show = false
    }
  }
}
</script>
