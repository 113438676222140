<template lang="pug">
  v-container(
    fluid
  )
    template(v-if='isLoading')
      div

    template(v-else)
      .ma-0.pa-0
        v-row(no-gutters, align='center')
          recentApps

        v-row(no-gutters, align='center')
          allApps
</template>

<script>
// Store
import { mapActions, mapMutations } from 'vuex'

// Comps
import recentApps from '@/components/home/recentApps.vue'
import allApps from '@/components/home/allApps.vue'

export default {
  name: 'Home',

  components: {
    recentApps,
    allApps
  },

  async mounted () {
    this.reset()

    await this.retrieveApps({ load: true })
  },

  computed: {
    isLoading () {
      return this.$store.state.apps.loading
    }
  },

  methods: {
    ...mapActions('apps', [
      'retrieveApps'
    ]),
    ...mapMutations('apps', {
      setApp: 'setCurrentApp'
    }),
    ...mapMutations('monetization', [
      'setIsInit'
    ]),
    ...mapMutations('adjust', {
      resetAdjust: 'reset'
    }),

    reset () {
      this.setApp(null)
      this.setIsInit(false)
      this.resetAdjust()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
