import { mapActions, mapState } from 'vuex'

import Notifications from '@/mixins/notifications.js'
import CurrentAppId from './currentAppId.js'

export default {
  mixins: [
    Notifications,
    CurrentAppId
  ],

  async mounted () {
    await this.refreshConfig()
  },

  data: () => ({
    loading: false
  }),

  computed: {
    ...mapState('acquisition', ['networks', 'platform'])
  },

  methods: {
    ...mapActions('acquisition', [
      'getConfiguration',
      'saveConfiguration'
    ]),

    setValues () {
      let shouldInput = false

      Object.keys(this.configuration)
        .forEach((key) => {
          this[key] = this.networks.configuration[this.platform][this.network.value][key]

          if (this[key]) shouldInput = true
        })

      if (shouldInput) this.canInput = true
    },

    async refreshConfig () {
      // Cheking if something is in the store already
      if (this.networks.configuration[this.platform][this.network.value]) {
        this.setValues()

        this.$emit('refreshed')
        return
      }

      // Otherwise we have to fetch them
      this.loading = true

      await this.getConfiguration({
        network: this.network.value,
        appId: this.currentAppId
      })

      this.setValues()

      this.loading = false

      this.$emit('refreshed')
    },
    async save (notify = true) {
      try {
        this.loading = true

        await this.saveConfiguration({
          network: this.network.value,
          configuration: this.configuration,
          appId: this.currentAppId
        })

        if (notify) {
          const success = !this.networks.configuration[this.platform][this.network.value].error

          this.notify(
            success
              ? `Successfully updated ${this.network.text} information`
              : `Could not update ${this.network.text} information`,
            !success
          )
        }
      } catch (e) {
        if (notify) {
          this.notify(`Could not update ${this.network.text} information`, true)
        }
      } finally {
        this.loading = false
      }
    }
  },

  watch: {
    async platform () {
      Object.keys(this.configuration)
        .forEach((key) => {
          this.$set(this, key, typeof this.configuration[key] === 'boolean' ? false : null)
        })

      await this.refreshConfig()
    }
  }
}
