<template lang="pug">
  v-alert(
    outlined,
    type='error'
  ) Not implemented yet.
</template>

<script>
export default {
  name: 'VoodooAds',

  props: {
    network: {
      type: Object,
      required: true
    }
  }
}
</script>
